<template>
  <div>
    <a-dropdown v-model="visible" :trigger="['contextmenu']">
      <div @click="contextmenuClick" class="calendar-div" @contextmenu="contextmenu">
        <div class="box" @mousedown="handleMouseDown" :style="backCss" :class="isView ? ' no-title' : ''">
          <div style="height:0;overflow:hidden">
            <a-tooltip
              overlayClassName="calendar-tooltip"
              v-for="(item, index) in schedule"
              :key="item.key + '-' + index"
              :ref="item.key + '-count'"
            >
              <template slot="title">
                <p
                  v-for="i in item.data.filter(
                    i =>
                      i.position &&
                      i.position.positionName != '统筹' &&
                      (i.depart.departName.indexOf('韩语部') > -1 || i.depart.departName.indexOf('日语部') > -1)
                  )"
                  :key="i.departId + '-' + i.positionId"
                >
                  <span>{{
                    i.position && i.position.positionName
                      ? (i.depart && i.depart.departName) + '-' + i.position.positionName
                      : ''
                  }}</span
                  >：
                  <span>{{ i.count }}</span>
                </p>
              </template>
              <div
                style="position: relative;left: -7px;"
                v-if="
                  item.data &&
                    item.data
                      .filter(
                        i =>
                          i.position &&
                          i.position.positionName != '统筹' &&
                          (i.depart.departName.indexOf('韩语部') > -1 || i.depart.departName.indexOf('日语部') > -1)
                      )
                      .map(i => i.count).length
                "
              >
                <span v-if="!item.isEdit" class="title-span">{{
                  item.data
                    .filter(
                      i =>
                        i.position &&
                        i.position.positionName != '统筹' &&
                        (i.depart.departName.indexOf('韩语部') > -1 || i.depart.departName.indexOf('日语部') > -1)
                    )
                    .map(i => i.count)
                    .join('，')
                }}</span>
                <!-- @dblclick.stop="titleSpanDbclick(item)" -->
                <!--             <a-input
              class="title-input"
              @input="
                e => {
                  titleInput(e, item.key)
                }
              "
              @keyup.enter.native="enterInput(item)"
              @click="titleInputClick"
              v-model="item.count"
              v-else
            />
            <div class="title-width-div" :ref="item.key + '-widthDiv'">{{ item.data.map(i=>i.count).join(',') }}</div> -->
              </div>
            </a-tooltip>
          </div>
          <div style="height:0;overflow:hidden">
            <div class="calendar-button-list">
              <button
                @click="prevClick"
                style="border-top-right-radius:0;border-bottom-right-radius:0;"
                class="fc-today-button fc-button fc-button-primary"
              >
                <a-icon type="left"></a-icon>
              </button>
              <button
                @click="nextClick"
                style="border-top-left-radius:0;border-bottom-left-radius:0"
                class="fc-today-button fc-button fc-button-primary"
              >
                <a-icon type="right"></a-icon>
              </button>
            </div>
            <div class="calendar-button-list-left">
              <button
                :style="
                  viewType == 'dayGridMonth' && calendarType == viewType
                    ? 'border-top-right-radius:0;border-bottom-right-radius:0;box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 50%) !important;'
                    : 'border-top-right-radius:0;border-bottom-right-radius:0;'
                "
                class="fc-today-button fc-button fc-button-primary"
                @click="monthClick"
              >
                月
              </button>
              <button
                :style="
                  viewType == 'dayGridWeek' && calendarType == viewType
                    ? 'border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0;box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 50%) !important;'
                    : 'border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0'
                "
                class="fc-today-button fc-button fc-button-primary"
                @click="weekClick"
              >
                周
              </button>
              <button
                :style="
                  viewType == 'dayGridDay' && calendarType == viewType
                    ? 'border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0;box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 50%) !important;'
                    : 'border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0'
                "
                class="fc-today-button fc-button fc-button-primary"
                @click="dayClick"
              >
                天
              </button>
              <!--           <span
            @click="openAllView()"
            v-if="viewType == 'dayGridMonth'"
            ref="openBtnAll"
            :style="openAll ? 'transform:rotate(180deg)' : ''"
            class="iconfont icon-zhankai open-btn-all"
          ></span>
          <template v-for="(item, index) in openList">
            <div
              @click="openView(index)"
              :ref="'openBtn-' + index"
              class="open-btn"
              v-show="!item.isOpen && viewType == 'dayGridMonth'"
              :key="'openBtn-' + index"
            >
              <span class="iconfont icon-icon_function_zhankai"></span>
            </div>
          </template> 展开-->
            </div>
          </div>
          <div
            class="mask"
            v-show="is_show_mask"
            :style="'width:' + mask_width + 'left:' + mask_left + 'height:' + mask_height + 'top:' + mask_top"
          ></div>
          <FullCalendar v-if="rlShow" class="fullCalendar" ref="fullCalendar" :options="calendarOptions">
            <template v-slot:eventContent="arg">
              <div
                v-if="arg.event.title && arg.event.title != 'dropDiv'"
                :ref="arg.event.extendedProps.record.id"
                :id="
                  arg.event.extendedProps.record.chapterId +
                    '-' +
                    arg.event.extendedProps.record.productionId +
                    '-' +
                    arg.event.extendedProps.record.id
                "
                :style="'width:100%;margin-right:5px;margin-bottom:5px'"
              >
                <div
                  v-if="arg.event.title && arg.event.extendedProps.record.type != 'customEvent' && !isView"
                  :style="
                    'display: flex; justify-content: space-between;width:100%;' +
                      `border:${arg.event.extendedProps.record.border}`
                  "
                >
                  <div style="display: flex;overflow:hidden;width: 92%;position:relative">
                    <a-checkbox
                      @click.stop
                      :ref="arg.event.extendedProps.record.id + '-check'"
                      :id="arg.event.extendedProps.record.id + '-check'"
                      v-if="arg.event.title && isSelect"
                    ></a-checkbox>
                    <a-checkbox
                      @click.stop
                      :ref="arg.event.extendedProps.record.id + '-select'"
                      :id="arg.event.extendedProps.record.id + '-select'"
                      @change="
                        e => {
                          dropSelectChange(e, arg.event.extendedProps.record)
                        }
                      "
                      v-else-if="
                        arg.event.title && isDropSelect && arg.event.extendedProps.record.taskStatus != '已完成'
                      "
                    ></a-checkbox>
                    <span
                      style="margin-left:5px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;max-width:50%;display:inline-block"
                    >
                      {{
                        arg.event.extendedProps.record.productSequenceNo
                          ? arg.event.extendedProps.record.productSequenceNo + '-'
                          : ''
                      }}{{ arg.event.title.split('-')[0] }}
                    </span>
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>
                          {{
                            arg.event.extendedProps.record.productSequenceNo
                              ? arg.event.extendedProps.record.productSequenceNo + '-'
                              : ''
                          }}{{ arg.event.title.split('-')[0] }}
                          {{
                            '-' +
                              arg.event.title.split('-')[1] +
                              '-' +
                              arg.event.title.split('-')[2] +
                              '-' +
                              arg.event.title.split('-')[3] +
                              (arg.event.title.split('-')[4] ? '-' + arg.event.title.split('-')[4] : '')
                          }}
                        </span>
                      </template>
                      <span style="min-width:100px;display:inline-block">
                        {{
                          '-' +
                            arg.event.title.split('-')[1] +
                            '-' +
                            arg.event.title.split('-')[2] +
                            '-' +
                            arg.event.title.split('-')[3] +
                            (arg.event.title.split('-')[4] ? '-' + arg.event.title.split('-')[4] : '')
                        }}
                      </span>
                    </a-tooltip>
                  </div>
                  <div class="remark-div">
                    <actions-tasks
                      :isMyTask="isMyTask"
                      @openTaskModel="openTaskModel"
                      :isCalendar="true"
                      v-if="arg.event.extendedProps.record && 'customEvent' != arg.event.extendedProps.record.type"
                      :propsParams="arg.event.extendedProps.record"
                      :loadData="changeStatusData"
                    ></actions-tasks>
                    <div v-if="arg.event.extendedProps.record.hasNote" class="remark-item"></div>
                  </div>
                  <div
                    class="reject-icon"
                    v-if="
                      arg.event.extendedProps.record.taskStatus == '驳回' &&
                        arg.event.extendedProps.record.rejectTask &&
                        arg.event.extendedProps.record.rejectTask.taskName == '甲方反馈'
                    "
                  ></div>
                </div>
                <div
                  v-if="arg.event.title && arg.event.extendedProps.record.type == 'customEvent' && isView"
                  :style="
                    'display: flex; justify-content: space-between;width:100%;' +
                      `border:${arg.event.extendedProps.record.border}`
                  "
                >
                  <div @dblclick="openEditEvent(arg.event)" style="width:93%;">
                    <a-checkbox
                      @click.stop
                      @change="
                        e => {
                          selectChange(e, arg.event.extendedProps.record)
                        }
                      "
                      v-if="arg.event.title && isSelect"
                    ></a-checkbox>
                    <a-checkbox
                      @click.stop
                      @change="
                        e => {
                          dropSelectChange(e, arg.event.extendedProps.record)
                        }
                      "
                      v-else-if="
                        arg.event.title && isDropSelect && arg.event.extendedProps.record.taskStatus != '已完成'
                      "
                    ></a-checkbox>
                    <input
                      type="radio"
                      style="position: relative;top: -1px;left: 3px;"
                      :checked="arg.event.extendedProps.checked == 'YES' ? true : false"
                      @click="
                        e => {
                          radioClick(e, arg.event)
                        }
                      "
                    />
                    <span
                      :style="
                        arg.event.extendedProps.checked == 'YES'
                          ? 'text-decoration:line-through;position: relative;top: 1px;left: 8px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;width: 90%;display: inline-block;'
                          : 'position: relative;top: 1px;left: 8px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;width: 90%;display: inline-block;'
                      "
                      >{{ arg.event.title }}</span
                    >
                  </div>
                  <div v-if="arg.event.extendedProps.record" style="margin-right: 4px" @click="removeEvent(arg.event)">
                    <a-icon class="delete-icon" type="delete" />
                  </div>
                </div>
              </div>
              <div v-else-if="arg.event.title == 'dropDiv'" class="drop-list"></div>
            </template>
          </FullCalendar>
          <div class="day-show" :style="'height:' + dayHeight">
            <a-row>
              <a-col :span="12" style="padding-right:2vw">
                <div class="day-show-num">
                  <div>
                    <span style="font-size:16px;">今日任务</span>
                  </div>
                  <div>
                    <span>已完成：</span>
                    <span>{{ overNum }}</span>
                    <span style="margin-left:15px">待完成：</span>
                    <span>{{ waitNum }}</span>
                  </div>
                </div>
                <template v-for="item in dayList">
                  <div v-if="item.title" style="width:100%;margin-bottom: 5px;position: relative;" :key="item.id">
                    <div
                      v-if="item.title && item.record.type != 'customEvent'"
                      :style="
                        'display: flex; justify-content: space-between;width:100%;' +
                          `background-color:${item.record.backGroundColor};color:${item.record.textColor};border:${item.record.border}`
                      "
                    >
                      <div
                        class="day-view-event"
                        @click.stop="handleEventDayClick(item)"
                        :id="item.record.chapterId + '-' + item.record.id + '-' + item.record.productionId"
                        style="width:100%;padding-left:5px;display: flex;"
                      >
                        <a-checkbox
                          @click.stop
                          :ref="item.record.id + '-check'"
                          :id="item.record.id + '-check'"
                          @change="
                            e => {
                              selectDayChange(e, item.record)
                            }
                          "
                          v-if="item.title && isSelect"
                        ></a-checkbox>
                        <!-- <span style="font-size:12px;margin-left:5px;">{{ item.title }}</span> -->
                        <span
                          class="day-view-event"
                          style="margin-left:5px;display:inline-block"
                          :id="item.record.chapterId + '-' + item.record.id + '-' + item.record.productionId + '-order'"
                        >
                          {{ item.record.productSequenceNo ? item.record.productSequenceNo + '-' : ''
                          }}{{ item.title.split('-')[0] }}
                        </span>
                        <span
                          class="day-view-event"
                          style="min-width:100px;display:inline-block"
                          :id="
                            item.record.chapterId + '-' + item.record.id + '-' + item.record.productionId + '-content'
                          "
                        >
                          {{
                            '-' +
                              item.title.split('-')[1] +
                              '-' +
                              item.title.split('-')[2] +
                              '-' +
                              item.title.split('-')[3] +
                              (item.title.split('-')[4] ? '-' + item.title.split('-')[4] : '')
                          }}
                        </span>
                      </div>
                      <div
                        class="remark-div day-view-event"
                        :id="
                          item.record.chapterId + '-' + item.record.id + '-' + item.record.productionId + '-remarkDiv'
                        "
                      >
                        <actions-tasks
                          v-if="item.record && 'customEvent' != item.record.type"
                          :propsParams="item.record"
                          :isMyTask="isMyTask"
                          :id="
                            item.record.chapterId + '-' + item.record.id + '-' + item.record.productionId + '-remark'
                          "
                          @openTaskModel="openTaskModel"
                          class="day-view-event"
                          :isCalendar="true"
                          :loadData="reDayloadData"
                        ></actions-tasks>
                        <div v-if="item.record.hasNote" class="remark-item"></div>
                      </div>
                    </div>
                    <div
                      class="reject-icon"
                      v-if="
                        item.record.taskStatus == '驳回' &&
                          item.record.rejectTask &&
                          item.record.rejectTask.taskName == '甲方反馈'
                      "
                    ></div>
                  </div>
                </template>
              </a-col>
              <a-col :span="12" style="padding-left:2vw">
                <div class="day-show-num">
                  <div>
                    <span style="font-size:16px;">今日待办</span>
                  </div>
                </div>
                <template v-for="item in eventList">
                  <div v-if="item.title" style="width:100%;margin-bottom: 5px;" :key="item.id">
                    <div :style="'display: flex; justify-content: space-between;width:100%;background-color:#f8f8f8'">
                      <!-- getBack({deadline:item.end}) -->
                      <div style="width:100%;padding-left:5px">
                        <input
                          type="radio"
                          style="position: relative;top: 2px;left: 3px;"
                          :value="item.checked == 'YES' ? true : false"
                          @click="
                            e => {
                              radioDayClick(e, item)
                            }
                          "
                        />
                        <span
                          :style="
                            item.checked == 'YES'
                              ? 'text-decoration:line-through;position: relative;top: 0;left: 8px;'
                              : 'position: relative;top: 0;left: 8px;'
                          "
                          >{{ item.title }}</span
                        >
                      </div>
                      <div v-if="item.record" style="margin-right: 4px" @click="removeEvent(item)">
                        <a-icon class="delete-icon" type="delete" />
                      </div>
                    </div>
                  </div>
                </template>
              </a-col>
            </a-row>
          </div>
          <a-modal title="添加事件" :visible="addEventDlgShow" @ok="handleAddEventOk" @cancel="handleAddEventCancel">
            <p>
              <a-textarea autoSize v-model="addEventText"></a-textarea>
            </p>
          </a-modal>
          <a-modal title="编辑事件" :visible="editEventDlgShow" @ok="handleEditEventOk" @cancel="handleAddEventCancel">
            <p>
              <a-textarea autoSize v-model="editEvent.text"></a-textarea>
            </p>
          </a-modal>
          <a-modal
            width="900px"
            :visible="needScheduleTask"
            @ok="handleEditdateOk"
            @cancel="handleAddEventCancel"
            destoryOnClose
            title="自动排期"
          >
            <span>自动排期：</span>
            <div style="display:flex;height: 40px;align-items: center;justify-content: space-between;">
              <a-radio-group @change="zdpqChange" v-model="taskRadioValue">
                <a-radio value="1">是</a-radio>
                <a-radio value="2">否</a-radio>
              </a-radio-group>
              <a-checkbox-group
                v-if="taskRadioValue == '1'"
                style="display:flex;flex: 1;justify-content: space-around;margin-left:15px"
                v-model="taskCheckedList"
                :options="plainOptions"
              />
            </div>
            <!--             <div style="margin-top:20px;" v-if="taskRadioValue == '2' && taskIds.length == 0">
              <a-row
                style="display: flex;align-items: center;font-weight:700;border-bottom:1px solid #e8e8e8;padding-bottom:10px"
              >
                <a-col :span="5">阶段</a-col>
                <a-col :span="5">人员</a-col>
                <a-col :span="7">当前截止日</a-col>
                <a-col :span="7">修改后的截止日</a-col>
              </a-row>
              <div class="scheduleTasks-list">
                <template v-for="(item, index) in scheduleTasks">
                  <a-row
                    :style="
                      index == scheduleTasks.length - 1
                        ? 'display: flex;align-items: center;padding-top:10px;padding-bottom:10px'
                        : 'display: flex;align-items: center;padding-top:10px;border-bottom:1px solid #e8e8e8;padding-bottom:10px'
                    "
                    v-if="!item.isShow"
                    :key="item.id"
                  >
                    <a-col :span="5">{{ item.stageName }}</a-col>
                    <a-col :span="5">{{ item.user && item.user.userName }}</a-col>
                    <a-col :span="7">{{ item.deadline && item.deadline.slice(0, 16) }}</a-col>
                    <a-col :span="7">
                      <a-date-picker
                        class="kanban-date"
                        style="font-size:12px;min-width:100px !important;"
                        format="YYYY-MM-DD HH:mm"
                        valueFormat="YYYY-MM-DD HH:mm"
                        :show-time="{ format: 'HH:mm' }"
                        placeholder="请选择截止日期"
                        v-model="item.deadlineNew"
                      />
                    </a-col>
                  </a-row>
                </template>
              </div>
            </div>
            <div style="margin-top:20px;" v-else-if="taskRadioValue == '2' && taskIds.length">
              <a-row
                style="display: flex;align-items: center;font-weight:700;border-bottom:1px solid #e8e8e8;padding-bottom:10px"
              >
                <a-col :span="3">章节序号</a-col>
                <a-col :span="4">作品名称</a-col>
                <a-col :span="4">阶段</a-col>
                <a-col :span="3">人员</a-col>
                <a-col :span="5">当前截止日</a-col>
                <a-col :span="5">修改后的截止日</a-col>
              </a-row>
              <div class="scheduleTasks-list">
                <template v-for="(item, index) in scheduleTasks">
                  <a-row
                    :style="
                      index == scheduleTasks.length - 1
                        ? 'display: flex;align-items: center;padding-top:10px;padding-bottom:10px'
                        : 'display: flex;align-items: center;padding-top:10px;border-bottom:1px solid #e8e8e8;padding-bottom:10px'
                    "
                    v-if="!item.isShow"
                    :key="item.id"
                  >
                    <a-col :span="3">{{ item.chapterSequenceNo }}</a-col>
                    <a-col :span="4">{{ item.productionName }}</a-col>
                    <a-col :span="4">{{ item.stageName }}</a-col>
                    <a-col :span="3">{{ item.user && item.user.userName }}</a-col>
                    <a-col :span="5">{{ item.deadline && item.deadline.slice(0, 16) }}</a-col>
                    <a-col :span="5">
                      <a-date-picker
                        class="kanban-date"
                        style="font-size:12px;min-width:100px !important;"
                        format="YYYY-MM-DD HH:mm"
                        valueFormat="YYYY-MM-DD HH:mm"
                        :show-time="{ format: 'HH:mm' }"
                        placeholder="请选择截止日期"
                        v-model="item.deadlineNew"
                      />
                    </a-col>
                  </a-row>
                </template>
              </div>
            </div> -->
            <div style="margin-top:20px;" v-if="taskRadioValue == '2'">
              <chapter-table ref="chapterTable" :scheduleTasks="scheduleTasks" />
            </div>
          </a-modal>
        </div>

        <!--         <div
          v-show="!isDesktop()"
          :style="{ width: this.isAccountCenter ? '100%' : '100%', position: 'absolute', left: '0', top: '0' }"
        >
          <MobileCalendar
            ref="MobileCalendar"
            :mobileLoading="mobileLoading"
            :mobileTasks="mobileTasks"
            :isAccountCenter="isAccountCenter"
            @clickCurrentDaySearch="clickCurrentDaySearch"
            :stageList="stageList"
            :schedule="schedule"
            :holiday="holiday"
            :isMyTask="isMyTask"
            @clickCurrentDay="clickCurrentDay"
          />
        </div> -->
      </div>
      <a-menu slot="overlay">
        <a-menu-item v-if="taskDetail.isLocal" key="11" @click="goToTerm(taskDetail, 1)">
          <span>查看术语表</span>
        </a-menu-item>
        <a-menu-item v-if="taskDetail.isLocal" key="10" @click="goToTerm(taskDetail, 3)">
          <span>查看关系图</span>
        </a-menu-item>
        <a-menu-item
          key="1"
          @click="goToTranslate(taskDetail)"
          v-if="
            taskDetail.applyTranslation &&
              ((taskDetail.taskStatus != '等待' && taskDetail.taskStatus != '驳回') ||
                (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
          "
        >
          <span>跳转翻译系统</span>
        </a-menu-item>
        <a-menu-item
          key="2"
          @click="goToFatchStraw(taskDetail)"
          v-if="
            taskDetail.applyReview &&
              ((taskDetail.taskStatus != '等待' && taskDetail.taskStatus != '驳回') ||
                (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId))
          "
        >
          <span>跳转查稿系统</span>
        </a-menu-item>
        <a-menu-item key="3" @click="goToUpload(taskDetail)" v-if="taskDetail.stageName == '给稿'">
          <span>上传原稿</span>
        </a-menu-item>
        <a-menu-item
          key="4"
          @click="gotoSumbmit(taskDetail)"
          v-if="
            taskDetail.applyReview &&
              (taskDetail.stageName == '制作' ||
                taskDetail.stageName == '制作修改' ||
                taskDetail.stageName == '监制' ||
                taskDetail.stageName == '监制确认' ||
                taskDetail.stageName.indexOf('建模') > -1 ||
                taskDetail.stageName.indexOf('精草') > -1 ||
                taskDetail.stageName.indexOf('勾线') > -1 ||
                taskDetail.stageName.indexOf('上色') > -1 ||
                taskDetail.stageName.indexOf('后期') > -1)
          "
        >
          <span>上传完成稿</span>
        </a-menu-item>
        <a-menu-item
          key="5"
          @click="downloadMake(taskDetail)"
          v-if="
            taskDetail.systemTypes &&
              taskDetail.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM') &&
              taskDetail.stageName == '制作'
          "
        >
          <span>下载制作稿</span>
        </a-menu-item>
        <a-menu-item
          key="6"
          @click="downloadOver(taskDetail, false)"
          v-if="
            taskDetail.applyReview &&
              (taskDetail.stageName == '监制确认' ||
                taskDetail.stageName == '勾线终审' ||
                taskDetail.stageName == '上色终审' ||
                taskDetail.stageName == '监制' ||
                taskDetail.stageName == '交稿')
          "
        >
          <span>下载终稿</span>
        </a-menu-item>
        <a-menu-item
          key="9"
          @click="downloadOver(taskDetail, true)"
          v-if="
            taskDetail.applyReview &&
              (taskDetail.stageName == '监制确认' || taskDetail.stageName == '监制' || taskDetail.stageName == '交稿')
          "
          >下载切片</a-menu-item
        >
        <a-menu-item
          key="7"
          @click="downloadJpg()"
          v-if="taskDetail.applyTranslation && taskDetail.stageName == '交稿'"
        >
          <span>下载翻译JPG</span>
        </a-menu-item>
        <a-menu-item
          key="8"
          @click="downloadPdf()"
          v-if="taskDetail.applyTranslation && taskDetail.stageName == '交稿'"
        >
          <span>下载翻译PDF</span>
        </a-menu-item>
        <a-menu-item @click="openDrawer" key="5">
          备注
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal
      title="备注"
      placement="right"
      :width="700"
      :footer="null"
      wrapClassName="drawer-layout"
      :visible="drawerVisible"
      @cancel="onClose"
    >
      <remark ref="remark" height="500px" :isMyTask="true" :taskDetail="taskDetail" :params="taskDetail" />
    </a-modal>
    <tasks-model ref="tasksModel" @closeTasksModel="closeTasksModel" />
    <a-modal
      :visible="confirmVisible"
      wrapClassName="confirm-modal"
      :bodyStyle="{ padding: '32px', paddingBottom: '24px' }"
      width="416px"
      :footer="null"
      @cancel="confirmVisible = false"
    >
      <div style="font-size:16px;display: flex;align-items: center;font-weight:500;color:rgba(0, 0, 0, 0.85)">
        <a-icon
          type="question-circle"
          style="font-size: 22px;margin-right:15px; color:orange;"
        />是否加载已锁定状态的文件?
      </div>
      <div style="height:62px;display: flex;align-items: end;justify-content: end;">
        <a-button @click="handleConfimCancel(confirmTask)">
          不加载
        </a-button>
        <a-button type="primary" style="margin-left:8px;" @click="handleConfimOk(confirmTask)">
          加载
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { axios } from '@/utils/request'
import Vue from 'vue'
import remark from '@/views/system/details/mytasks/modules/remark'
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasks'
import tasksModel from '@/views/system/modules/mytasks/modules/tasksModel'
import MobileCalendar from './components/MobileCalendar'
import { createAlink } from '@/utils/util'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import moment from 'moment'
import { checkPermission } from '@/utils/hasPermission'
import FullCalendar from '@fullcalendar/vue'
import { mixinDevice } from '@/utils/mixin.js'
import dayGridPlugin from '@fullcalendar/daygrid'
import { simpleDebounce } from '@/utils/util'
import debounce from 'lodash/debounce'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
import icsToJson from './ics-to-json'
import editItemSelectVue from '../../components/edit/edit-item-select.vue'
import draggable from 'vuedraggable'
import ChapterTable from '@/views/production/details/chapter-table.vue'
const stageBackgroundColor = {
  1: '#ffcfc0',
  2: '#ffdec0',
  3: '#ffedc0',
  4: '#fffcc0',
  5: '#f2ffc0',
  6: '#e3ffc0',
  7: '#d4ffc0',
  8: '#c5ffc0',
  9: '#c0ffca',
  10: '#c0ffd9',
  11: '#c0ffe8',
  12: '#c0fff7',
  13: '#c0f7ff',
  14: '#c0e8ff',
  15: '#c0d9ff',
  16: '#c0caff',
  17: '#c5c0ff',
  18: '#d4c0ff',
  19: '#e3c0ff',
  20: '#f2c0ff',
  21: '#ffc0fc',
  22: '#ffc0ed',
  23: '#ffc0de',
  24: '#ffc0cf',
  交稿: '#ffc0fc',
  监制: '#c0caff',
  制作: '#c0ffe8',
  校对: '#e3ffc0',
  翻译: '#ffdec0',
  customEvent: '#d3caee'
}
function handleArrayParams(params) {
  return Object.fromEntries(
    Object.entries(params).flatMap(([k, v]) =>
      Array.isArray(v) ? v.map((value, index) => [`${k}[${index}]`, value]) : [[k, v]]
    )
  )
}
const stamptime = time => {
  var date = new Date(time)
  var Y = date.getFullYear() + ''
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + ''
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
  return Y + M + D
}
export default {
  components: {
    ActionsTasks,
    FullCalendar,
    MobileCalendar,
    draggable,
    tasksModel,
    remark,
    ChapterTable
  },
  mixins: [mixinDevice],
  props: {
    workDays: {
      type: Array,
      default: () => []
    },
    stageList: {
      type: Array,
      default: () => []
    },
    homeStyle: {
      type: String,
      default: '220px'
    },
    parent: {
      type: Object,
      default: () => {}
    },
    pushWorkDays: {
      type: Array,
      default: () => []
    },
    isMyTask: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    queryParam: {
      type: Object,
      default: () => {}
    },
    userInfo: {
      type: Object,
      default: () => {}
    },
    getWorkNum: {
      type: Function,
      default: () => {}
    },

    filterCheckList: {
      type: Array,
      default: () => ['排期', '节假日', '可安排数量', '已超过', '未排满', '拖稿', '已完成', '可开始', '等待', '驳回']
    },
    userIds: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isSkip: {
      type: Boolean,
      default: false
    },
    workdayAvailableCount: {
      type: Number,
      default: 0
    },
    isAccountCenter: {
      type: Boolean,
      default: false
    },
    selectKeys: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    tab: {
      type: String,
      default: '1'
    },
    calendarType: {
      type: String,
      default: 'dayGridMonth'
    }
  },
  data() {
    return {
      downProgress: 0,
      dayHeight: '0',
      mobileTasks: [],
      visible: false,
      mobileLoading: false,
      overNum: 0,
      taskRadioValue: '2',
      waitNum: 0,
      isSelect: false,
      isDropSelect: false,
      isShow: true,
      rlShow: true,
      editEventDlgShow: false,
      addEventDlgShow: false,
      editEvent: {},
      addEventDate: null,
      addEventText: '',
      calendarApi: null,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin], // 需要用哪个插件引入后放到这个数组里
        initialDate: new Date(), // 日历第一次加载时显示的初始日期。可以解析为Date的任何职包括ISO8601日期字符串，例如"2014-02-01"。 // 日历加载时的初始视图，默认值为'dayGridMonth'，可以为任何可用视图的值，如例如'dayGridWeek'，'timeGridDay'，'listWeek'
        initialView: this.calendarType, // 日历加载时的初始视图，默认值为'dayGridMonth'，可以为任何可用视图的值，如例如'dayGridWeek'，'timeGridDay'，'listWeek' // 日历加载时的初始视图，默认值为'dayGridMonth'，可以为任何可用视图的值，如例如'dayGridWeek'，'timeGridDay'，'listWeek'
        /* initialView: this.isAccountCenter ? 'dayGridWeek' : 'dayGridMonth', */ locale: 'zh-cn', // 设置日历的语言，中文为 “zh-cn”
        firstDay: '1', // 设置每周的第一天，默认值取决于当前语言环境，该值为代表星期几的数字，且值必须为整数，星期日=0
        weekNumberCalculation: 'ISO', // 指定"ISO"结果为ISO8601周数。指定"ISO"将firstDay的默认值更改为1（Monday）
        /* editable: true, */
        selectable: true,
        unselectAuto: false,
        eventOrder: 'eventOrder',
        height: 'auto',
        views: {
          //对应月视图
          dayGridMonth: {
            displayEventTime: false, //是否显示时间
            dayCellContent: item => {
              return {
                html: `<div class='day-title' id=${stamptime(item.date)}>
                  <div style="display: flex;"></div>
                <span><span>${item.dayNumberText}</span></span></div>`
              }
            }
          },
          dayGridWeek: {
            displayEventTime: false, //是否显示时间
            dayCellContent: item => {
              return {
                html: `<div class='day-title' id=${stamptime(item.date)}>
                  <div style="display: flex;"></div>
                <span><span>${item.dayNumberText}</span></span></div>`
              }
            }
          }
        }, //dayRender已废弃，使用views替换
        /*         buttonText: {
          // 文本将显示在headerToolbar / footerToolbar的按钮上。不支持HTML注入。所有特殊字符将被转义。
          today: '今天',
          month: '月',
          week: '周',
          day: '天'
        }, */
        headerToolbar: {
          // 在日历顶部定义按钮和标题。将headerToolbar选项设置为false不会显示任何标题工具栏。可以为对象提供属性start/center/end或left/center/right。这些属性包含带有逗号/空格分隔值的字符串。用逗号分隔的值将相邻显示。用空格分隔的值之间会显示一个很小的间隙。
          right: 'today',
          center: 'title',
          /* left: 'dayGridMonth,dayGridWeek,dayGridDay', */
          left: ''
        },
        eventTimeFormat: {
          // 在每个事件上显示的时间的格式
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        },
        events: this.fetchCalendarData,
        /* eventRender: (event,element)=>{this.calendarRender(event,element)}, */
        customButtons: {
          // 定义可在headerToolbar / footerToolbar中使用的自定义按钮
          today: {
            text: '今天', // 按钮的展示文本
            click: this.todayClick // 点击按钮触发的事件，这里要注意的是当按钮绑定了事件之后该按钮原本自带的事件将会失效
          },
          next: {
            click: this.nextClick
          },
          prev: {
            click: this.prevClick
          },
          dayGridDay: {
            text: '天',
            click: this.dayClick
          },
          dayGridWeek: {
            text: '周',
            click: this.weekClick
          },
          dayGridMonth: {
            text: '月',
            click: this.monthClick
          }
        },
        eventClick: this.handleEventClick, // 点击事件时，触发该回调
        eventMouseEnter: this.handleMouseEnter, // 鼠标悬停在事件上时，触发该回调
        eventMouseLeave: this.handleMouseLeave, // 鼠标移除时，触发该回调
        dateClick: this.handleDateClick, // 当用户单击日期或时间时,触发该回调，触发此回调，您必须加载interaction插件
        eventDrop: this.handleEventDrop,
        eventStartEditable: this.checkPermission(), //是否可拖拽
        select: this.selectDaysChange,
        eventDragStart: this.eventDragStart, //开始拖拽
        eventDragStop: this.eventDragStop //结束拖拽
        // eventContent: this.eventContentCallback,
      },
      isPrevOrNext: true,
      plainOptions: ['可工作日', '阶段耗时', '可安排数量'],
      taskCheckedList: ['可工作日', '阶段耗时', '可安排数量'],
      dayList: [],
      eventList: [],
      /* viewType: this.isAccountCenter ? 'dayGridWeek' : 'dayGridMonth', */
      viewType: this.calendarType,
      timer: null,
      holiday: [],
      workDay: [],
      schedule: [],
      adminWorks: [],
      radioValue: false,
      scheduleTasks: [],
      needScheduleTask: false,
      dropTimeStart: '',
      dropTimeEnd: '',
      moveData: {},
      drag: false,
      taskIds: [],
      tasks: [],
      allList: [],
      filterList: [],
      pushList: [],
      openAll: false,
      openList: [
        {
          isOpen: false
        },
        {
          isOpen: false
        },
        {
          isOpen: false
        },
        {
          isOpen: false
        },
        {
          isOpen: false
        },
        {
          isOpen: false
        }
      ],
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      select_list: [],
      is_show_mask: false,
      box_screen_left: 0,
      box_screen_top: 0,
      select_list_check: {},
      isDrag: false,
      showWidth: 0,
      isLoad: false,
      taskDetail: {},
      drawerVisible: false,
      confirmTask: {},
      confirmVisible: false
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      let height = this.isMyTask ? 'calc(100vh - 305px)' : 'calc(100vh - 395px)'
      return {
        '--theme-color': color,
        '--theme-height': height,
        height
      }
    },
    mask_width() {
      return `${Math.abs(this.end_x - this.start_x)}px;`
    },
    mask_top() {
      return `${Math.min(this.start_y, this.end_y) - this.box_screen_top}px;`
    },
    mask_left() {
      return `${Math.min(this.start_x, this.end_x) - this.box_screen_left}px;`
    },
    mask_height() {
      return `${Math.abs(this.end_y - this.start_y)}px;`
    }
  },
  created() {},
  mounted() {
    this.$bus.$on('reload', keys => {
      this.$nextTick(() => {
        this.reloadData()
      })
    })
    const that = this
    window.onresize = () => {
      return (() => {
        that.showWidth = document.body.clientWidth
      })()
    }
    this.isLoad = false
    this.$nextTick(() => {
      if (this.$refs.fullCalendar) {
        this.calendarApi = this.$refs.fullCalendar.getApi()
        if (this.viewType == 'dayGridMonth') {
          this.monthClick()
        } else if (this.viewType == 'dayGridWeek') {
          this.weekClick()
        } else if (this.viewType == 'dayGridDay') {
          this.dayClick()
        }
      }
    })
    const dom_box = document.querySelector('.box')
    if (dom_box.getBoundingClientRect()) {
      this.box_screen_left = dom_box.getBoundingClientRect().left
      this.box_screen_top = dom_box.getBoundingClientRect().top
    }
    setTimeout(() => {
      let div = document.getElementsByClassName('calendar-button-list')[0]
      document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].style.display = 'flex'
      document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].appendChild(div)
      let divLeft = document.getElementsByClassName('calendar-button-list-left')[0]
      document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].style.display = 'flex'
      document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].appendChild(divLeft)
      let workLeft = document.getElementsByClassName('work-left')[0]
      workLeft && document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].appendChild(workLeft)
      let workRight = document.getElementsByClassName('work-right')[0]
      workRight && document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].appendChild(workRight)
      /*    展开   if (this.viewType == 'dayGridMonth') {
        Array.from(document.getElementsByClassName('fc-scrollgrid-sync-table')[0].getElementsByTagName('tr')).forEach(
          (item, index) => {
            if (index == 0) {
              document.getElementsByClassName('fc-toolbar-title')[0].appendChild(this.$refs['openBtnAll'])
            }
            item.childNodes[0].appendChild(this.$refs['openBtn-' + index][0])
          }
        )
      } */
      if (this.isMyTask) {
        /*         if (!this.isAdmin) {
          this.addDayTitle()
        } else {
          this.addAdminDayTitle()
        } */
        this.addDayTitle()
      }
    }, 500)
  },
  methods: {
    isDesktop: simpleDebounce(async function() {
      this.isDesktopSim()
    }, 500),
    isDesktopSim() {
      if (this.device !== 'desktop') {
        this.$nextTick(() => {
          this.dayClick()
        })
      }
      return this.device === 'desktop'
    },
    zdpqChange() {
      if (this.taskRadioValue == '2') {
        this.$nextTick(() => {
          if (this.$refs.chapterTable) {
            this.scheduleTasks.forEach(item => {
              if (this.$refs.chapterTable.tableData.every(i => i.teamId != item.chapterOrder)) {
                this.$refs.chapterTable.tableData.push({
                  value: item.chapterOrder,
                  team: item.chapterOrder,
                  teamId: item.chapterOrder,
                  hasChild: true,
                  children: []
                })
              }
            })
            this.scheduleTasks.forEach(item => {
              this.$refs.chapterTable.tableData.forEach(i => {
                if (i.teamId == item.chapterOrder) {
                  item.teamId = item.id
                  i.children.push(item)
                }
              })
            })
            this.$refs.chapterTable.copyTableData = JSON.parse(JSON.stringify(this.$refs.chapterTable.tableData))
          }
        })
      }
    },
    openTaskModel(data) {
      this.$refs.tasksModel.open(data.noUpdateTasks, data.hasFeedback)
    },
    closeTasksModel(data) {
      if (this.viewType == 'dayGridDay') {
        if (data) {
          this.reDayloadData(data.updatedTasks)
        } else {
          this.reDayloadData([])
        }
      } else {
        if (data) {
          this.changeStatusData(data.updatedTasks)
        } else {
          this.changeStatusData([])
        }
      }
    },
    onClose() {
      this.drawerVisible = false
      this.loadData()
    },
    openDrawer() {
      this.visible = false
      this.drawerVisible = true
      this.$nextTick(() => {
        if (this.$refs.remark) {
          this.$refs.remark.getUserList()
          this.$refs.remark.getRemarkList()
        }
      })
    },
    contextmenu(e) {
      if (this.viewType != 'dayGridDay') {
        if (e.target.className.indexOf('fc-daygrid-event') > -1) {
          this.$nextTick(() => {
            this.visible = true
          })
          this.taskDetail.chapterId = e.target.getElementsByClassName('fc-event-main')[0].firstChild.id.split('-')[0]
          this.taskDetail.taskId = e.target.getElementsByClassName('fc-event-main')[0].firstChild.id.split('-')[2]
          this.taskDetail.productionId = e.target.getElementsByClassName('fc-event-main')[0].firstChild.id.split('-')[1]
          let record = this.allList.find(item => item.record.taskId == this.taskDetail.taskId).record
          this.taskDetail.stageId = record.stageId
          this.taskDetail.stageList = record.stageList
          this.taskDetail.applyReview = record.applyReview
          this.taskDetail.applyTranslation = record.applyTranslation
          this.taskDetail.stageName = record.stageName
          this.taskDetail.productName = record.productName
          this.taskDetail.productionId = record.productionId
          this.taskDetail.chapterOrder = record.chapterOrder
          this.taskDetail.platformId = record.platformId
          this.taskDetail.taskStatus = record.taskStatus
          this.taskDetail.productOrder = record.productOrder
          this.taskDetail.departName = record.departName
          this.taskDetail.isLocal = record.isLocal
        } else {
          this.$nextTick(() => {
            this.visible = false
          })
        }
      } else {
        if (e.target.className.indexOf('day-view-event') > -1) {
          this.$nextTick(() => {
            this.visible = true
          })
          this.taskDetail.chapterId = e.target.id.split('-')[0]
          this.taskDetail.taskId = e.target.id.split('-')[1]
          this.taskDetail.productionId = e.target.id.split('-')[2]
          let record = this.allList.find(item => item.record.taskId == this.taskDetail.taskId).record
          this.taskDetail.stageId = record.stageId
          this.taskDetail.stageList = record.stageList
          this.taskDetail.applyReview = record.applyReview
          this.taskDetail.applyTranslation = record.applyTranslation
          this.taskDetail.stageName = record.stageName
          this.taskDetail.productName = record.productName
          this.taskDetail.productionId = record.productionId
          this.taskDetail.chapterOrder = record.chapterOrder
          this.taskDetail.platformId = record.platformId
          this.taskDetail.taskStatus = record.taskStatus
          this.taskDetail.productOrder = record.productOrder
          this.taskDetail.departName = record.departName
        } else {
          this.$nextTick(() => {
            this.visible = false
          })
        }
      }
    },
    contextmenuClick() {
      this.visible = false
    },
    downloadJpg() {
      localStorage.setItem('translate_download', 'JPG')
      /* window.open(`/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`) */
      const newUrl = `/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    downloadPdf() {
      localStorage.setItem('translate_download', 'PDF')
      /* window.open(`/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`) */
      const newUrl = `/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    debounceTitle() {
      if (this.queryParam.startDate != document.getElementsByClassName('fc-daygrid-day')[0].dataset.date) return
      let data = new URLSearchParams(
        handleArrayParams(
          this.$route.params.userId
            ? {
                userIds: this.$route.params.userId ? [] : this.userIds,
                userId: this.$route.params.userId || null,
                startDate: this.queryParam.startDate,
                endDate: this.queryParam.endDate
              }
            : {
                userIds: this.$route.params.userId ? [] : this.userIds,
                startDate: this.queryParam.startDate,
                endDate: this.queryParam.endDate
              }
        )
      ).toString()
      this.adminWorks = []
      getAction('tasks/taskSchedule?' + data, {}).then(res => {
        this.isShow = true
        let result = res.data
        for (let key in result) {
          for (let adminKey in result[key]) {
            let item = {
              key: key + '-' + adminKey,
              data: result[key][adminKey] || []
            }
            item.data.length && this.adminWorks.push(item)
          }
        }
        if (this.filterCheckList.indexOf('已超过') == -1) {
          this.adminWorks = this.adminWorks.filter(item => {
            return item.key.split('-')[1] != 'exceededUsers'
          })
        }
        if (this.filterCheckList.indexOf('拖稿') == -1) {
          this.adminWorks = this.adminWorks.filter(item => {
            return item.key.split('-')[1] != 'delayUsers'
          })
        }
        if (this.filterCheckList.indexOf('未排满') == -1) {
          this.adminWorks = this.adminWorks.filter(item => {
            return item.key.split('-')[1] != 'arrangeableUsers'
          })
        }
        setTimeout(() => {
          Array.from(document.getElementsByClassName('admin-day-title'))?.forEach(item => {
            item.parentNode.removeChild(item)
          })
        }, 500)
        setTimeout(() => {
          Array.from(document.getElementsByClassName('day-title')).forEach((item, index) => {
            this.adminWorks?.forEach(adminWork => {
              if (adminWork.key.split('-')[0] == item.id) {
                if (adminWork.key.split('-')[1] == 'arrangeableUsers') {
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.display = 'inline-block')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.order = '1')
                  if (this.$refs['adminDayTitle' + adminWork.key][0])
                    item.childNodes[1].appendChild(this.$refs['adminDayTitle' + adminWork.key][0].$el)
                } else if (adminWork.key.split('-')[1] == 'exceededUsers') {
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.display = 'inline-block')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.marginLeft = '5px')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.backgroundColor = '#dd5652')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.color = '#fff')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.order = '3')
                  if (this.$refs['adminDayTitle' + adminWork.key][0])
                    item.childNodes[1].appendChild(this.$refs['adminDayTitle' + adminWork.key][0].$el)
                } else {
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.display = 'inline-block')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.marginLeft = '5px')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.backgroundColor = '#fff9b7')
                  this.$refs['adminDayTitle' + adminWork.key][0] &&
                    (this.$refs['adminDayTitle' + adminWork.key][0].$el.style.order = '2')
                  if (this.$refs['adminDayTitle' + adminWork.key][0])
                    item.childNodes[1].appendChild(this.$refs['adminDayTitle' + adminWork.key][0].$el)
                }
              }
            })
          })
        }, 1500)
      })
    },
    getRadioDis() {
      let arr = []
      this.taskIds?.forEach(item => {
        arr.push(this.allList.find(i => i.id == item))
      })
      let isDis = false
      arr.forEach(item => {
        arr.forEach(i => {
          if (i.id != item.id && i.record.chapterId == item.record.chapterId) {
            isDis = true
          }
        })
      })
      return isDis
    },
    handleMouseDown(event) {
      if (!this.isDropSelect && !this.isSelect) return
      if (event.target.tagName === 'SPAN') return false
      const dom_box = document.querySelector('.box')
      this.box_screen_left = dom_box.getBoundingClientRect().left
      this.box_screen_top = dom_box.getBoundingClientRect().top
      this.is_show_mask = true
      this.start_x = event.clientX
      this.start_y = event.clientY
      this.end_x = event.clientX
      this.end_y = event.clientY
      document.body.addEventListener('mousemove', this.handleMouseMove)
      document.body.addEventListener('mouseup', this.handleMouseUp)
    },
    handleMouseMove(event) {
      if (this.isDrag) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      this.end_x = event.clientX
      this.end_y = event.clientY
    },
    resSetXY() {
      this.start_x = 0
      this.start_y = 0
      this.end_x = 0
      this.end_y = 0
    },
    handleDomSelect() {
      const dom_mask = window.document.querySelector('.mask')
      const rect_select = dom_mask.getClientRects()[0]

      const add_list = []
      const del_list = []
      if (this.viewType == 'dayGridDay') {
        document
          .getElementsByClassName('day-show')[0]
          .querySelectorAll('.ant-checkbox-wrapper')
          .forEach((node, index) => {
            if (!node.getClientRects()[0]) return
            const rects = node.getClientRects()[0]
            if (this.collide(rects, rect_select) === true) {
              let arr = []
              if (this.isDropSelect) {
                arr = this.allList.filter(item => {
                  return item.record.taskStatus != '已完成' && item.title != 'dropDiv'
                })
              } else if (this.isSelect) {
                arr = this.allList.filter(item => {
                  return item.title != 'dropDiv'
                })
              }
              let nodeId = node.getElementsByClassName('ant-checkbox-input')[0].id.split('-')[0]
              console.log(arr)
              if (
                this.select_list?.includes(
                  arr?.find(item => {
                    return item.id == nodeId
                  }).id
                )
              ) {
                del_list.push(
                  arr.find(item => {
                    return item.id == nodeId
                  }).id
                )
              } else {
                add_list.push(
                  arr.find(item => {
                    return item.id == nodeId
                  }).id
                )
              }
            }
          })
      } else {
        this.$refs.fullCalendar.$el.querySelectorAll('.ant-checkbox-wrapper').forEach((node, index) => {
          if (!node.getClientRects()[0]) return
          const rects = node.getClientRects()[0]
          if (this.collide(rects, rect_select) === true) {
            let arr = []
            if (this.isDropSelect) {
              arr = this.allList.filter(item => {
                return item.record.taskStatus != '已完成' && item.title != 'dropDiv'
              })
            } else if (this.isSelect) {
              arr = this.allList.filter(item => {
                return item.title != 'dropDiv'
              })
            }
            let nodeId = node.getElementsByClassName('ant-checkbox-input')[0].id.split('-')[0]
            if (
              this.select_list.includes(
                arr.find(item => {
                  return item.id == nodeId
                }).id
              )
            ) {
              del_list.push(
                arr.find(item => {
                  return item.id == nodeId
                }).id
              )
            } else {
              add_list.push(
                arr.find(item => {
                  return item.id == nodeId
                }).id
              )
            }
          }
        })
      }
      this.select_list = this.select_list.concat(add_list).filter(item => !del_list.includes(item))
      if (this.isDropSelect) {
        this.allList
          .filter(item => {
            return item.record.taskStatus != '已完成' && item.title != 'dropDiv'
          })
          .forEach(item => {
            if (this.$refs[item.id + '-select']) {
              if (this.select_list.some(i => i == item.id)) {
                this.$refs[item.id + '-select']._props.checked = true
                this.dropSelectChange({ target: { checked: true } }, item.record)
              } else {
                this.$refs[item.id + '-select']._props.checked = false
                this.dropSelectChange({ target: { checked: false } }, item.record)
              }
            }
          })
        this.taskIds = []
        this.allList
          .filter(item => {
            return item.record.taskStatus != '已完成' && item.title != 'dropDiv'
          })
          .forEach(item => {
            if (
              this.$refs[item.id + '-select'] &&
              this.$refs[item.id + '-select']._props.checked &&
              this.taskIds.every(i => i != item.record.id)
            ) {
              this.taskIds.push(item.record.id)
            } else if (
              this.$refs[item.id + '-select'] &&
              !this.$refs[item.id + '-select']._props.checked &&
              this.taskIds.some(i => i == item.record.id)
            ) {
              this.taskIds.splice(this.taskIds.indexOf(item.record.id), 1)
              let moveDiv = this.$refs[data.id]
              let parent = moveDiv.parentNode.parentNode.parentNode.parentNode
              if (!this.taskIds.length) {
                parent.getElementsByClassName('drop-list')[0].style.boxShadow = 'none'
              }
            }
          })
      } else if (this.isSelect) {
        if (this.viewType == 'dayGridDay') {
          this.allList
            .filter(item => {
              return item.title != 'dropDiv'
            })
            .forEach(item => {
              if (this.$refs[item.id + '-check'][0]) {
                if (this.select_list.some(i => i == item.id)) {
                  console.log(this.$refs[item.id + '-check'], 123)
                  this.$refs[item.id + '-check'][0]._props.checked = true
                  this.selectChange({ target: { checked: true } }, item.record)
                } else {
                  this.$refs[item.id + '-check'][0]._props.checked = false
                  this.selectChange({ target: { checked: false } }, item.record)
                }
              } else if (this.$refs[item.id + '-check']) {
                if (this.select_list.some(i => i == item.id)) {
                  this.$refs[item.id + '-check']._props.checked = true
                  this.selectChange({ target: { checked: true } }, item.record)
                } else {
                  this.$refs[item.id + '-check']._props.checked = false
                  this.selectChange({ target: { checked: false } }, item.record)
                }
              }
            })
        } else {
          this.allList
            .filter(item => {
              return item.title != 'dropDiv'
            })
            .forEach(item => {
              if (this.$refs[item.id + '-check']) {
                if (this.select_list.some(i => i == item.id)) {
                  this.$refs[item.id + '-check']._props.checked = true
                  this.selectChange({ target: { checked: true } }, item.record)
                } else {
                  this.$refs[item.id + '-check']._props.checked = false
                  this.selectChange({ target: { checked: false } }, item.record)
                }
              }
            })
        }
      }
    },
    handleMouseUp() {
      document.body.removeEventListener('mousemove', this.handleMouseMove)
      document.body.removeEventListener('mouseup', this.handleMouseUp)
      this.is_show_mask = false
      this.handleDomSelect()
      this.resSetXY()
    },
    collide(rect1, rect2) {
      const maxX = Math.max(rect1.x + rect1.width, rect2.x + rect2.width)
      const maxY = Math.max(rect1.y + rect1.height, rect2.y + rect2.height)
      const minX = Math.min(rect1.x, rect2.x)
      const minY = Math.min(rect1.y, rect2.y)
      if (maxX - minX <= rect1.width + rect2.width && maxY - minY <= rect1.height + rect2.height) {
        return true
      } else {
        return false
      }
    },
    openAllView() {
      if (this.openAll) {
        this.calendarApi.removeAllEvents()
        this.calendarApi.addEventSource(this.filterList)
        this.openAll = false
        this.openList.forEach(item => {
          item.isOpen = false
        })
        this.$forceUpdate()
      } else {
        this.calendarApi.removeAllEvents()
        this.calendarApi.addEventSource(this.allList)
        this.pushList = this.allList
        this.openAll = true
        this.openList.forEach(item => {
          item.isOpen = true
        })
        this.$forceUpdate()
      }
    },
    openView(index) {
      if (this.openList[index].isOpen) return
      this.calendarApi.removeAllEvents()
      this.calendarApi.addEventSource(this.filterList)
      let dateArr = []
      Array.from(
        document
          .getElementsByClassName('fc-scrollgrid-sync-table')[0]
          .getElementsByTagName('tr')
          [index].getElementsByClassName('fc-daygrid-day')
      ).forEach(item => {
        dateArr.push(item.dataset.date)
      })
      this.pushList = []
      dateArr.forEach(date => {
        let list = this.allList.filter(item => {
          return item.start.split(' ')[0] == date
        })
        let arr = []
        list.forEach(item => {
          if (
            this.filterList
              .filter(i => {
                return i.start.split(' ')[0] == date
              })
              .every(i => {
                return i.id != item.id
              })
          ) {
            arr.push(item)
          }
        })
        this.pushList = [...this.pushList, ...arr]
        this.calendarApi.addEventSource(arr)
      })
      this.pushList = [...this.filterList, ...this.pushList]
      this.openList.forEach(item => {
        item.isOpen = false
      })
      this.openList[index].isOpen = true
    },
    getStatusLabel(value) {
      let text = ''
      switch (value) {
        case 'WAITING':
          text = '等待'
          break
        case 'STARTED':
          text = '可开始'
          break
        case 'REJECTING':
          text = '驳回'
          break
        case 'FINISHED':
          text = '已完成'
          break
      }
      return text
    },
    reDayloadData() {
      let info = {
        start: this.calendarApi.currentData.currentDate,
        end: this.calendarApi.currentData.currentDate
      }
      this.fetchCalendarDayData(info)
    },
    changeStatusData(data) {
      if (this.viewType == 'dayGridDay') {
        let info = {
          start: this.calendarApi.currentData.currentDate,
          end: this.calendarApi.currentData.currentDate
        }
        this.fetchCalendarDayData(info)
        return
      }
      if (data && data.updatedTasks) {
        data.updatedTasks?.forEach(item => {
          let index = this.allList.findIndex(i => {
            return i.id == item.taskId
          })
          if (index > -1) {
            this.allList[index].record.taskStatus = this.getStatusLabel(item.taskStatus)
            this.allList[index].textColor =
              this.allList[index].record.taskStatus == '已完成'
                ? 'green'
                : this.allList[index].record.taskStatus == '等待'
                ? 'grey'
                : this.allList[index].record.taskStatus == '驳回'
                ? 'orange'
                : 'red'
            this.allList[index].record.rejectTaskId = item.rejectTaskId
            this.allList[index].record.hasBeenReject = item.hasBeenReject
            this.allList[index].record.finishStatus = item.finishStatus
            this.allList[index].record.priority = item.priority
            this.allList[index].record.deadline = item.deadline
            this.allList[index].start = item.deadline
          }
          let pushIndex = this.pushList.findIndex(i => {
            return i.id == item.taskId
          })
          if (pushIndex > -1) {
            this.pushList[pushIndex].record.taskStatus = this.getStatusLabel(item.taskStatus)
            this.pushList[pushIndex].textColor =
              this.pushList[pushIndex].record.taskStatus == '已完成'
                ? 'green'
                : this.pushList[pushIndex].record.taskStatus == '等待'
                ? 'grey'
                : this.pushList[pushIndex].record.taskStatus == '驳回'
                ? 'orange'
                : 'red'
            this.pushList[pushIndex].record.rejectTaskId = item.rejectTaskId
            this.pushList[pushIndex].record.hasBeenReject = item.hasBeenReject
            this.pushList[pushIndex].record.finishStatus = item.finishStatus
            this.pushList[pushIndex].record.priority = item.priority
            this.pushList[pushIndex].record.deadline = item.deadline
            this.pushList[pushIndex].start = item.deadline
          }
        })
      } else {
        data?.forEach(item => {
          let index = this.allList.findIndex(i => {
            return i.id == item.taskId
          })
          if (index > -1) {
            this.allList[index].record.taskStatus = this.getStatusLabel(item.taskStatus)
            this.allList[index].textColor =
              this.allList[index].record.taskStatus == '已完成'
                ? 'green'
                : this.allList[index].record.taskStatus == '等待'
                ? 'grey'
                : this.allList[index].record.taskStatus == '驳回'
                ? 'orange'
                : 'red'
            this.allList[index].record.rejectTaskId = item.rejectTaskId
            this.allList[index].record.hasBeenReject = item.hasBeenReject
            this.allList[index].record.finishStatus = item.finishStatus
            this.allList[index].record.priority = item.priority
            this.allList[index].record.deadline = item.deadline
            this.allList[index].start = item.deadline
          }
          let pushIndex = this.pushList.findIndex(i => {
            return i.id == item.taskId
          })
          if (pushIndex > -1) {
            this.pushList[pushIndex].record.taskStatus = this.getStatusLabel(item.taskStatus)
            this.pushList[pushIndex].textColor =
              this.pushList[pushIndex].record.taskStatus == '已完成'
                ? 'green'
                : this.pushList[pushIndex].record.taskStatus == '等待'
                ? 'grey'
                : this.pushList[pushIndex].record.taskStatus == '驳回'
                ? 'orange'
                : 'red'
            this.pushList[pushIndex].record.rejectTaskId = item.rejectTaskId
            this.pushList[pushIndex].record.hasBeenReject = item.hasBeenReject
            this.pushList[pushIndex].record.finishStatus = item.finishStatus
            this.pushList[pushIndex].record.priority = item.priority
            this.pushList[pushIndex].record.deadline = item.deadline
            this.pushList[pushIndex].start = item.deadline
          }
        })
      }

      this.pushList = this.pushList.filter(item => {
        return this.filterCheckList.indexOf(item.record.taskStatus) > -1
      })
      this.calendarApi.removeAllEvents()
      this.calendarApi.addEventSource(this.pushList)
      this.select_list = []
      this.$forceUpdate()
    },
    //开始拖拽事件
    onStart() {
      this.drag = true
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false
    },
    goToTranslate(task) {
      /*       this.$router.push({
        path: `/translate/editor/${this.taskDetail.taskId}/${this.taskDetail.productionId}/${this.taskDetail.chapterId}/${this.taskDetail.platformId}`
      }) */
      localStorage.setItem('translate_record', JSON.stringify(task))
      /* window.open(
        `/translate/editor/${this.taskDetail.taskId}/${this.taskDetail.productionId}/${this.taskDetail.chapterId}/${this.taskDetail.platformId}?productionName=` +
          this.taskDetail.productionName+'&targetLang='+this.targetLang
      ) */
      localStorage.setItem('translate_download', '')
      /* window.open(`/translate/${task.taskId}/${task.chapterId}/0/0`) */
      const newUrl = `/translate/${task.taskId}/${task.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    gotoSumbmit(record) {
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传完成稿`
      })
    },
    async downloadOver(data, isSection) {
      let arr = [data]
      let that = this
      arr.forEach(async (item, index) => {
        let productOrder = item.productOrder + ''
        if (productOrder.length == 1) {
          productOrder = '00' + productOrder
        } else if (productOrder.length == 2) {
          productOrder = '0' + productOrder
        }
        let chapterOrder = item.chapterOrder + ''
        if (chapterOrder.length == 1) {
          chapterOrder = '00' + chapterOrder
        } else if (chapterOrder.length == 2) {
          chapterOrder = '0' + chapterOrder
        }
        const token = Vue.ls.get(ACCESS_TOKEN)
        const res = await axios.post(
          Vue.prototype.API_BASE_URL +
            '/finish/manuscript/download?chapterId=' +
            item.chapterId +
            '&isSection=' +
            isSection,
          {},
          {
            headers: { 'X-Access-Token': token },
            responseType: 'blob',
            onDownloadProgress(progress) {
              // 监听下载进度的方法
              that.downProgress = Math.round((100 * progress.loaded) / progress.total)
              if (progress.total) {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productName + '-' + chapterOrder,
                    duration: that.downProgress == 100 ? 0.3 : null,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: that.downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${that.downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    }
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: that.downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${that.downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: that.downProgress == 100 ? 0.3 : null
                  })
                }
              } else {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productName + '-' + chapterOrder,
                    duration: null,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M'
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productName + '-' + chapterOrder,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M',
                    duration: null
                  })
                }
              }
              open = true
              console.log(that.downProgress, progress.loaded, progress.total, 123)
            }
          }
        )
        if (!res) return
        const blob = res
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        let fileName = item.productName + '-' + chapterOrder + '-终稿'
        if (this.taskDetail.departName.indexOf('原创部') == -1) {
          fileName = productOrder + '-' + chapterOrder
        }
        a.href = url
        a.download = fileName + '.zip'
        a.click()
        window.URL.revokeObjectURL(url)
        /*         this.$notification.open({
          key: 'downLoad' + index,
          message: '下载终稿-'+productOrder+'-'+item.productionName + '-' + chapterOrder,
          description: '已下载' + that.downProgress + 'M',
          duration: 0.3
        }) */
      })
    },
    async downloadMake(taskDetail) {
      let that = this
      this.loading = true
      const rest = await v1postAction('/psd/get_chapter_make_psd_link', {
        productionId: taskDetail.productionId,
        chapterId: taskDetail.chapterId
      })
      if (rest.code == 200) {
        let postData = { list: [] }
        rest.data?.forEach(data => {
          let makeArr = []
          data.textAreas.forEach(textArea => {
            if (!textArea.characterSentences.length) return
            if (textArea.characterSentences[0] && textArea.characterSentences[0].characterText == ' ') {
              textArea.characterSentences = textArea.characterSentences.filter((t, tIdx) => tIdx)
              if (textArea.showText[0] == ' ') {
                textArea.showText = textArea.showText.replace(' ', '')
              }
            }
            let textArr = textArea.showText.split('\n')
            var reg = /^[a-zA-Z]+$/

            textArr = textArr.sort((a, b) => a.replaceAll('.', '').length - b.replaceAll('.', '').length)
            let textArrLength = 0
            textArr[textArr.length - 1].split('').forEach(text => {
              if (reg.test(text) || text == ' ') {
                textArrLength = textArrLength + 0.5
              } else if (text == '.') {
                textArrLength = textArrLength + 0.3
              } else {
                textArrLength++
              }
            })
            textArrLength = textArrLength.toFixed(0) - 0
            let maxWidth =
              (
                textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
              ).toFixed(0) - 0
            if (textArea.textLayout == 2) {
              maxWidth =
                (
                  textArr.length *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
                ).toFixed(0) - 0
            }
            let maxHeight =
              (
                textArr.length *
                textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
              ).toFixed(0) - 0
            if (textArea.textLayout == 2) {
              maxHeight =
                (
                  textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
                ).toFixed(0) - 0
            }
            let changeWidth = ((maxWidth - textArea.textAreaTranslationRegion.width) / 2).toFixed(0) - 0
            let changeHeight = ((maxHeight - textArea.textAreaTranslationRegion.height) / 2).toFixed(0) - 0
            let pushArr = []
            let brIndex = [-1]
            let newBrIndex = [-1]
            let changeBrIndex = []
            let phoneticArr = []
            let pushPhoneticText = ''
            let theX = 0
            let theY = 0
            let phoneticBrIndexOftext = []
            let arr = []
            textArea.showTexts = []
            textArea.characterSentences.forEach((text, textIndex) => {
              if (text.characterText == '\n') text.characterText = '<br>'
              if (text.characterText == '<br>') newBrIndex.push(textIndex)
              if (text.phonetic && text.phonetic.phoneticText) {
                phoneticBrIndexOftext.push(newBrIndex[newBrIndex.length - 1])
              }
              if (text.characterText != '<br>') {
                arr.push(text)
              } else {
                textArea.showTexts.push({ pId: '', texts: arr, textIndex })
                arr = []
                if (
                  textIndex == textArea.characterSentences.length - 1 &&
                  !arr.length &&
                  text.characterText == '<br>'
                ) {
                  textArea.showTexts.push({
                    pId: '',
                    texts: [
                      {
                        ...textArea.characterSentences[0],
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      }
                    ],
                    pushTtextIndex: textIndex
                  })
                }
              }
              if (textIndex == textArea.characterSentences.length - 1 && arr.length) {
                textArea.showTexts.push({ pId: '', texts: arr, pushTtextIndex: textIndex })
              }
            })
            textArea.showTexts = textArea.showTexts.sort((a, b) => b.texts.length - a.texts.length)
            phoneticBrIndexOftext = Array.from(new Set(phoneticBrIndexOftext))
            let enNum = 0
            textArea.characterSentences.forEach((text, textIndex) => {
              let markArr = []
              if (text.characterText == '<br>') {
                brIndex.push(textIndex)
                enNum = 0
              }
              let minusWidth = 0
              /*               if (changeWidth < 0) {
                if (phoneticBrIndexOftext.length) {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                } else {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                }
              } */
              minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
              let textAlignWidth = 0
              if (text.font.textAlign == 'right' && textArea.showTexts[0]) {
                textAlignWidth = textArea.showTexts[0].texts.length * text.font.fontSize
              }
              theX =
                textArea.textLayout == 1
                  ? textArea.textAreaTranslationRegion.x - changeWidth + textAlignWidth
                  : textArea.textAreaTranslationRegion.x -
                    changeWidth +
                    phoneticBrIndexOftext.length * ((text.font.fontSize - 1) / 2 + 1) +
                    minusWidth
              theY =
                textArea.textLayout == 1
                  ? textArea.textAreaTranslationRegion.y - changeHeight + text.font.fontSize
                  : textArea.textAreaTranslationRegion.y - changeHeight + textAlignWidth
              let pushCharacterText = text.characterText == '<br>' ? '\r' : text.characterText
              if (
                textArea.characterSentences[textIndex - 1] &&
                textArea.characterSentences[textIndex - 1].characterText == '<br>' &&
                textArea.characterSentences[textIndex].characterText == ' '
              ) {
                pushCharacterText = ''
              }
              let textPushArr = [
                pushCharacterText == '\n' ? '\r' : pushCharacterText,
                (text.font.fontSize - 1) / 4 - 0,
                text.font.postScriptName,
                { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                text.font.textAlign,
                0.0,
                (text.font.lineSpacing * 100).toFixed(0) - 0,
                0.0,
                textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                theX,
                theY,
                text.font.strokeWidth || 0,
                text.font.strokeColor
                  ? {
                      red: text.font.strokeColor.red,
                      green: text.font.strokeColor.green,
                      blue: text.font.strokeColor.blue
                    }
                  : { red: 255, green: 255, blue: 255 },
                'antiAliasStrong',
                textArea.textLayout == 1 ? 0 : 1,
                0,
                text.characterSentenceId
              ]
              if (reg.test(text.characterText)) {
                if (
                  text.characterText == 'f' ||
                  text.characterText == 'i' ||
                  text.characterText == 'j' ||
                  text.characterText == 'l' ||
                  text.characterText == 't'
                ) {
                  enNum = enNum + 0.5
                } else {
                  if (text.characterText == 'A') {
                    enNum = enNum + 0.25
                  } /* else if(text.characterText=='B'){
                    enNum = enNum + 0.3
                  } */ else {
                    enNum = enNum + 0.3
                  }
                }
              }
              if (text.font.hasMark) {
                let markText = '.'
                let markX = 0
                if (!brIndex.length) {
                  markX = (textIndex - 1 - enNum) * text.font.fontSize + (text.font.fontSize / 2 - 0) - 3
                  if (textIndex > 8) {
                    markX = markX + ((textIndex - 8) / 3) * 2
                  }
                  if (text.font.isBold) {
                    markX = markX + textIndex
                  }
                } else {
                  markX =
                    (textIndex - brIndex[brIndex.length - 1] - 1 - enNum) * text.font.fontSize +
                    (text.font.fontSize / 2 - 0) -
                    3
                  if (textIndex - brIndex[brIndex.length - 1] > 8) {
                    markX = markX + ((textIndex - brIndex[brIndex.length - 1] - 8) / 3) * 2
                  }
                  if (text.font.isBold) {
                    markX = markX + (textIndex - brIndex[brIndex.length - 1])
                  }
                }
                if (text.font.textAlign == 'right') {
                  markX = theX - markX
                }
                let markPushArr = [
                  markText,
                  ((text.font.fontSize - 1) / 4).toFixed(0) - 0,
                  text.font.postScriptName,
                  { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                  { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                  text.font.textAlign,
                  0.0,
                  (text.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  text.font.textAlign == 'right' ? markX : theX + markX,
                  theY +
                    (brIndex.length - 2) +
                    (text.font.fontSize - 1) / 4 +
                    (((brIndex.length - 1) * text.font.fontSize * text.font.lineSpacing).toFixed(0) - 0),
                  text.font.strokeWidth || 0,
                  text.font.strokeColor
                    ? {
                        red: text.font.strokeColor.red,
                        green: text.font.strokeColor.green,
                        blue: text.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  0,
                  text.characterSentenceId
                ]
                markArr.push(markPushArr)
                makeArr.push(markArr)
              }
              pushArr.push(textPushArr)
              if (text.phonetic && text.phonetic.phoneticText) {
                if (text.phonetic.font) {
                  text.phonetic.font.postScriptName = text.font.postScriptName
                }
                let isFirstText = false
                if (!textIndex || textArea.characterSentences[textIndex - 1].characterText == '<br>') isFirstText = true
                phoneticArr.push({
                  targetCharacterIds: text.phonetic.targetCharacterIds,
                  brNum: brIndex.length - 1,
                  phonetic: text.phonetic,
                  text,
                  textIndex,
                  isFirstText
                })
              }
              if (
                phoneticArr.findIndex(p => p.textIndex == textIndex) > -1 &&
                phoneticArr.find(p => p.textIndex == textIndex).isFirstText &&
                phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText.length > 3
              ) {
                pushPhoneticText = ''
                let pushPhoneticTextLength = phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText
                  .length
                for (let i = 0; i < pushPhoneticTextLength - 3; i++) {
                  pushPhoneticText = pushPhoneticText + ' '
                }
              }
            })
            if (brIndex.length && phoneticArr.length) {
              brIndex.forEach((brIdx, brIdxIndex) => {
                brIdx = brIdx + 1 + brIdxIndex
                pushArr.splice(brIdx, 0, [
                  pushPhoneticText,
                  (phoneticArr[0].phonetic.font.fontSize - 1) / 4 - 0,
                  pushArr[0][2],
                  pushArr[0][3],
                  pushArr[0][4],
                  pushArr[0][5],
                  pushArr[0][6],
                  pushArr[0][7],
                  pushArr[0][8],
                  pushArr[0][9],
                  pushArr[0][10],
                  theX,
                  theY,
                  pushArr[0][13],
                  pushArr[0][14],
                  pushArr[0][15],
                  textArea.textLayout == 1 ? 0 : 1,
                  pushArr[0][17]
                ])
                changeBrIndex.push(brIdx - 1)
              })
            }
            if (phoneticArr.length) {
              let phoneticBrIndex = [...changeBrIndex]
              let pushPhoneticBrIndexArr = []
              let pushPhoneticBrNum = 0
              phoneticArr.forEach((p, pIdx) => {
                if (pushPhoneticBrIndexArr.indexOf(changeBrIndex[p.brNum]) > -1) {
                  return
                }
                pushArr.splice(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum, 0, [
                  '\r',
                  pushArr[0][1],
                  pushArr[0][2],
                  pushArr[0][3],
                  pushArr[0][4],
                  pushArr[0][5],
                  pushArr[0][6],
                  pushArr[0][7],
                  pushArr[0][8],
                  pushArr[0][9],
                  pushArr[0][10],
                  theX,
                  theY,
                  pushArr[0][13],
                  pushArr[0][14],
                  pushArr[0][15],
                  textArea.textLayout == 1 ? 0 : 1,
                  pushArr[0][17]
                ])
                /*                 changeBrIndex.forEach(c => {
                  if (
                    pushArr[c + pushPhoneticBrNum] &&
                    pushArr[c + pushPhoneticBrNum - 1] &&
                    pushArr[c + pushPhoneticBrNum][0] == '\r' &&
                    pushArr[c + pushPhoneticBrNum - 1][0] == '\r'
                  ) {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum - 1)
                  } else {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum)
                  }
                }) */
                let pushBrNum = changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum
                phoneticBrIndex[p.brNum] = pushBrNum
                /* phoneticBrIndex.push(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum) */
                pushPhoneticBrIndexArr.push(changeBrIndex[p.brNum])
                pushPhoneticBrNum++
              })
              phoneticArr = phoneticArr.reverse()
              let phoneticSzie = 0
              let moveNum = 0
              brIndex = []
              pushArr.forEach((text, textIndex) => {
                if (
                  text[0] == '\r' &&
                  ((pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r') ||
                    (pushArr[textIndex + 1] && pushArr[textIndex + 1][0] != '\r') ||
                    !textIndex)
                ) {
                  /* if(pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r'&&pushArr[textIndex] && pushArr[textIndex][0] == '\r'){
                    brIndex.push(textIndex)
                  } */
                  brIndex.push(textIndex)
                }
              })
              phoneticArr.forEach((p, pIdx) => {
                if (!p.targetCharacterIds) p.targetCharacterIds = []
                let menuis = brIndex[0] == 0 ? 0 : 1
                moveNum = pushArr.findIndex(text => text[18] == p.targetCharacterIds[0]) - brIndex[p.brNum - menuis] - 1
                /* textArea.showText
                  .split('\n')
                  [p.brNum].split('')
                  .forEach((t, tEnIdx) => {
                    if (
                      reg.test(t) &&
                      textArea.characterSentences.findIndex(te => te.characterSentenceId == p.targetCharacterIds[0]) >
                        tEnIdx
                    ) {
                      moveNum--
                    }
                  }) */
                p.moveNum = moveNum
              })
              let addTotal = phoneticArr.length
              let addArr = []
              phoneticArr.forEach((p, pIdx) => {
                phoneticSzie = (p.phonetic.font.fontSize - 1) / 4 - 0
                let sortNum = phoneticArr
                  .filter(cp => cp.brNum == p.brNum)
                  .sort((a, b) => {
                    return a.moveNum - b.moveNum
                  })
                let addNum = 1
                if (p.phonetic.phoneticText.length > 1) {
                  addNum = 0
                } else if (p.phonetic.phoneticText.length == 1) {
                  addNum = 2
                }
                if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                  addNum = -2
                }
                console.log(p.moveWidth, p, sortNum, addNum)
                if (p.moveNum != sortNum[0].moveNum) {
                  if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) {
                    if (p.phonetic.phoneticText.length > 2) {
                      addNum = 0
                    } else if (p.phonetic.phoneticText.length == 2) {
                      addNum = 2
                    } else if (p.phonetic.phoneticText.length == 1) {
                      addNum = 3
                    }
                    if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                      addNum = addNum + 1
                    if (!p.phonetic.font.postScriptName) {
                      this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                    }
                  }
                  p.moveWidth =
                    (p.moveNum - sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].moveNum - 1) * 8 + addNum
                  let changeNum =
                    (sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].targetCharacterIds.length - 1) * 4
                  /*                     sortNum.forEach((s,sIdx)=>{
                      if(sIdx<sortNum.findIndex(s => s.moveNum == p.moveNum)){
                        changeNum=changeNum+(s.targetCharacterIds.length-1)*3
                      }
                    }) */
                  p.moveWidth = p.moveWidth - changeNum
                  if (p.phonetic.phoneticText.length > 5 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 1
                  }
                  if (p.targetCharacterIds.length > 1) {
                    p.moveWidth = p.moveWidth + (p.targetCharacterIds.length - 1) * 4
                  }
                } else {
                  if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                    addNum = addNum + (p.moveNum == 1 ? 2 : 1)
                  if (!p.phonetic.font.postScriptName) {
                    this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                  }
                  if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) addNum = 1
                  p.moveWidth = (p.moveNum - 2 + p.targetCharacterIds.length) * 10 + addNum - 2
                  /* if (p.phonetic.phoneticText.length == 4 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 2
                  } */
                  /*  if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  }
                  if (p.phonetic.phoneticText.length == 2 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  } */
                  if (p.moveNum == 1) {
                    p.moveWidth = (p.targetCharacterIds.length - 1) * 4 + 2
                  }
                }
                let addText = ''
                for (let i = 0; i < p.moveWidth; i++) {
                  addText = addText + ' '
                }
                addArr.push({
                  index: phoneticBrIndex[p.brNum] + (phoneticBrIndex[p.brNum] ? addTotal : addTotal - 1),
                  addText,
                  text: p.text,
                  p
                })
                addTotal--
                pushArr.splice(phoneticBrIndex[p.brNum] ? phoneticBrIndex[p.brNum] + 1 : 0, 0, [
                  p.phonetic.phoneticText,
                  (p.phonetic.font.fontSize - 1) / 4 - 0,
                  p.phonetic.font.postScriptName,
                  {
                    red: p.text.font.fontColor.red,
                    green: p.text.font.fontColor.green,
                    blue: p.text.font.fontColor.blue
                  },
                  { bold: p.phonetic.font.isBold ? true : false, italic: p.phonetic.font.isIncline ? true : false },
                  'center',
                  p.phonetic.font.letterSpacing,
                  (p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  p.phonetic.font.textHeight * 100,
                  p.phonetic.font.textWidth * 100,
                  theX,
                  theY,
                  p.phonetic.font.strokeWidth || 0,
                  p.phonetic.font.strokeColor
                    ? {
                        red: p.phonetic.font.strokeColor.red,
                        green: p.phonetic.font.strokeColor.green,
                        blue: p.phonetic.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  1
                ])
              })
              addArr = addArr.reverse()
              addArr.forEach((a, aIdx) => {
                pushArr.splice(a.index + aIdx, 0, [
                  a.addText,
                  (a.p.phonetic.font.fontSize - 1) / 4 - 0,
                  'AdobeHeitiStd-Regular',
                  {
                    red: a.text.font.fontColor.red,
                    green: a.text.font.fontColor.green,
                    blue: a.text.font.fontColor.blue
                  },
                  { bold: a.p.phonetic.font.isBold ? true : false, italic: a.p.phonetic.font.isIncline ? true : false },
                  'center',
                  a.p.phonetic.font.letterSpacing,
                  (a.p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  100,
                  100,
                  theX,
                  theY,
                  a.p.phonetic.font.strokeWidth || 0,
                  a.p.phonetic.font.strokeColor
                    ? {
                        red: a.p.phonetic.font.strokeColor.red,
                        green: a.p.phonetic.font.strokeColor.green,
                        blue: a.p.phonetic.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  1
                ])
              })
              if (pushPhoneticText.length) {
                pushArr.forEach(a => {
                  if (a[16] == 1) {
                    a[11] = a[11] + pushPhoneticText.length * (phoneticSzie / 2)
                  } else {
                    a[12] = a[12] - pushPhoneticText.length * (phoneticSzie / 2)
                  }
                })
              }
            }
            console.log(pushArr) //pushPhoneticBrIndexArr有问题
            makeArr.push(pushArr)
          })

          postData.list.push(makeArr)
        })
        const res = await v1postAction('/psd/test', {
          ...postData,
          productionId: taskDetail.productionId,
          chapterId: taskDetail.chapterId
        })
        if (res.code == 1) {
          let name = taskDetail.productionName + '-' + taskDetail.chapterOrder + '-制作稿.zip'
          let xhr = new XMLHttpRequest()
          // GET请求,link,async(是否异步)
          xhr.open('GET', '/api/v1' + res.data, true)
          xhr.setRequestHeader('X-Access-Token', Vue.ls.get(ACCESS_TOKEN))
          xhr.responseType = 'blob'
          xhr.onload = function(e) {
            if (this.status == 200) {
              let blob = this.response
              let a = document.createElement('a')
              // blob.type = "application/octet-stream";
              //创键临时link对象
              let url = window.URL.createObjectURL(new Blob([blob]))
              a.href = url
              a.download = name
              a.click()
              // 释放之前创建的URL对象
              window.URL.revokeObjectURL(res.data)
              a.remove()
              that.loading = false
            } else {
              that.loading = false
            }
          }
          // //发送请求
          xhr.send()
        }
      } else {
        that.loading = false
        that.$message.error(res.msg)
      }
    },
    goToUpload(record) {
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传原稿`
      })
    },
    goToFatchStraw(task) {
      this.confirmVisible = true
      this.confirmTask = task
      /* if (task.departName.indexOf('原创部') > -1) {
        window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0?loadLock=true`)
      } else {
        this.confirmVisible = true
      } */
    },
    handleConfimCancel(task) {
      this.confirmVisible = false
      /* window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0`) */
      const newUrl = `/fatchStraw/${task.taskId}/${task.chapterId}/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    handleConfimOk(task) {
      this.confirmVisible = false
      /* window.open(`/fatchStraw/${task.taskId}/${task.chapterId}/0?loadLock=true`) */
      const newUrl = `/fatchStraw/${task.taskId}/${task.chapterId}/0?loadLock=true`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    async clickCurrentDaySearch(info) {
      if (this.isDesktop()) return
      let queryParam = {
        userIds: this.userIds[0] || this.userInfo.id,
        startDate: info.days[0].data,
        endDate: info.days[info.days.length - 1].data
      }
      if (info.stageIds && info.stageIds.length) {
        queryParam.stageIds = info.stageIds.join(',')
      }
      if (info.productionName) {
        queryParam.productionName = info.productionName
      }
      if (info.settlementMonth) {
        queryParam.settlementMonth = info.settlementMonth?.format('YYYY-MM') + '-01' || ''
      }
      this.mobileLoading = true
      const res = await getAction('/tasks/my?current=1&size=-1', queryParam)
      try {
        this.mobileTasks = res.data.records
        this.mobileLoading = false
      } catch (err) {
        this.mobileLoading = false
      }
    },
    async clickCurrentDay(info) {
      if (this.isDesktop()) return
      let endDate = new Date(info.data)
      endDate = endDate.setDate(endDate.getDate() + 1)
      endDate = this.insertStr(this.insertStr(stamptime(new Date(endDate)), 4, '-'), 7, '-')
      let queryParam = { userIds: this.userIds[0] || this.userInfo.id, startDate: info.data, endDate }
      this.mobileLoading = true
      const res = await getAction('/tasks/my?current=1&size=-1', queryParam)
      getAction('/workSchedule/userSchedule', {
        userId: this.userIds[0] || this.userInfo.id,
        startDate: info.days[0].data,
        endDate: info.days[info.days.length - 1].data
      }).then(rest => {
        this.schedule = []
        for (let key in rest.data.schedule) {
          let item = {
            key: key,
            data: rest.data.schedule[key] || 0,
            isEdit: false
          }
          this.schedule.push(item)
        }
      })
      try {
        this.mobileTasks = res.data.records
        this.mobileLoading = false
      } catch (err) {
        this.mobileLoading = false
      }
    },
    async enterInput(item) {
      await postAction('/workSchedule/', {
        startDate: this.insertStr(this.insertStr(item.key, 4, '-'), 7, '-'),
        type: 'NONE',
        userIds: [this.userInfo.id],
        count: item.count
      })
      try {
        this.$message.success('保存成功')
        item.isEdit = false
        this.reloadData()
      } catch (err) {
        console.log(err)
      }
    },
    titleSpanDbclick(item) {
      Array.from(document.getElementsByClassName('fc-daygrid-day')).forEach(i => {
        if (
          i.dataset.date == this.insertStr(this.insertStr(item.key, 4, '-'), 7, '-') &&
          !i.childNodes[0].childNodes[1].innerText
        ) {
          item.isEdit = true
        }
      })
    },
    titleInput(e, key) {
      if (this.$refs[key + '-widthDiv'][0].clientWidth > 10) {
        e.target.style.width = this.$refs[key + '-widthDiv'][0].clientWidth + 18 + 'px'
      }
      if (e.target.value.length <= 1) {
        e.target.style.width = '25px'
      }
    },
    titleInputClick(e) {
      e.target.focus()
    },
    getdate(startDate, endDate) {
      let dateArr = []
      Array.from(document.getElementsByClassName('fc-daygrid-day')).forEach(item => {
        dateArr.push({ time: item.dataset.date + ' 23:59:59', day: new Date(item.dataset.date + ' 23:59:59').getDay() })
      })
      var result = dateArr.filter(e => e.time >= startDate.toISOString() && e.time <= endDate.toISOString())
      return result
    },
    checkPermission() {
      return checkPermission('myTask:drop')
    },
    eventDragStart(e) {
      this.isDrag = true
      Array.from(document.getElementsByClassName('drop-list')).forEach(item => {
        if (item.childNodes.length) {
          e.el.childNodes[0].appendChild(item)
        }
      })
    },
    eventDragStop(e) {
      this.isDrag = false
      this.dropTimeStart = this.insertStr(
        this.insertStr(e.el.parentNode.parentNode.parentNode.childNodes[0].childNodes[0].id, 4, '-'),
        7,
        '-'
      )
    },
    insertStr(source, start, newStr) {
      return source.slice(0, start) + newStr + source.slice(start)
    },
    dateChange(num = 1, date = false) {
      if (!date) {
        date = new Date() //没有传入值时,默认是当前日期
        date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      }
      date += ' 00:00:00' //设置为当天凌晨12点
      date = Date.parse(new Date(date)) / 1000 //转换为时间戳
      date += 86400 * num //修改后的时间戳
      var newDate = new Date(parseInt(date) * 1000) //转换为时间
      var Y = newDate.getFullYear() + ''
      var M = (newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1) + ''
      var D = (newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()) + ''
      return Y + '-' + M + '-' + D
    },
    getNewDate(str) {
      var strArray = str.split(' ')
      var strDate = strArray[0].split('-')
      var strTime = strArray[1].split(':')
      var a = new Date(strDate[0], strDate[1] - parseInt(1), strDate[2], strTime[0], strTime[1], strTime[2])
      return a
    },
    async handleEditdateOk() {
      if (this.taskRadioValue == '2') {
        /*         if (
          this.scheduleTasks.length &&
          this.getNewDate(this.scheduleTasks[this.scheduleTasks.length - 1].deadlineNew) <
            this.getNewDate(this.scheduleTasks[this.scheduleTasks.length - 1].deadline)
        ) {
          this.$message.error('无法将后面阶段的任务拖动至前面阶段截止日前，请选择自动排期或调整其他任务后再做更改')
          return
        } */
        const res = await postAction(
          '/tasks/updateTasks',
          this.scheduleTasks.map(item => {
            delete item.rejectTask
            delete item.settlementDate
            return {
              ...item,
              id: item.id,
              taskId: item.taskId,
              deadline: item.deadlineNew?.length == 16 ? item.deadlineNew + ':00' : item.deadlineNew,
              taskStatus: null
            }
          })
        )
        if (res.code == 200) {
          this.$message.success('修改成功')
          this.needScheduleTask = false
          this.taskRadioValue = '2'
          this.reloadData()
        } else {
          this.$message.error(res.msg || res.message)
        }
      } else {
        if (this.taskIds.length) {
          const res = await postAction('/tasks/moveConfirmBatch', {
            taskIds: this.taskIds,
            targetDate: this.dropTimeEnd,
            isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
            isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
            isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量') // 是否考虑任务排满
          })
          if (res.code == 200) {
            this.$message.success('排期成功')
            this.needScheduleTask = false
            this.taskRadioValue = '2'
            this.reloadData()
          } else {
            this.$message.error(res.msg || res.message)
          }
          return
        }
        this.moveData = {
          ...this.moveData,
          isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
          isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
          isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量') // 是否考虑任务排满
        }
        const res = await postAction('/tasks/moveConfirm', this.moveData)
        if (res.code == 200) {
          this.$message.success('排期成功')
          this.needScheduleTask = false
          this.taskRadioValue = '2'
          this.reloadData()
        } else {
          this.$message.error(res.msg || res.message)
        }
      }
    },
    openEditEvent(event) {
      this.editEvent = { id: event.id, text: event.title, checked: event._def.extendedProps.checked || 'NO' }
      this.editEventDlgShow = true
    },
    radioClick(e, event) {
      if (event.extendedProps.checked == 'YES') {
        event._def.extendedProps = Object.assign({}, event._def.extendedProps, { checked: 'NO' })
        e.target.checked = false
      } else {
        event._def.extendedProps = Object.assign({}, event._def.extendedProps, { checked: 'YES' })
        e.target.checked = true
      }
      this.calendarOptions = this.reCalendarView()
      putAction(
        `/tasks/change_custom_event?id=${event.id.replace('custom_event_', '')}&checked=${
          event._def.extendedProps.checked
        }`
      ).then()
    },
    radioDayClick(e, item) {
      if (item.checked == 'YES') {
        item = Object.assign({}, item, { checked: 'NO' })
        e.target.checked = false
      } else {
        item = Object.assign({}, item, { checked: 'YES' })
        e.target.checked = true
      }
      putAction(`/tasks/change_custom_event?id=${item.id.replace('custom_event_', '')}&checked=${item.checked}`).then()
    },
    skipOtherTask(user) {
      this.$emit('setQueryParam', { id: user.id, isSkip: true, type: user.isCoordinator })
      this.reloadData()
    },
    insertStr(source, start, newStr) {
      return source.slice(0, start) + newStr + source.slice(start)
    },
    addDayTitle() {
      setTimeout(() => {
        Array.from(document.getElementsByClassName('day-title')).forEach(item => {
          let span = document.createElement('span')
          span.style.color = '#fbac00'
          span.style.marginRight = '2px'
          span.innerText = ''
          let title = document.createElement('span')
          title.innerText =
            item.lastChild.innerText
              .replace('假', '')
              .replace('班', '')
              .replace('休', '') || ''
          if (
            this.holiday.some(day => {
              return day.date == this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-') && day.type != 'WORK'
            })
          ) {
            if (item.lastChild.childNodes[0].innerText.length > 1) {
              item.lastChild.innerHTML = ''
              span.innerText = '假'
              item.lastChild.appendChild(span)
              item.lastChild.appendChild(title)
            }
          }
          this.holiday.forEach(day => {
            if (day.type == 'WORK') {
              if (
                day.date == this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-') &&
                item.lastChild.childNodes[0].innerText.length > 1
              ) {
                item.lastChild.innerHTML = ''
                span.innerText = '班'
                span.style.color = 'red'
                item.lastChild.appendChild(span)
                item.lastChild.appendChild(title)
              }
            }
          })
          this.workDay.forEach(day => {
            let date = day.date.split('-').join('')
            if (date == item.id && day.type == 'HOLIDAY') {
              if (item.lastChild.childNodes[0].innerText == '假') {
                item.lastChild.childNodes[0].innerText = '休'
              } else {
                item.lastChild.innerHTML = ''
                span.innerText = '休'
                span.style.color = 'red'
                item.lastChild.appendChild(span)
                item.lastChild.appendChild(title)
              }
            } else if (date == item.id && !day.type) {
              if (item.lastChild.childNodes[0].innerText == '休') {
                item.lastChild.childNodes[0].innerText = ''
              }
            } else if (date == item.id && day.type == 'WORK') {
              if (item.lastChild.childNodes[0].innerText == '假' || item.lastChild.childNodes[0].innerText == '休') {
                item.lastChild.innerHTML = ''
                span.innerText = '班'
                span.style.color = '#64b25a'
                item.lastChild.appendChild(span)
                item.lastChild.appendChild(title)
              } else if (item.lastChild.childNodes.length == 1 || !item.lastChild.childNodes[0].innerText) {
                item.lastChild.innerHTML = ''
                span.innerText = '班'
                span.style.color = '#64b25a'
                item.lastChild.appendChild(span)
                item.lastChild.appendChild(title)
              }
            }
          })
        })
        this.calendarOptions = this.reCalendarView()
      }, 1500)
      setTimeout(() => {
        Array.from(document.getElementsByClassName('day-title')).forEach(item => {
          if (this.workDays.length && this.workDays[0] != -1) {
            let arr = []
            this.workDays.forEach(w => {
              if (w == 7) {
                arr.push(0)
              } else {
                arr.push(w)
              }
            })
            if (
              arr.every(w => {
                return w != new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-')).getDay()
              })
            ) {
              let start = new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-'))
              if (
                (this.pushWorkDays.length && this.pushWorkDays.every(d => d != this.formatDate(start))) ||
                !this.pushWorkDays.length
              ) {
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                childDiv.style.background = '#ccc'
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              }
            }
          } else {
            if (
              new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-')).getDay() == 0 ||
              new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-')).getDay() == 6
            ) {
              let start = new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-'))
              if (
                (this.pushWorkDays.length && this.pushWorkDays.every(d => d != this.formatDate(start))) ||
                !this.pushWorkDays.length
              ) {
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                childDiv.style.background = '#ccc'
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              }
            }
          }
          if (this.workDays.some(item => item == '-1')) {
            if (item.lastChild.childNodes[0].innerText) {
              if (item.lastChild.childNodes[0].innerText == '休' || item.lastChild.childNodes[0].innerText == '假') {
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                if (this.workDays.some(item => item == '-1')) {
                  childDiv.style.background = '#ccc'
                } else {
                  childDiv.style.background = '#fff'
                }
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              } else if (item.lastChild.childNodes[0].innerText == '班') {
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                childDiv.style.background = '#fff'
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              }
            } else {
              this.workDay.forEach(day => {
                let date = day.date.split('-').join('')
                if (date == item.id && day.type != 'HOLIDAY') {
                  let div = document.createElement('div')
                  div.className = 'fc-daygrid-bg-harness'
                  div.style.left = '0px'
                  div.style.right = '0px'
                  let childDiv = document.createElement('div')
                  childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                  childDiv.style.background = '#fff'
                  div.appendChild(childDiv)
                  while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                    item.parentNode.parentNode
                      .querySelector('.fc-daygrid-day-bg')
                      .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                  }
                  item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
                }
              })
            }
            this.workDay.forEach(day => {
              let date = day.date.split('-').join('')
              if (date == item.id && day.type == 'WORK') {
                if (item.lastChild.childNodes[0].innerText == '假') {
                  item.lastChild.childNodes[0].innerText = ''
                }
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                childDiv.style.background = '#fff'
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              }
            })
            if (this.filterCheckList.indexOf('可安排数量') == -1) return
            this.schedule.forEach(s => {
              let date = new Date(this.insertStr(this.insertStr(s.key, 4, '-'), 7, '-'))
              if (
                s.key == item.id &&
                !this.holiday.some(h => {
                  return h.type == 'HOLIDAY' && h.date.replace('-', '').replace('-', '') == s.key
                })
              ) {
                if (this.workDays.length && this.workDays[0] != -1) {
                  /*                   let arr = []
                  this.workDays.forEach(w => {
                    if (w == 7) {
                      arr.push(0)
                    } else {
                      arr.push(w)
                    }
                  })
                  if (
                    arr.some(w => {
                      return w == date.getDay()
                    })
                  ) {
                    if (
                      !this.workDay.some(day => {
                        return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                      })
                    ) {
                      item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                    }
                  } else {
                    this.workDay.forEach(day => {
                      if (day.date.replace('-', '').replace('-', '') == s.key && day.type != 'HOLIDAY') {
                        item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                      }
                    })
                  } */
                  if (s.data.length) {
                    item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                  }
                } else {
                  /*                   if (
                    (date.getDay() != 0 && date.getDay() != 6) ||
                    this.workDay.some(day => {
                      return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                    }) ||
                    this.holiday.some(h => {
                      return h.type == 'WORK' && h.date.replace('-', '').replace('-', '') == s.key
                    })
                  ) {
                    if (
                      !this.workDay.some(day => {
                        return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                      })
                    ) {
                      item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                    }
                    if (s.data.length) {
                      item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                    }
                  } else {
                    this.workDay.forEach(day => {
                      if (day.date.replace('-', '').replace('-', '') == s.key && day.type != 'HOLIDAY') {
                        item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                      }
                    })
                  } */

                  //单独修改工作数量
                  if (s.data.length) {
                    item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                  }
                }
              }
            })
          } else {
            if (this.filterCheckList.indexOf('可安排数量') == -1) return
            this.schedule.forEach(s => {
              let date = new Date(this.insertStr(this.insertStr(s.key, 4, '-'), 7, '-'))
              if (s.key == item.id) {
                if (this.workDays.length && this.workDays[0] != -1) {
                  let arr = []
                  this.workDays.forEach(w => {
                    if (w == 7) {
                      arr.push(0)
                    } else {
                      arr.push(w)
                    }
                  })
                  if (
                    arr.some(w => {
                      return w == date.getDay()
                    })
                  ) {
                    if (
                      !this.workDay.some(day => {
                        return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                      })
                    ) {
                      item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                    }
                  } else {
                    this.workDay.forEach(day => {
                      if (day.date.replace('-', '').replace('-', '') == s.key && day.type != 'HOLIDAY') {
                        item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                      }
                    })
                  }
                }
              }
            })
          }
        })
      }, 1500)
    },
    addAdminDayTitle: simpleDebounce(async function() {
      if (this.userIds.length > 1) {
        this.calendarOptions = this.reCalendarView()
      }
      setTimeout(() => {
        Array.from(document.getElementsByClassName('day-title'))?.forEach(item => {
          let span = document.createElement('span')
          span.style.color = '#fbac00'
          span.style.marginRight = '2px'
          span.innerText = ''
          let title = document.createElement('span')
          title.innerText =
            item.lastChild.innerText
              .replace('假', '')
              .replace('班', '')
              .replace('休', '') || ''
          if (
            this.holiday.some(day => {
              return day.date == this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-') && day.type != 'WORK'
            })
          ) {
            if (item.lastChild.childNodes[0].innerText.length > 1) {
              item.lastChild.innerHTML = ''
              span.innerText = '假'
              item.lastChild.appendChild(span)
              item.lastChild.appendChild(title)
            }
          }
          this.holiday?.forEach(day => {
            if (day.type == 'WORK') {
              if (
                day.date == this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-') &&
                item.lastChild.childNodes[0].innerText.length > 1
              ) {
                item.lastChild.innerHTML = ''
                span.innerText = '班'
                span.style.color = 'red'
                item.lastChild.appendChild(span)
                item.lastChild.appendChild(title)
              }
            }
          })
          this.workDay?.forEach(day => {
            let date = day.date.split('-').join('')
            if (date == item.id && day.type == 'HOLIDAY') {
              if (item.lastChild.childNodes[0].innerText == '假') {
                item.lastChild.childNodes[0].innerText = '休'
              } else {
                item.lastChild.innerHTML = ''
                span.innerText = '休'
                span.style.color = 'red'
                item.lastChild.appendChild(span)
                item.lastChild.appendChild(title)
              }
            } else if (date == item.id && !day.type) {
              if (item.lastChild.childNodes[0].innerText == '休') {
                item.lastChild.childNodes[0].innerText = ''
              }
            } else if (date == item.id && day.type == 'WORK') {
              if (item.lastChild.childNodes[0].innerText == '假' || item.lastChild.childNodes[0].innerText == '休') {
                item.lastChild.childNodes[0].innerText = '班'
                item.lastChild.childNodes[0].style.color = '#64b25a'
              } else if (item.lastChild.childNodes.length == 1 || !item.lastChild.childNodes[0].innerText) {
                item.lastChild.childNodes[0].innerText = '班'
                item.lastChild.childNodes[0].style.color = '#64b25a'
              }
            }
          })
        })
        this.calendarOptions = this.reCalendarView()
      }, 1500)
      setTimeout(() => {
        Array.from(document.getElementsByClassName('day-title'))?.forEach(item => {
          if (this.workDays.length && this.workDays[0] != -1) {
            let arr = []
            this.workDays?.forEach(w => {
              if (w == 7) {
                arr.push(0)
              } else {
                arr.push(w)
              }
            })
            if (
              arr.every(w => {
                return w != new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-')).getDay()
              })
            ) {
              let div = document.createElement('div')
              div.className = 'fc-daygrid-bg-harness'
              div.style.left = '0px'
              div.style.right = '0px'
              let childDiv = document.createElement('div')
              childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
              childDiv.style.background = '#ccc'
              div.appendChild(childDiv)
              while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                item.parentNode.parentNode
                  .querySelector('.fc-daygrid-day-bg')
                  .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
              }
              item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
            }
          } else {
            if (
              new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-')).getDay() == 0 ||
              new Date(this.insertStr(this.insertStr(item.id, 4, '-'), 7, '-')).getDay() == 6
            ) {
              let div = document.createElement('div')
              div.className = 'fc-daygrid-bg-harness'
              div.style.left = '0px'
              div.style.right = '0px'
              let childDiv = document.createElement('div')
              childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
              childDiv.style.background = '#ccc'
              div.appendChild(childDiv)
              while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                item.parentNode.parentNode
                  .querySelector('.fc-daygrid-day-bg')
                  .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
              }
              item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
            }
          }
          if (this.workDays.some(item => item == '-1')) {
            if (item.lastChild.childNodes[0].innerText) {
              if (item.lastChild.childNodes[0].innerText == '休' || item.lastChild.childNodes[0].innerText == '假') {
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                if (this.workDays.some(item => item == '-1')) {
                  childDiv.style.background = '#ccc'
                } else {
                  childDiv.style.background = '#fff'
                }
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              } else if (item.lastChild.childNodes[0].innerText == '班') {
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                childDiv.style.background = '#fff'
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              }
            } else {
              this.workDay.forEach(day => {
                let date = day.date.split('-').join('')
                if (date == item.id && day.type != 'HOLIDAY') {
                  let div = document.createElement('div')
                  div.className = 'fc-daygrid-bg-harness'
                  div.style.left = '0px'
                  div.style.right = '0px'
                  let childDiv = document.createElement('div')
                  childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                  childDiv.style.background = '#fff'
                  div.appendChild(childDiv)
                  while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                    item.parentNode.parentNode
                      .querySelector('.fc-daygrid-day-bg')
                      .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                  }
                  item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
                }
              })
            }
            this.workDay?.forEach(day => {
              let date = day.date.split('-').join('')
              if (date == item.id && day.type == 'WORK') {
                if (item.lastChild.childNodes[0].innerText == '假') {
                  item.lastChild.childNodes[0].innerText = ''
                }
                let div = document.createElement('div')
                div.className = 'fc-daygrid-bg-harness'
                div.style.left = '0px'
                div.style.right = '0px'
                let childDiv = document.createElement('div')
                childDiv.className = 'fc-bg-event fc-event fc-event-start fc-event-end fc-event-future'
                childDiv.style.background = '#fff'
                div.appendChild(childDiv)
                while (item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild) {
                  item.parentNode.parentNode
                    .querySelector('.fc-daygrid-day-bg')
                    .removeChild(item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').firstChild)
                }
                item.parentNode.parentNode.querySelector('.fc-daygrid-day-bg').appendChild(div)
              }
            })
            this.schedule.forEach(s => {
              let date = new Date(this.insertStr(this.insertStr(s.key, 4, '-'), 7, '-'))
              if (
                s.key == item.id &&
                !this.holiday.some(h => {
                  return h.type == 'HOLIDAY' && h.date.replace('-', '').replace('-', '') == s.key
                })
              ) {
                if (this.workDays.length && this.workDays[0] != -1) {
                  let arr = []
                  this.workDays.forEach(w => {
                    if (w == 7) {
                      arr.push(0)
                    } else {
                      arr.push(w)
                    }
                  })
                  if (
                    arr.some(w => {
                      return w == date.getDay()
                    })
                  ) {
                    if (
                      !this.workDay.some(day => {
                        return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                      })
                    ) {
                      item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                    }
                  } else {
                    this.workDay.forEach(day => {
                      if (day.date.replace('-', '').replace('-', '') == s.key && day.type != 'HOLIDAY') {
                        item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                      }
                    })
                  }
                } else {
                  if (
                    (date.getDay() != 0 && date.getDay() != 6) ||
                    this.workDay.some(day => {
                      return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                    }) ||
                    this.holiday.some(h => {
                      return h.type == 'WORK' && h.date.replace('-', '').replace('-', '') == s.key
                    })
                  ) {
                    if (
                      !this.workDay.some(day => {
                        return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                      })
                    ) {
                      item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                    }
                  } else {
                    this.workDay.forEach(day => {
                      if (day.date.replace('-', '').replace('-', '') == s.key && day.type != 'HOLIDAY') {
                        item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                      }
                    })
                  }
                }
              }
            })
          } else {
            this.schedule.forEach(s => {
              let date = new Date(this.insertStr(this.insertStr(s.key, 4, '-'), 7, '-'))
              if (s.key == item.id) {
                if (this.workDays.length && this.workDays[0] != -1) {
                  let arr = []
                  this.workDays.forEach(w => {
                    if (w == 7) {
                      arr.push(0)
                    } else {
                      arr.push(w)
                    }
                  })
                  if (
                    arr.some(w => {
                      return w == date.getDay()
                    })
                  ) {
                    if (
                      !this.workDay.some(day => {
                        return day.type == 'HOLIDAY' && day.date.replace('-', '').replace('-', '') == s.key
                      })
                    ) {
                      item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                    }
                  } else {
                    this.workDay.forEach(day => {
                      if (day.date.replace('-', '').replace('-', '') == s.key && day.type != 'HOLIDAY') {
                        item.childNodes[1].appendChild(this.$refs[s.key + '-count'][0].$el)
                      }
                    })
                  }
                }
              }
            })
          }
        })
      }, 1500)
    }, 1000),
    async getHoliday() {
      if (this.filterCheckList.indexOf('节假日') == -1) {
        this.holiday = []
        return
      }
      const res = await getAction('/holiday', {
        startDate: this.queryParam.startDate,
        endDate: this.queryParam.endDate
      })
      this.holiday = res.data
    },
    throttle(method, delay, duration) {
      var that = this
      var timer = this.timer
      var begin = new Date().getTime()
      return function() {
        var current = new Date().getTime()
        clearTimeout(timer)
        if (current - begin >= duration) {
          method()
          begin = current
        } else {
          that.timer = setTimeout(function() {
            method()
          }, delay)
        }
      }
    },
    dayClick(e) {
      this.calendarApi.today()
      this.viewType = 'dayGridDay'
      this.calendarApi.changeView('dayGridDay')
      document.getElementsByClassName('fc-view-harness')[0].style.display = 'none'
      document.getElementsByClassName('day-show')[0].style.opacity = '1'
      /*       document.getElementsByClassName('fc-dayGridDay-button')[0].style.boxShadow = '0 0 0 0.2rem rgb(76 91 106 / 50%)'
      document.getElementsByClassName('fc-dayGridWeek-button')[0].style.boxShadow = 'none'
      document.getElementsByClassName('fc-dayGridMonth-button')[0].style.boxShadow = 'none' */
      this.nextClick()
      this.prevClick()
      let info = {
        start: this.calendarApi.currentData.currentDate,
        end: this.calendarApi.currentData.currentDate
      }
      this.fetchCalendarDayData(info)
      this.$emit('changeViewTypeToWeek', 'dayGridDay')
    },
    weekClick() {
      this.openAll = false
      this.openList.forEach(item => {
        item.isOpen = false
      })
      this.viewType = 'dayGridWeek'
      this.calendarApi.changeView('dayGridWeek')
      document.getElementsByClassName('fc-view-harness')[0].style.display = 'block'
      document.getElementsByClassName('day-show')[0].style.opacity = '0'
      this.calendarApi.today()
      this.reloadData()
      this.addDayTitle()
      this.$emit('getTitle')
      this.$emit('changeViewTypeToWeek', 'dayGridWeek')
    },
    monthClick(e) {
      this.viewType = 'dayGridMonth'
      this.calendarApi.changeView('dayGridMonth')
      document.getElementsByClassName('fc-view-harness')[0].style.display = 'block'
      document.getElementsByClassName('day-show')[0].style.opacity = '0'
      this.nextClick()
      this.prevClick()
      this.addDayTitle()
      this.$emit('getTitle')
      this.$emit('changeViewTypeToWeek', 'dayGridMonth')
    },
    fetchCalendarDayData(info) {
      // 是手动切换上下时才回写
      this.throttle(
        () => {
          if (this.isPrevOrNext && info.start) {
            // 回写顶部搜索栏中的日期
            this.$emit('setSearchDate', {
              startDate: moment(info.start),
              endDate: moment(info.end)
            })
            this.isPrevOrNext = false
          }
          this.$emit('setSkipLoading', true)
          let startDate = `${info.start.getFullYear()}-${
            String(info.start.getMonth() + 1).length == 1
              ? '0' + String(info.start.getMonth() + 1)
              : String(info.start.getMonth() + 1)
          }-${String(info.start.getDate()).length == 1 ? '0' + info.start.getDate() : info.start.getDate()}`
          let endDate = `${info.start.getFullYear()}-${
            String(info.start.getMonth() + 1).length == 1
              ? '0' + String(info.start.getMonth() + 1)
              : String(info.start.getMonth() + 1)
          }-${String(info.start.getDate()).length == 1 ? '0' + info.start.getDate() : info.start.getDate()}`
          let url = ''
          if (!this.isMyTask) {
            /* url = `/production/list_calendar_tasks?id=${this.parent.id}&startDate=${startDate}&endDate=${endDate}` */
            url = `/production/list_calendar_tasks?id=${this.parent.id}`
          } else {
            url = `/tasks/my?current=1&size=-1`
            // url = '/plus/task/queryTaskList'
            // this.queryParam.size = -1
          }
          let restDays = []
          /* if (!this.queryParam || !this.queryParam.userIds || this.queryParam.userIds == '') {
            let start = info.start
            let end = info.end
            let workDays = this.userInfo.userFile.workDays
            if (!(workDays?.length == 1 && workDays[0] == -1)) {
              while (start.getTime() <= end.getTime()) {
                if (workDays.indexOf(start.getDay()) < 0) {
                  restDays.push({
                    start: moment(new Date(start.getTime())).format('YYYY-MM-DD'),
                    color: '#ccc',
                    display: 'background'
                  })
                }
                start = new Date(start.getTime() + 86400000)
              }
            }
          } else if (this.queryParam && this.queryParam.userIds) {
            let userIds = this.queryParam.userIds.split(',')
            getAction('/sys/user/get_user_file', { userId: this.queryParam.userIds }).then(res => {
              if (res.success) {
                let start = info.start
                let end = info.end
                let workDays = this.userInfo.userFile.workDays
                if (!(workDays?.length == 1 && workDays[0] == -1)) {
                  while (start.getTime() <= end.getTime()) {
                    if (workDays.indexOf(start.getDay()) < 0) {
                      restDays.push({
                        start: moment(new Date(start.getTime())).format('YYYY-MM-DD'),
                        color: '#ccc',
                        display: 'background'
                      })
                    }
                    start = new Date(start.getTime() + 86400000)
                  }
                }
              }
            })
          } */
          let infoTask = {
            start: this.calendarApi.currentData.currentDate,
            end: new Date(this.calendarApi.currentData.currentDate - 0 + 24 * 60 * 60 * 1000)
          }
          let startDateTask = `${infoTask.start.getFullYear()}-${
            String(infoTask.start.getMonth() + 1).length == 1
              ? '0' + String(infoTask.start.getMonth() + 1)
              : String(infoTask.start.getMonth() + 1)
          }-${String(infoTask.start.getDate()).length == 1 ? '0' + infoTask.start.getDate() : infoTask.start.getDate()}`
          let endDateTask = `${infoTask.start.getFullYear()}-${
            String(infoTask.end.getMonth() + 1).length == 1
              ? '0' + String(infoTask.end.getMonth() + 1)
              : String(infoTask.end.getMonth() + 1)
          }-${String(infoTask.end.getDate()).length == 1 ? '0' + infoTask.end.getDate() : infoTask.end.getDate()}`
          let queryParam = { ...this.queryParam, startDate: startDateTask, endDate: endDateTask }
          queryParam = new URLSearchParams(handleArrayParams({ ...queryParam }))
          getAction(url + '&' + queryParam).then(({ success, data }) => {
            if (success) {
              if (this.isMyTask) {
                data = data.records
              }
              data = data.filter(item => {
                return this.filterCheckList.indexOf(item.taskStatus) > -1
              })
              let isEditable = false
              let list = data.map((one, index) => {
                return {
                  id: one.id,
                  title:
                    one.productionName +
                    '-' +
                    parseFloat((one.chapterSequenceNo - 0).toFixed(1)) +
                    '-' +
                    one.stageName +
                    '-' +
                    one.user.userName,
                  start: one.deadline,
                  editable: isEditable,
                  allDay: true,
                  textColor:
                    one.taskStatus == '已完成'
                      ? 'green'
                      : one.taskStatus == '等待'
                      ? 'grey'
                      : one.taskStatus == '驳回'
                      ? 'orange'
                      : 'red',
                  color: stageBackgroundColor[one.stageName] || stageBackgroundColor[one.stageId] || 'white',
                  record: { ...one, idx: index }
                  /* isCountent: true */
                }
              })
              /* list = list.concat(restDays) */
              getAction('/tasks/get_custom_event', {
                startDate: startDate,
                endDate: endDate
              }).then(({ success, data }) => {
                if (success) {
                  let list1 = data.map(one => {
                    return {
                      id: 'custom_event_' + one.id,
                      title: one.eventData,
                      checked: one.checked,
                      start: one.startDate,
                      end: one.endDate,
                      textColor: 'gray',
                      color: stageBackgroundColor['customEvent'],
                      record: { type: 'customEvent' }
                    }
                  })
                  list = list.concat(list1)
                  this.dayList = []
                  this.dayList = list.filter(item => {
                    return item.start && item.start.split(' ')[0] == startDate
                  })
                  this.eventList = []
                  this.eventList = list.filter(item => {
                    return item.start && item.start == startDate
                  })
                  this.overNum = 0
                  this.waitNum = 0
                  this.dayList.forEach(item => {
                    if (item.record) {
                      if (item.record.taskStatus) {
                        if (item.record.taskStatus == '已完成') {
                          this.overNum++
                        } else {
                          this.waitNum++
                        }
                      }
                    }
                  })
                  this.allList = this.dayList
                  this.$emit('setSkipLoading', false)
                }
              })
            }
          })
        },
        500,
        700
      )()
    },
    //选中日期
    selectDaysChange(dateInfo) {
      let selectDays = this.getdate(dateInfo.start, dateInfo.end).map(item => {
        return { time: item.time.split(' ')[0], day: item.day }
      })
      let isHoliDay = false
      let isWorkDay = false
      let isSleep = false
      let isAllDis = false
      selectDays.forEach(day => {
        if (day.day === 0) day.day = 7
        if (this.workDays.some(item => item == '-1') && day.day > 0 && day.day < 6) {
          isWorkDay = true
        } else {
          if (this.workDays.some(item => item == day.day)) {
            isWorkDay = true
          } else {
            isHoliDay = true
          }
        }
        let schedule = this.workDay.find(item => item.date == day.time)
        if (schedule?.type != 'HOLIDAY') {
          isSleep = true
        }
        let hday = this.holiday.find(item => item.date == day.time)
        this.holiday.forEach(item => {
          if (item.type == 'WORK' && item.date == day.time) {
            if (this.workDays.length == 1 && this.workDays[0] == -1) {
              isWorkDay = true
              isHoliDay = false
            }
          } else if (item.type != 'WORK' && item.date == day.time) {
            if (this.workDays.length == 1 && this.workDays[0] == -1) {
              isWorkDay = false
              isHoliDay = true
            }
          }
        })
        if (schedule?.type == 'HOLIDAY') {
          isHoliDay = true
        }
        if (schedule?.type == 'WORK') {
          isHoliDay = false
          isWorkDay = true
          if (hday?.type == 'HOLIDAY') {
            isHoliDay = false
          }
        }
      })
      Array.from(document.getElementsByClassName('fc-daygrid-day')).forEach(i => {
        if (
          selectDays.some(day => {
            return i.dataset.date == day.time && i.childNodes[0].childNodes[1].innerText
          })
        ) {
          isAllDis = true
        }
      })
      this.$emit('getDate', { dateInfo, isWorkDay, isHoliDay, isSleep, isAllDis })
    },
    async dropSelectChange(e, data) {
      if (e.target.checked) {
        let moveDiv = this.$refs[data.id]
        let parent = moveDiv.parentNode.parentNode.parentNode.parentNode
        let addDiv = parent.getElementsByClassName('drop-list')[0] /* .parentNode.parentNode.parentNode */
        moveDiv.parentNode.id = data.id + '-parent'
        moveDiv.style.color = data.textColor
        if (!addDiv.childNodes.length) {
          addDiv.style.boxShadow = '0 2px 8px rgb(0 0 0 / 15%)'
        }
        /* this.taskIds.push(data.id) */
        addDiv.appendChild(moveDiv)
        if (this.taskIds.length == 1) {
          /*           this.tasks.forEach(item => {
            if (item.stageId != data.stageId && item.taskStatus != '已完成') {
              this.$refs[item.id + '-select']._props.disabled = true
            }
          }) */
        }
      } else {
        let moveDiv = this.$refs[data.id]
        /* console.log(this.taskIds.indexOf(data.id),456) */
        if (document.getElementById(data.id + '-parent'))
          document.getElementById(data.id + '-parent').appendChild(moveDiv)
      }
    },
    selectChange(e, data) {
      /*       if (e.target.checked) {
        if (this.selectKeys.length > 0) {
          if (this.selectKeys[0].taskStatus != data.taskStatus) {
            this.$message.error('不同状态无法进行批量操作！')
            this.$emit('changeFinishDis', true)
            return
          }
          if (this.selectKeys[0].stageName != data.stageName) {
            this.$message.error('不同阶段无法进行批量操作！')
            this.$emit('changeFinishDis', true)
            return
          }
        }
      } */
      this.$emit('getKey', { checked: e.target.checked, ...data })
      this.$emit('changeFinishDis', false)
      /* this.calendarApi.refetchEvents() */
    },
    selectDayChange(e, data) {
      /*       if (e.target.checked) {
        if (this.selectKeys.findIndex(item => item.id == data.id) == -1) {
          this.selectKeys.push(data)
        }
      } else {
        if (this.selectKeys.findIndex(item => item.id == data.id) > -1) {
          this.selectKeys.splice(
            this.selectKeys.findIndex(item => item.id == data.id),
            1
          )
        }
      } */
      /* this.$emit('getKey', { checked: e.target.checked, ...data }) */
      this.$emit('changeFinishDis', false)
    },
    getBack(record) {
      let color = 'background-color:#f8f8f8'
      if (record) {
        if (record.taskStatus == '驳回') {
          color =
            record.deadline && record.deadline.slice(11, 16) < '18'
              ? 'background-color:rgb(255 225 225);border: 1px solid #ffa39e;color:orange;'
              : record.priority == 'HIGH'
              ? 'background-color:rgb(255 225 225);border: 1px solid #ffa39e;color:orange;'
              : record.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7;color:orange;'
              : record.hasBeenReject
              ? 'background-color:rgb(255 229 199);color:orange;'
              : 'background-color:#f8f8f8;color:orange;'
        } else if (record.taskStatus == '已完成') {
          color =
            record.deadline && record.deadline.slice(11, 16) < '18' && record.finishStatus != 'DELAY'
              ? 'background-color:#f8f8f8;color:green;'
              : record.priority == 'HIGH' && record.finishStatus != 'DELAY'
              ? 'background-color:#f8f8f8;color:green;'
              : record.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7;color:green;'
              : 'background-color:#f8f8f8;color:green;'
        } else if (record.taskStatus == '可开始') {
          color = record.hasBeenReject
            ? 'background-color:rgb(255 229 199);color:red;'
            : 'background-color:#f8f8f8;color:red;'
        }
        if (record.finishStatus == 'DELAY') {
          color = 'background-color:#fff9b7;'
        }
      }
      if (record.rejectTaskId && record.taskStatus != '驳回') {
        color = color + 'border: 1px solid orange;'
      }
      return color
    },
    handleLinkTranslateMytasks() {
      createAlink(this.translateMytasksDetialPath, '_self')
      // this.$router.path({ path: this.translateMytasksDetialPath })
    },
    handleLinkTasks(record) {
      if (record.stageName === '给稿') {
        this.$router.push({
          path: `${this.mytasksUploadPath}/${record.taskId}/${record.productionId}/${record.chapterId}`,
          query: {
            record: encodeURIComponent(JSON.stringify(record))
          }
        })
      } else {
        this.$router.push({
          path: `${this.translateEditorPath}/${record.taskId}/${record.productionId}/${record.chapterId}/${record.platformId}`
        })
      }
    },
    handleDateClick(dateClickInfo) {
      if (!this.isView) return
      this.addEventDate = dateClickInfo.dateStr
      this.addEventText = ''
      this.addEventDlgShow = true
    },
    handleEventClick(eventClickInfo) {
      if (eventClickInfo.event.extendedProps.record.type == 'customEvent') {
        return
      }
      if (this.isSelect || this.isDropSelect) return
      const {
        productionId,
        chapterId,
        platformId,
        productionName,
        chapterOrder,
        stageName
      } = eventClickInfo.event.extendedProps.record
      /*       this.$router.push({
        path: `/translate/mytasks/detail/${
          eventClickInfo.event.id
        }/${productionId}/${chapterId}?record=${encodeURIComponent(
          JSON.stringify({
            platformId
          })
        )}&isMyTask=${this.isMyTask}&name=${productionName + '-' + chapterOrder + '-' + stageName}`
      }) */
      const newUrl = `/translate/mytasks/detail/${
        eventClickInfo.event.id
      }/${productionId}/${chapterId}?record=${encodeURIComponent(
        JSON.stringify({
          platformId
        })
      )}&isMyTask=${this.isMyTask}&name=${productionName + '-' + chapterOrder + '-' + stageName}`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
      let url = '/system/mytasks'
      setTimeout(() => {
        this.$bus.$emit('getSkipUrl', url)
      }, 0)
    },
    handleEventDayClick(eventClickInfo) {
      if (eventClickInfo.record.type == 'customEvent') {
        return
      }
      if (this.isSelect || this.isDropSelect) return
      const { productionId, chapterId, chapterOrder, platformId, stageName, productionName } = eventClickInfo.record
      this.$router.push({
        path: `/translate/mytasks/detail/${eventClickInfo.id}/${productionId}/${chapterId}?record=${encodeURIComponent(
          JSON.stringify({
            platformId
          })
        )}&isMyTask=${this.isMyTask}&name=${productionName + '-' + chapterOrder + '-' + stageName}`
      })
      let url = '/system/mytasks'
      setTimeout(() => {
        this.$bus.$emit('getSkipUrl', url)
      }, 0)
    },
    handleMouseEnter(mouseEnterInfo) {
      // 提示：mouseEnterInfo.event.startStr 可以获取当前事件的开始事件
    },
    handleMouseLeave(mouseEnterInfo) {},
    async handleEventDrop(eventInfo) {
      let lastData = {}
      eventInfo.event.extendedProps.record.taskList?.forEach((item, index) => {
        if (index != 0) {
          if (item.taskId == eventInfo.event.extendedProps.record.taskId) {
            lastData = eventInfo.event.extendedProps.record.taskList[index - 1]
          }
        }
      })
      if (eventInfo.event.extendedProps.record.type == 'customEvent') {
        putAction(
          `/tasks/change_custom_event?id=${eventInfo.event.id.replace('custom_event_', '')}&days=${
            eventInfo.delta.days
          }`
        ).then()
        return
      }
      this.dropTimeEnd = this.dateChange(eventInfo.delta.days, this.dropTimeStart)
      if (this.taskIds.length) {
        console.log(
          this.allList.filter(item => {
            return this.taskIds.some(i => i == item.record.id)
          })
        )
        const rest = await postAction('/tasks/moveBatch', { taskIds: this.taskIds, targetDate: this.dropTimeEnd })
        if (!rest.success) {
          this.$message.error(rest.message)
          this.reloadData()
          return
        }
        try {
          this.scheduleTasks =
            rest.data.alertTasks?.map(item => {
              item.deadlineNew = item.deadline
              return item
            }) || []
          let taskArr = []
          if (this.scheduleTasks.length) {
            this.tasks.forEach(item => {
              this.taskIds.forEach(i => {
                if (i == item.id) {
                  taskArr.push({ ...item, isShow: true, deadlineNew: this.dropTimeEnd + ' ' + '23:59:59' })
                }
              })
            })
          }
          this.scheduleTasks = [...taskArr, ...this.scheduleTasks]
          if (this.scheduleTasks.length) {
            this.needScheduleTask = true
            this.$nextTick(() => {
              if (this.$refs.chapterTable) {
                this.scheduleTasks.forEach(item => {
                  if (this.$refs.chapterTable.tableData.every(i => i.teamId != item.chapterOrder)) {
                    this.$refs.chapterTable.tableData.push({
                      value: item.chapterOrder,
                      team: item.chapterOrder,
                      teamId: item.chapterOrder,
                      hasChild: true,
                      children: []
                    })
                  }
                })
                this.scheduleTasks.forEach(item => {
                  this.$refs.chapterTable.tableData.forEach(i => {
                    if (i.teamId == item.chapterOrder) {
                      item.teamId = item.id
                      i.children.push(item)
                    }
                  })
                })
                this.$refs.chapterTable.copyTableData = JSON.parse(JSON.stringify(this.$refs.chapterTable.tableData))
              }
            })
          } else {
            this.tasks.forEach(item => {
              this.taskIds.forEach(i => {
                if (i == item.id) {
                  taskArr.push({ ...item, isShow: true, deadlineNew: this.dropTimeEnd + ' ' + '23:59:59' })
                }
              })
            })
            const res = await postAction(
              '/tasks/updateTasks',
              taskArr.map(item => {
                delete item.rejectTask
                delete item.settlementDate
                return {
                  ...item,
                  deadline: this.dropTimeEnd + ' 00:00:00',
                  taskStatus: null
                }
              })
            )
            if (res.code == 200) {
              this.$message.success('修改成功')
              this.reloadData()
            } else {
              this.$message.error(res.msg || res.message)
            }
          }
        } catch (err) {
          console.error(err)
        }
        return
      }
      this.moveData = { taskId: eventInfo.event.id, days: eventInfo.delta.days }
      const res = await postAction(`/tasks/move`, {
        taskId: eventInfo.event.id,
        days: eventInfo.delta.days,
        isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
        isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
        isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量') // 是否考虑任务排满
      })
      if (!res.success) {
        this.$message.error(res.msg || res.message)
        this.reloadData()
        return
      }
      try {
        this.scheduleTasks =
          res.data.alertTasks?.map(item => {
            item.deadlineNew = item.deadline
            return item
          }) || []
        if (this.scheduleTasks.length) {
          this.scheduleTasks.unshift({
            ...eventInfo.event.extendedProps.record,
            deadlineNew:
              this.dateChange(eventInfo.delta.days, this.dropTimeStart) +
              ' ' +
              eventInfo.event.extendedProps.record.deadline.split(' ')[1],
            isShow: true
          })
        }
        if (this.scheduleTasks.length) {
          this.needScheduleTask = true
          this.$nextTick(() => {
            if (this.$refs.chapterTable) {
              this.scheduleTasks.forEach(item => {
                if (this.$refs.chapterTable.tableData.every(i => i.teamId != item.chapterOrder)) {
                  this.$refs.chapterTable.tableData.push({
                    value: item.chapterOrder,
                    team: item.chapterOrder,
                    teamId: item.chapterOrder,
                    hasChild: true,
                    children: []
                  })
                }
              })
              this.scheduleTasks.forEach(item => {
                this.$refs.chapterTable.tableData.forEach(i => {
                  if (i.teamId == item.chapterOrder) {
                    item.teamId = item.id
                    i.children.push(item)
                  }
                })
              })
              this.$refs.chapterTable.copyTableData = JSON.parse(JSON.stringify(this.$refs.chapterTable.tableData))
            }
          })
        } else {
          delete eventInfo.event.extendedProps.record.rejectTask
          delete eventInfo.event.extendedProps.record.settlementDate
          const res = await postAction('/tasks/updateTasks', [
            {
              ...eventInfo.event.extendedProps.record,
              deadline: this.dropTimeEnd + ' 00:00:00',
              taskStatus: null
            }
          ])
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.reloadData()
          } else {
            this.$message.error(res.msg || res.message)
          }
        }
      } catch (err) {
        console.error(err)
      }
    },
    goToTerm(taskDetail, type) {
      window.open(
        `/production/manage/detail/${taskDetail.productionId}?&name=${taskDetail.productName}&tab=6&currentType=${type}&changeTitle=true`
      )
    },
    handleSelectOn() {
      this.isSelect = !this.isSelect
      //批量修改状态禁止拖拽
      this.select_list = []
      this.taskIds = []
      this.calendarOptions.eventStartEditable = !this.calendarOptions.eventStartEditable
      /* this.calendarApi.refetchEvents() */
    },
    handleSelectOnTwo() {
      this.isSelect = false
      //批量修改状态禁止拖拽
      this.select_list = []
      this.taskIds = []
      this.calendarOptions.eventStartEditable = !this.calendarOptions.eventStartEditable
      /* this.calendarApi.refetchEvents() */
    },
    handleDropSelectOn() {
      this.isDropSelect = !this.isDropSelect
      this.openAll = false
      this.calendarApi.refetchEvents()
    },
    handleSelectOff() {},
    todayClick(mouseEvent, htmlElement) {
      this.isPrevOrNext = true
      this.calendarApi.today() // 将日历移动到当前日期。
      this.calendarApi.getDate() // 返回日期的日历的当前日期
      if (this.viewType == 'dayGridDay') {
        let info = {
          start: this.calendarApi.currentData.currentDate,
          end: new Date(this.calendarApi.currentData.currentDate - 0 + 24 * 60 * 60 * 1000)
        }
        this.isPrevOrNext = false
        this.fetchCalendarDayData(info)
      }
    },
    nextClick(mouseEvent, htmlElement) {
      this.isPrevOrNext = true
      this.calendarApi.next() // 将日历向前移动一步（例如，一个月或一周）。
      if (this.viewType == 'dayGridDay') {
        let info = {
          start: this.calendarApi.currentData.currentDate,
          end: new Date(this.calendarApi.currentData.currentDate - 0 + 24 * 60 * 60 * 1000)
        }
        this.isPrevOrNext = false
        this.fetchCalendarDayData(info)
      }
      if (this.isMyTask) {
        /*         if (!this.isAdmin) {
          this.addDayTitle()
          this.$emit('getTitle')
        } else {
          this.addAdminDayTitle()
        } */
        this.addDayTitle()
        this.$emit('getTitle')
      }
      // 如果dayGridMonth查看日历，则将日历向前移动一个月。
      // 如果日历位于dayGridWeek或中timeGridWeek，则将日历向前移动一周。
      // 如果日历位于dayGridDay或中timeGridDay，则将日历向前移动一天。
    },
    prevClick(mouseEvent, htmlElement) {
      this.isPrevOrNext = true
      this.calendarApi.prev()
      if (this.viewType == 'dayGridDay') {
        let info = {
          start: this.calendarApi.currentData.currentDate,
          end: new Date(this.calendarApi.currentData.currentDate - 0 + 24 * 60 * 60 * 1000)
        }
        this.isPrevOrNext = false
        this.fetchCalendarDayData(info)
      } // 将日历后退一步（例如，一个月或一周）。
      // 如果dayGridMonth查看日历，则将日历后移一个月。
      // 如果日历位于dayGridWeek或中timeGridWeek，则将日历后移一周。
      // 如果日历位于dayGridDay或中timeGridDay，则将日历移回一天。
      if (this.isMyTask) {
        /*         if (!this.isAdmin) {
          this.addDayTitle()
          this.$emit('getTitle')
        } else {
          this.addAdminDayTitle()
        } */
        this.addDayTitle()
        this.$emit('getTitle')
      }
    },
    reloadData() {
      if (this.viewType == 'dayGridDay') {
        let info = {
          start: this.calendarApi.currentData.currentDate,
          end: this.calendarApi.currentData.currentDate
        }
        this.fetchCalendarDayData(info)
        return
      }
      this.calendarOptions = this.reCalendarView()
      if (this.calendarApi) this.calendarApi.refetchEvents()
      if (this.isDrag) {
        document.body.addEventListener('mousemove', this.handleMouseMove)
        document.body.addEventListener('mouseup', this.handleMouseUp)
        document.getElementsByClassName('mask')[0].style.width = '0'
        document.getElementsByClassName('mask')[0].style.height = '0'
        this.$nextTick(() => {
          this.isDrag = false
        })
      }
      this.taskIds = []
      if (!this.isAdmin) this.$emit('getTitle')
      setTimeout(() => {
        if (this.isDesktop()) {
          let div = document.getElementsByClassName('calendar-button-list')[0]
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].style.display = 'flex'
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].appendChild(div)
          let divLeft = document.getElementsByClassName('calendar-button-list-left')[0]
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].style.display = 'flex'
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].appendChild(divLeft)
          let workLeft = document.getElementsByClassName('work-left')[0]
          workLeft && document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].appendChild(workLeft)
          let workRight = document.getElementsByClassName('work-right')[0]
          workRight && document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].appendChild(workRight)
          /*    展开   if (this.viewType == 'dayGridMonth') {
        Array.from(document.getElementsByClassName('fc-scrollgrid-sync-table')[0].getElementsByTagName('tr')).forEach(
          (item, index) => {
            if (index == 0) {
              document.getElementsByClassName('fc-toolbar-title')[0].appendChild(this.$refs['openBtnAll'])
            }
            item.childNodes[0].appendChild(this.$refs['openBtn-' + index][0])
          }
        )
      } */
          if (this.isMyTask) {
            /*             if (!this.isAdmin) {
              this.addDayTitle()
            } else {
              this.addAdminDayTitle()
            } */
            this.addDayTitle()
            this.getWorkNum()
          }
        }
      }, 500)
    },
    loadData() {
      this.calendarOptions = this.reCalendarView()
      if (this.calendarApi) this.calendarApi.refetchEvents()
      if (this.isDrag) {
        document.body.addEventListener('mousemove', this.handleMouseMove)
        document.body.addEventListener('mouseup', this.handleMouseUp)
        document.getElementsByClassName('mask')[0].style.width = '0'
        document.getElementsByClassName('mask')[0].style.height = '0'
        this.$nextTick(() => {
          this.isDrag = false
        })
      }
      this.taskIds = []
      if (!this.isAdmin) this.$emit('getTitle')
      if (this.viewType == 'dayGridDay') {
        let info = {
          start: this.calendarApi.currentData.currentDate,
          end: this.calendarApi.currentData.currentDate
        }
        this.fetchCalendarDayData(info)
      }
      setTimeout(() => {
        if (this.isDesktop()) {
          let div = document.getElementsByClassName('calendar-button-list')[0]
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].style.display = 'flex'
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].appendChild(div)
          let divLeft = document.getElementsByClassName('calendar-button-list-left')[0]
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].style.display = 'flex'
          document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].appendChild(divLeft)
          let workLeft = document.getElementsByClassName('work-left')[0]
          workLeft && document.getElementsByClassName('fc-header-toolbar')[0].childNodes[0].appendChild(workLeft)
          let workRight = document.getElementsByClassName('work-right')[0]
          workRight && document.getElementsByClassName('fc-header-toolbar')[0].childNodes[2].appendChild(workRight)
          /*    展开   if (this.viewType == 'dayGridMonth') {
        Array.from(document.getElementsByClassName('fc-scrollgrid-sync-table')[0].getElementsByTagName('tr')).forEach(
          (item, index) => {
            if (index == 0) {
              document.getElementsByClassName('fc-toolbar-title')[0].appendChild(this.$refs['openBtnAll'])
            }
            item.childNodes[0].appendChild(this.$refs['openBtn-' + index][0])
          }
        )
      } */
        }
        if (this.isMyTask) {
          /*         if (!this.isAdmin) {
          this.addDayTitle()
        } else {
          this.addAdminDayTitle()
        } */
          this.addDayTitle()
        }
      }, 500)
    },
    reloadWorkDay(data) {
      if (this.filterCheckList.indexOf('排期') > -1) {
        this.workDay = data
      } else {
        this.workDay = []
      }
      if (this.isMyTask) {
        /*         if (!this.isAdmin) {
          this.addDayTitle()
        } else {
          this.addAdminDayTitle()
        } */
        this.addDayTitle()
      }
    },
    async getWorkDay() {
      const res = await getAction('/workSchedule', {
        startDate: this.queryParam.startDate,
        endDate: this.queryParam.endDate,
        userId: this.userIds[0] || this.userInfo.id
      })
      this.reloadWorkDay(res.data)
    },
    listCompareWithDate(list, key, asc = true) {
      return list?.sort((a, b) => new Date((asc ? a : b)[key]).getTime() - new Date((asc ? b : a)[key]).getTime())
    },
    listCompareWithChapterSequenceNo(list, key, asc = true) {
      return list?.sort((a, b) => (asc ? a : b)[key] - (asc ? b : a)[key])
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始，所以需要+1
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    fetchCalendarData(info, successCallback, failureCallback) {
      this.throttle(
        () => {
          // 每次加载手动清空已加载元素
          if (this.$refs.fullCalendar) {
            this.$refs.fullCalendar.getApi().removeAllEvents()
          }
          // 是手动切换上下时才回写
          if (this.isPrevOrNext && info.start) {
            // 回写顶部搜索栏中的日期
            this.$emit('setSearchDate', {
              startDate: moment(info.start),
              endDate: moment(info.end)
            })
            this.isPrevOrNext = false
          }
          this.$emit('setSkipLoading', true)
          let startDate = `${info.start.getFullYear()}-${info.start.getMonth() + 1}-${info.start.getDate()}`
          let endDate = `${info.end.getFullYear()}-${info.end.getMonth() + 1}-${info.end.getDate()}`
          let url = ''
          if (!this.isMyTask) {
            url = `/production/list_calendar_tasks?id=${this.parent.id}&startDate=${startDate}&endDate=${endDate}`
          } else {
            url = '/tasks/my?current=1&size=-1'
            // url = '/plus/task/queryTaskList'
            // this.queryParam.size = -1
          }
          let restDays = []
          if (!this.queryParam || !this.queryParam.userIds || this.queryParam.userIds == '') {
            let start = info.start
            let end = info.end
            let workDays = this.userInfo.userFile ? this.userInfo.userFile.workDays : []
            if (!(workDays?.length == 1 && workDays[0] == -1)) {
              while (start.getTime() <= end.getTime()) {
                if (workDays.indexOf(start.getDay()) < 0) {
                  if (
                    (this.pushWorkDays.length && this.pushWorkDays.every(d => d != this.formatDate(start))) ||
                    !this.pushWorkDays.length
                  ) {
                    restDays.push({
                      start: moment(new Date(start.getTime())).format('YYYY-MM-DD'),
                      color: '#ccc',
                      display: 'background'
                    })
                  }
                }
                start = new Date(start.getTime() + 86400000)
              }
            } else {
              while (start.getTime() <= end.getTime()) {
                if (start.getDay() == 6 || start.getDay() == 0) {
                  if (
                    (this.pushWorkDays.length && this.pushWorkDays.every(d => d != this.formatDate(start))) ||
                    !this.pushWorkDays.length
                  ) {
                    restDays.push({
                      start: moment(new Date(start.getTime())).format('YYYY-MM-DD'),
                      color: '#ccc',
                      display: 'background'
                    })
                  }
                }
                start = new Date(start.getTime() + 86400000)
              }
            }
          } else if (this.queryParam && this.queryParam.userIds) {
            let userIds = this.queryParam.userIds.split(',')
            getAction('/sys/user/get_user_file', { userId: this.queryParam.userIds }).then(res => {
              if (res.success) {
                let start = info.start
                let end = info.end
                let workDays = this.userInfo ? this.userInfo.userFile.workDays : []
                if (!(workDays?.length == 1 && workDays[0] == -1)) {
                  while (start.getTime() <= end.getTime()) {
                    if (workDays.indexOf(start.getDay()) < 0) {
                      if (
                        (this.pushWorkDays.length && this.pushWorkDays.every(d => d != this.formatDate(start))) ||
                        !this.pushWorkDays.length
                      ) {
                        restDays.push({
                          start: moment(new Date(start.getTime())).format('YYYY-MM-DD'),
                          color: '#ccc',
                          display: 'background'
                        })
                      }
                    }
                    start = new Date(start.getTime() + 86400000)
                  }
                } else {
                  while (start.getTime() <= end.getTime()) {
                    if (start.getDay() == 6 || start.getDay() == 0) {
                      if (
                        (this.pushWorkDays.length && this.pushWorkDays.every(d => d != this.formatDate(start))) ||
                        !this.pushWorkDays.length
                      ) {
                        restDays.push({
                          start: moment(new Date(start.getTime())).format('YYYY-MM-DD'),
                          color: '#ccc',
                          display: 'background'
                        })
                      }
                    }
                    start = new Date(start.getTime() + 86400000)
                  }
                }
              }
            })
          }
          /*           if (this.isMyTask && this.isAdmin) {
            this.debounceTitle()
          } */
          if (this.isMyTask) {
            if (this.filterCheckList.indexOf('可安排数量') > -1) {
              getAction('/workSchedule/userSchedule', {
                userId: this.userIds[0] || this.userInfo.id,
                startDate: this.queryParam.startDate,
                endDate: this.queryParam.endDate
              }).then(res => {
                if (res.data.schedule) {
                  this.schedule = []
                  for (let key in res.data.schedule) {
                    let item = {
                      key: key,
                      data: res.data.schedule[key] || 0,
                      isEdit: false
                    }
                    this.schedule.push(item)
                  }
                  console.log(this.schedule, res.data.schedule, 789)
                }
              })
            }
            this.$emit('setIsWorkEdit')
          }
          let isDropSelect = this.isDropSelect
          if (isDropSelect) {
            this.isDropSelect = false
          }
          this.getHoliday()
          this.getWorkDay()
          let data = new URLSearchParams(handleArrayParams({ ...this.queryParam })).toString()
          getAction(url + '&' + data).then(({ success, data }) => {
            if (success) {
              if (this.isMyTask) {
                data = data.records
              }
              data = data.filter(item => {
                return this.filterCheckList.indexOf(item.taskStatus) > -1
              })
              /* data = data.filter(one=>{return one.taskStatus == '已完成'}) */
              /* data = this.listCompareWithDate(data, 'deadline', true) */
              /* data = this.listCompareWithChapterSequenceNo(data, 'chapterSequenceNo', true) */
              this.tasks = data
              //获取任务数据
              this.allList = []
              /*               let arr1 = data.filter(item => item.taskStatus == '驳回')
              let arr2 = data.filter(item => item.taskStatus != '驳回')
              data = [...arr1, ...arr2] */
              this.allList = data.map((one, index) => {
                let isEditable = false
                let userInfo = Vue.ls.get(USER_INFO) || {}
                if (
                  userInfo.roles.some(item => {
                    return item.roleCode == 'admin' || item.roleCode == 'boss'
                  })
                ) {
                  isEditable = true
                } else if (
                  userInfo.roles.some(item => {
                    return item.roleCode == 'tongchou'
                  })
                ) {
                  if (
                    one.adminUsers?.some(item => {
                      return item.userId == userInfo.id
                    })
                  ) {
                    isEditable = true
                  }
                }
                if (one.taskStatus == '已完成') {
                  isEditable = false
                }
                this.isDropSelect = isDropSelect
                if (this.isDropSelect || this.isSelect) {
                  isEditable = false
                  this.select_list = []
                }
                if (!one.backGroundColor) {
                  one.backGroundColor = 'rgba(248,248,248)'
                }
                return {
                  id: one.id,
                  title:
                    one.productionName +
                    '-' +
                    parseFloat((one.chapterSequenceNo - 0).toFixed(1)) +
                    '-' +
                    one.stageName +
                    '-' +
                    one.user.userName,
                  start: one.deadline,
                  allDay: true,
                  editable: isEditable,
                  textColor: one.textColor,
                  backgroundColor: one.backGroundColor,
                  /* color: stageBackgroundColor[one.stageName] || stageBackgroundColor[one.stageId] || 'white', */
                  record: one,
                  eventOrder: index + 1
                  /* isCountent: true */
                }
              })
              this.filterList = []
              let key = 0
              /*               this.allList.forEach((item, index) => {
                if (index && this.allList[index - 1].start.split(' ')[0] == item.start.split(' ')[0]) {
                  key++
                } else {
                  key = 0
                }
                if (key < 5) {
                  this.filterList.push(item)
                }
              }) */
              let dateArr = data.map(one => {
                return one.deadline.split(' ')[0] + ' 00:00:00'
              })
              dateArr = [...new Set(dateArr)]
              dateArr.forEach(item => {
                /*  展开               this.filterList.push({
                  id: item,
                  title: 'dropDiv',
                  start: item,
                  eventOrder: data.length,
                  allDay: true,
                  color: 'white',
                  textColor: 'green',
                  record: {}
                }) */
                this.allList.push({
                  id: item,
                  title: 'dropDiv',
                  start: item,
                  eventOrder: 0,
                  allDay: true,
                  editable: true,
                  color: 'white',
                  textColor: 'gray',
                  record: {}
                })
              })
              if (this.isDesktop()) {
                const dom_box = document.querySelector('.box')
                this.box_screen_left = dom_box.getBoundingClientRect().left
                this.box_screen_top = dom_box.getBoundingClientRect().top
              }
              /*            展开   if (this.viewType == 'dayGridMonth') {
                this.openAll = false
                this.openList.forEach(item => {
                  item.isOpen = false
                })
                Array.from(
                  document.getElementsByClassName('fc-scrollgrid-sync-table')[0].getElementsByTagName('tr')
                ).forEach((item, index) => {
                  if (index == 0) {
                    document.getElementsByClassName('fc-toolbar-title')[0].appendChild(this.$refs['openBtnAll'])
                  }
                  let dateOfThisWeek = Array.from(item.getElementsByClassName('fc-daygrid-day')).map(
                    i => i.dataset.date
                  )
                  let tasksOfWeek = [[], [], [], [], [], [], []]
                  this.openList[index].isOpen = true
                  for (let task of this.allList) {
                    let date = task.start.split(' ')[0]
                    for (const i in dateOfThisWeek) {
                      if (date == dateOfThisWeek[i]) {
                        tasksOfWeek[i].push(task)
                        if (tasksOfWeek[i].length > 5) {
                          this.openList[index].isOpen = false
                          break
                        }
                      }
                    }
                  }
                  item.childNodes[0].appendChild(this.$refs['openBtn-' + index][0])
                })
              } */
              if (this.isView) {
                getAction('/tasks/get_custom_event', {
                  startDate: startDate,
                  endDate: endDate
                }).then(({ success, data }) => {
                  if (success) {
                    let list1 = data.map(one => {
                      return {
                        id: 'custom_event_' + one.id,
                        title: one.eventData,
                        start: one.startDate,
                        checked: one.checked,
                        end: one.endDate,
                        textColor: 'gray',
                        color: stageBackgroundColor['customEvent'],
                        record: { type: 'customEvent' }
                      }
                    })
                    let list = []
                    list = list.concat(list1)
                    this.$emit('setSkipLoading', false)
                    successCallback(list)
                  }
                })
              } else {
                /* list = list.filter((item, index) => {
                  return index < 150
                }) */
                this.$emit('setSkipLoading', false)
                this.pushList = this.allList
                console.log(this.allList, 123)
                successCallback(this.allList)

                /* 展开 if (this.viewType == 'dayGridWeek') {
                  dateArr.forEach(item => {
                    this.allList.push({
                      id: item,
                      title: 'dropDiv',
                      start: item,
                      eventOrder: data.length,
                      allDay: true,
                      color: 'white',
                      textColor: 'green',
                      record: {}
                    })
                  })
                  this.pushList = this.allList
                  successCallback(this.allList)
                } else {
                  this.pushList = this.filterList
                  successCallback(this.filterList)
                } */
              }
            }
          })
        },
        500,
        700
      )()
    },

    handleAddEventOk() {
      this.addEventDlgShow = false
      postAction('/tasks/add_custom_event', {
        startDate: this.addEventDate,
        endDate: this.addEventDate,
        eventData: this.addEventText,
        checked: 'NO'
      }).then(({ success, data }) => {
        if (success) {
          this.reloadData()
        }
      })
    },
    async handleEditEventOk() {
      const res = await putAction(
        `/tasks/change_custom_event?id=${this.editEvent.id.replace('custom_event_', '')}&eventData=${
          this.editEvent.text
        }&checked=${this.editEvent.checked}`
      )
      if (res.code == 200) {
        this.editEventDlgShow = false
        this.reloadData()
        this.$message.success('更新成功')
      }
    },
    handleAddEventCancel() {
      this.addEventDlgShow = false
      this.editEventDlgShow = false
      this.needScheduleTask = false
      this.taskRadioValue = '2'
      this.$emit('setSelectKeys')
      this.reloadData()
    },
    removeEvent(event) {
      deleteAction('/tasks/delete_custom_event', { id: event.id.replace('custom_event_', '') }).then(({ success }) => {
        if (success) {
          let e = this.calendarApi.getEventById(event.id)
          if (e) {
            e.remove()
          }
        }
      })
    },
    //触发日历双向绑定
    reCalendarView() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin], // 需要用哪个插件引入后放到这个数组里
        initialDate: new Date(), // 日历第一次加载时显示的初始日期。可以解析为Date的任何职包括ISO8601日期字符串，例如"2014-02-01"。
        /* initialView: this.isAccountCenter ? 'dayGridWeek' : 'dayGridMonth', */
        initialView: this.calendarType, // 日历加载时的初始视图，默认值为'dayGridMonth'，可以为任何可用视图的值，如例如'dayGridWeek'，'timeGridDay'，'listWeek'
        locale: 'zh-cn', // 设置日历的语言，中文为 “zh-cn”
        firstDay: '1', // 设置每周的第一天，默认值取决于当前语言环境，该值为代表星期几的数字，且值必须为整数，星期日=0
        weekNumberCalculation: 'ISO', // 指定"ISO"结果为ISO8601周数。指定"ISO"将firstDay的默认值更改为1（Monday）
        /* editable: true, */
        selectable: true,
        unselectAuto: false,
        height: 'auto',
        header: false,
        views: {
          //对应月视图
          dayGridMonth: {
            displayEventTime: false, //是否显示时间
            dayCellContent: item => {
              return {
                html: `<div class='day-title' id=${stamptime(item.date)}>
                  <div style="display: flex;"></div>
                <span><span>${item.dayNumberText}</span></span></div>`
              }
            }
          },
          dayGridWeek: {
            displayEventTime: false, //是否显示时间
            dayCellContent: item => {
              return {
                html: `<div class='day-title' id=${stamptime(item.date)}>
                  <div style="display: flex;"></div>
                <span><span>${item.dayNumberText}</span></span></div>`
              }
            }
          }
        }, //dayRender已废弃，使用views替换
        /*         buttonText: {
          // 文本将显示在headerToolbar / footerToolbar的按钮上。不支持HTML注入。所有特殊字符将被转义。
          today: '今天',
          month: '月',
          week: '周',
          day: '天'
        }, */
        headerToolbar: {
          // 在日历顶部定义按钮和标题。将headerToolbar选项设置为false不会显示任何标题工具栏。可以为对象提供属性start/center/end或left/center/right。这些属性包含带有逗号/空格分隔值的字符串。用逗号分隔的值将相邻显示。用空格分隔的值之间会显示一个很小的间隙。
          right: 'today',
          center: 'title',
          /* left: 'dayGridMonth,dayGridWeek,dayGridDay', */
          left: ''
        },
        eventTimeFormat: {
          // 在每个事件上显示的时间的格式
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        },
        events: this.fetchCalendarData,
        /* eventRender: (event,element)=>{this.calendarRender(event,element)}, */
        customButtons: {
          // 定义可在headerToolbar / footerToolbar中使用的自定义按钮
          today: {
            text: '今天', // 按钮的展示文本
            click: this.todayClick // 点击按钮触发的事件，这里要注意的是当按钮绑定了事件之后该按钮原本自带的事件将会失效
          },
          next: {
            click: this.nextClick
          },
          prev: {
            click: this.prevClick
          },
          dayGridDay: {
            text: '天',
            click: this.dayClick
          },
          dayGridWeek: {
            text: '周',
            click: this.weekClick
          },
          dayGridMonth: {
            text: '月',
            click: this.monthClick
          }
        },
        eventClick: this.handleEventClick, // 点击事件时，触发该回调
        eventMouseEnter: this.handleMouseEnter, // 鼠标悬停在事件上时，触发该回调
        eventMouseLeave: this.handleMouseLeave, // 鼠标移除时，触发该回调
        dateClick: this.handleDateClick, // 当用户单击日期或时间时,触发该回调，触发此回调，您必须加载interaction插件
        eventDrop: this.handleEventDrop,
        eventStartEditable: this.checkPermission(),
        select: this.selectDaysChange,
        eventOrder: 'eventOrder',
        eventDragStart: this.eventDragStart, //开始拖拽
        eventDragStop: this.eventDragStop //结束拖拽
        // eventContent: this.eventContentCallback,
      }
    }
  },
  watch: {
    showWidth(n, o) {
      this.$nextTick(() => {
        if (n > 1090 && o < n && !this.isLoad) {
          this.reloadData()
          this.isLoad = true
        } else {
          this.isLoad = false
        }
      })
    },
    calendarType() {
      if (this.calendarType == 'dayGridDay') {
        this.dayHeight = this.isMyTask ? 'calc(100vh - 305px)' : 'calc(100vh - 395px)'
      } else {
        this.dayHeight = '0'
      }
    }
  }
}
</script>

<style lang="less">
.remark-div {
  position: relative;

  .remark-item {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    transform: rotate(180deg);
    border: 3px solid red;
    border-top-color: transparent;
    border-right-color: transparent;
  }
}

.fc-toolbar-chunk:first-child {
  flex: 1;
}

.fc-toolbar-chunk:last-child {
  flex: 1;
  justify-content: end;
  align-items: center;

  .fc-today-button {
    order: 2;
  }
}

.fc .fc-view-harness::-webkit-scrollbar-button {
  height: 0;
}

.fc .fc-view-harness::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

.fc .fc-view-harness::-webkit-scrollbar-track {
  border-radius: 0;
}

.fc .fc-view-harness::-webkit-scrollbar-track-piece {
  border-radius: 0;
}

.fc .fc-view-harness::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}

.day-show::-webkit-scrollbar-button {
  height: 0;
}

.day-show::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

.day-show::-webkit-scrollbar-track {
  border-radius: 0;
}

.day-show::-webkit-scrollbar-track-piece {
  border-radius: 0;
}

.day-show::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}

.box {
  user-select: none;
  position: relative;

  .mask {
    position: absolute;
    background: #409eff;
    opacity: 0.4;
    z-index: 999;
    transition: none !important;
  }

  .ant-checkbox {
    float: left;
  }
}

.fc .fc-view-harness {
  overflow-y: auto;
  height: var(--theme-height);
}

.day-view-event {
  cursor: pointer;
}

.fc-daygrid-day {
  position: relative;
}

.open-btn {
  position: absolute;
  width: 703%;
  bottom: 0;
  height: 60px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #fff);
  display: flex;
  justify-content: center;
  z-index: 9;
  align-items: flex-end;
  cursor: pointer;

  span {
    position: relative;
    top: 5px;
  }
}

.open-btn-all {
  cursor: pointer;
  margin-left: 5px;
  font-size: 18px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.scheduleTasks-list {
  max-height: 500px;
  overflow-y: auto;
}

.reject-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  line-height: 1.5;
  width: 3px;
  background-color: orange;
}

.item {
  padding: 6px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move;
}

/*选中样式*/
.chosen {
  border: solid 1px #3089dc !important;
}

.title-width-div {
  opacity: 0;
  position: absolute;
}

.fc-button {
  z-index: 2;
}

.calendar-button-list {
  order: 3;
}

.calendar-button-list,
.calendar-button-list-left {
  display: flex;
}

/* .fc-icon-chevron-right:before {
  pointer-events: stroke !important;
}
.fc-icon-chevron-left:before {
  pointer-events: stroke !important;
} */
.no-title {
  .day-title {
    .title-span {
      opacity: 0;
    }
  }
}

.date-icon {
  color: #ffa500 !important;
  margin-right: 10px;
}

.delete-icon:hover {
  color: var(--theme-color);
}

.day-show {
  opacity: 0;
  position: absolute;
  width: 100%;
  overflow: auto;

  .day-show-num {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-right: 5px;
  }
}

.fc-daygrid-day-bg {
  /*  display: none; */
  .fc-daygrid-bg-harness {
    z-index: -1 !important;
  }

  .fc .fc-bg-event {
    z-index: -1 !important;
  }
}

.fc .fc-scrollgrid-section-sticky > * {
  z-index: 2 !important;
}

.fc .fc-scroller-harness {
  overflow: visible !important;
}

.ant-tooltip-arrow-content {
  background-color: #fff !important;
}

.calendar-tooltip {
  min-width: 100px !important;
}

.fullCalendar a {
  color: grey !important;
}

.admin-day-title {
  border-radius: 10px !important;
  font-weight: 700;
  height: 18px;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
  display: none;
}

/* .admin-day-title:hover {
  border:2px solid var(--theme-color) !important;
} */
.admin-day-title-wc {
  border-radius: 10px !important;
  font-weight: 700;
  height: 18px;
  line-height: 14px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  border: 2px solid #dd5652 !important;
  display: none;
}

.admin-day-title-wc:hover {
  border: 2px solid #dd5652 !important;
}

.admin-day-title-tg {
  border-radius: 10px !important;
  font-weight: 700;
  height: 18px;
  line-height: 14px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  border: 2px solid #f7a000 !important;
  display: none;
}

.admin-day-title-tg:hover {
  border: 2px solid #f7a000 !important;
}

.day-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  color: rgb(61, 61, 61);
  margin-bottom: 5px;

  .title-span {
    color: #fff;
    text-align: center;
    min-width: 18px;
    padding-right: 5px;
    padding-left: 4px;
    height: 18px;
    display: block;
    background-color: var(--theme-color);
    border-radius: 9px;
    line-height: 18px;
    position: relative;
  }

  .title-input {
    width: 25px;
    color: #fff;
    text-align: center;
    padding-right: 5px;
    padding-left: 4px;
    height: 18px;
    display: block;
    background-color: var(--theme-color);
    border-radius: 9px;
    line-height: 18px;
    position: relative;
  }
}

.reject-icon {
  position: absolute;
  left: 0px;
  top: 0px;
}

.fc-col-header-cell-cushion {
  color: rgb(61, 61, 61) !important;
}

.fc-col-header {
  background-color: #fafafa;
}

.fc-daygrid-dot-event {
  padding-top: 0 !important;
}

.fc-button-primary {
  background-color: #666 !important;
  border-color: #666 !important;
}

.fc-daygrid-event-harness .fc-daygrid-event {
  /* background: rgba(0, 0, 0, 0) !important; */
  border: unset !important;
}

.fc-daygrid-event-harness-abs {
  position: relative !important;
  top: 0 !important;
}

.fc-daygrid-day-bottom,
.fc-daygrid-event-harness {
  margin-top: 0 !important;
}
</style>
