var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-dropdown",
        {
          attrs: { trigger: ["contextmenu"] },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "calendar-div",
              on: { click: _vm.contextmenuClick, contextmenu: _vm.contextmenu },
            },
            [
              _c(
                "div",
                {
                  staticClass: "box",
                  class: _vm.isView ? " no-title" : "",
                  style: _vm.backCss,
                  on: { mousedown: _vm.handleMouseDown },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "0", overflow: "hidden" } },
                    _vm._l(_vm.schedule, function (item, index) {
                      return _c(
                        "a-tooltip",
                        {
                          key: item.key + "-" + index,
                          ref: item.key + "-count",
                          refInFor: true,
                          attrs: { overlayClassName: "calendar-tooltip" },
                        },
                        [
                          _c(
                            "template",
                            { slot: "title" },
                            _vm._l(
                              item.data.filter(
                                (i) =>
                                  i.position &&
                                  i.position.positionName != "统筹" &&
                                  (i.depart.departName.indexOf("韩语部") > -1 ||
                                    i.depart.departName.indexOf("日语部") > -1)
                              ),
                              function (i) {
                                return _c(
                                  "p",
                                  { key: i.departId + "-" + i.positionId },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          i.position && i.position.positionName
                                            ? (i.depart &&
                                                i.depart.departName) +
                                                "-" +
                                                i.position.positionName
                                            : ""
                                        )
                                      ),
                                    ]),
                                    _vm._v("：\n                "),
                                    _c("span", [_vm._v(_vm._s(i.count))]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          item.data &&
                          item.data
                            .filter(
                              (i) =>
                                i.position &&
                                i.position.positionName != "统筹" &&
                                (i.depart.departName.indexOf("韩语部") > -1 ||
                                  i.depart.departName.indexOf("日语部") > -1)
                            )
                            .map((i) => i.count).length
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    left: "-7px",
                                  },
                                },
                                [
                                  !item.isEdit
                                    ? _c(
                                        "span",
                                        { staticClass: "title-span" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.data
                                                .filter(
                                                  (i) =>
                                                    i.position &&
                                                    i.position.positionName !=
                                                      "统筹" &&
                                                    (i.depart.departName.indexOf(
                                                      "韩语部"
                                                    ) > -1 ||
                                                      i.depart.departName.indexOf(
                                                        "日语部"
                                                      ) > -1)
                                                )
                                                .map((i) => i.count)
                                                .join("，")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { height: "0", overflow: "hidden" } },
                    [
                      _c("div", { staticClass: "calendar-button-list" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "fc-today-button fc-button fc-button-primary",
                            staticStyle: {
                              "border-top-right-radius": "0",
                              "border-bottom-right-radius": "0",
                            },
                            on: { click: _vm.prevClick },
                          },
                          [_c("a-icon", { attrs: { type: "left" } })],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "fc-today-button fc-button fc-button-primary",
                            staticStyle: {
                              "border-top-left-radius": "0",
                              "border-bottom-left-radius": "0",
                            },
                            on: { click: _vm.nextClick },
                          },
                          [_c("a-icon", { attrs: { type: "right" } })],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "calendar-button-list-left" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "fc-today-button fc-button fc-button-primary",
                            style:
                              _vm.viewType == "dayGridMonth" &&
                              _vm.calendarType == _vm.viewType
                                ? "border-top-right-radius:0;border-bottom-right-radius:0;box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 50%) !important;"
                                : "border-top-right-radius:0;border-bottom-right-radius:0;",
                            on: { click: _vm.monthClick },
                          },
                          [_vm._v("\n              月\n            ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "fc-today-button fc-button fc-button-primary",
                            style:
                              _vm.viewType == "dayGridWeek" &&
                              _vm.calendarType == _vm.viewType
                                ? "border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0;box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 50%) !important;"
                                : "border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0",
                            on: { click: _vm.weekClick },
                          },
                          [_vm._v("\n              周\n            ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "fc-today-button fc-button fc-button-primary",
                            style:
                              _vm.viewType == "dayGridDay" &&
                              _vm.calendarType == _vm.viewType
                                ? "border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0;box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 50%) !important;"
                                : "border-top-right-radius:0;border-bottom-right-radius:0;border-top-left-radius:0;border-bottom-left-radius:0",
                            on: { click: _vm.dayClick },
                          },
                          [_vm._v("\n              天\n            ")]
                        ),
                      ]),
                    ]
                  ),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_show_mask,
                        expression: "is_show_mask",
                      },
                    ],
                    staticClass: "mask",
                    style:
                      "width:" +
                      _vm.mask_width +
                      "left:" +
                      _vm.mask_left +
                      "height:" +
                      _vm.mask_height +
                      "top:" +
                      _vm.mask_top,
                  }),
                  _vm.rlShow
                    ? _c("FullCalendar", {
                        ref: "fullCalendar",
                        staticClass: "fullCalendar",
                        attrs: { options: _vm.calendarOptions },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "eventContent",
                              fn: function (arg) {
                                return [
                                  arg.event.title &&
                                  arg.event.title != "dropDiv"
                                    ? _c(
                                        "div",
                                        {
                                          ref: arg.event.extendedProps.record
                                            .id,
                                          style:
                                            "width:100%;margin-right:5px;margin-bottom:5px",
                                          attrs: {
                                            id:
                                              arg.event.extendedProps.record
                                                .chapterId +
                                              "-" +
                                              arg.event.extendedProps.record
                                                .productionId +
                                              "-" +
                                              arg.event.extendedProps.record.id,
                                          },
                                        },
                                        [
                                          arg.event.title &&
                                          arg.event.extendedProps.record.type !=
                                            "customEvent" &&
                                          !_vm.isView
                                            ? _c(
                                                "div",
                                                {
                                                  style:
                                                    "display: flex; justify-content: space-between;width:100%;" +
                                                    `border:${arg.event.extendedProps.record.border}`,
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        width: "92%",
                                                        position: "relative",
                                                      },
                                                    },
                                                    [
                                                      arg.event.title &&
                                                      _vm.isSelect
                                                        ? _c("a-checkbox", {
                                                            ref:
                                                              arg.event
                                                                .extendedProps
                                                                .record.id +
                                                              "-check",
                                                            attrs: {
                                                              id:
                                                                arg.event
                                                                  .extendedProps
                                                                  .record.id +
                                                                "-check",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              },
                                                            },
                                                          })
                                                        : arg.event.title &&
                                                          _vm.isDropSelect &&
                                                          arg.event
                                                            .extendedProps
                                                            .record
                                                            .taskStatus !=
                                                            "已完成"
                                                        ? _c("a-checkbox", {
                                                            ref:
                                                              arg.event
                                                                .extendedProps
                                                                .record.id +
                                                              "-select",
                                                            attrs: {
                                                              id:
                                                                arg.event
                                                                  .extendedProps
                                                                  .record.id +
                                                                "-select",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              },
                                                              change: (e) => {
                                                                _vm.dropSelectChange(
                                                                  e,
                                                                  arg.event
                                                                    .extendedProps
                                                                    .record
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "5px",
                                                            overflow: "hidden",
                                                            "white-space":
                                                              "nowrap",
                                                            "text-overflow":
                                                              "ellipsis",
                                                            "-o-text-overflow":
                                                              "ellipsis",
                                                            "max-width": "50%",
                                                            display:
                                                              "inline-block",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                arg.event
                                                                  .extendedProps
                                                                  .record
                                                                  .productSequenceNo
                                                                  ? arg.event
                                                                      .extendedProps
                                                                      .record
                                                                      .productSequenceNo +
                                                                      "-"
                                                                  : ""
                                                              ) +
                                                              _vm._s(
                                                                arg.event.title.split(
                                                                  "-"
                                                                )[0]
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      arg.event
                                                                        .extendedProps
                                                                        .record
                                                                        .productSequenceNo
                                                                        ? arg
                                                                            .event
                                                                            .extendedProps
                                                                            .record
                                                                            .productSequenceNo +
                                                                            "-"
                                                                        : ""
                                                                    ) +
                                                                    _vm._s(
                                                                      arg.event.title.split(
                                                                        "-"
                                                                      )[0]
                                                                    ) +
                                                                    "\n                        " +
                                                                    _vm._s(
                                                                      "-" +
                                                                        arg.event.title.split(
                                                                          "-"
                                                                        )[1] +
                                                                        "-" +
                                                                        arg.event.title.split(
                                                                          "-"
                                                                        )[2] +
                                                                        "-" +
                                                                        arg.event.title.split(
                                                                          "-"
                                                                        )[3] +
                                                                        (arg.event.title.split(
                                                                          "-"
                                                                        )[4]
                                                                          ? "-" +
                                                                            arg.event.title.split(
                                                                              "-"
                                                                            )[4]
                                                                          : "")
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "min-width":
                                                                  "100px",
                                                                display:
                                                                  "inline-block",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    "-" +
                                                                      arg.event.title.split(
                                                                        "-"
                                                                      )[1] +
                                                                      "-" +
                                                                      arg.event.title.split(
                                                                        "-"
                                                                      )[2] +
                                                                      "-" +
                                                                      arg.event.title.split(
                                                                        "-"
                                                                      )[3] +
                                                                      (arg.event.title.split(
                                                                        "-"
                                                                      )[4]
                                                                        ? "-" +
                                                                          arg.event.title.split(
                                                                            "-"
                                                                          )[4]
                                                                        : "")
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "remark-div",
                                                    },
                                                    [
                                                      arg.event.extendedProps
                                                        .record &&
                                                      "customEvent" !=
                                                        arg.event.extendedProps
                                                          .record.type
                                                        ? _c("actions-tasks", {
                                                            attrs: {
                                                              isMyTask:
                                                                _vm.isMyTask,
                                                              isCalendar: true,
                                                              propsParams:
                                                                arg.event
                                                                  .extendedProps
                                                                  .record,
                                                              loadData:
                                                                _vm.changeStatusData,
                                                            },
                                                            on: {
                                                              openTaskModel:
                                                                _vm.openTaskModel,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      arg.event.extendedProps
                                                        .record.hasNote
                                                        ? _c("div", {
                                                            staticClass:
                                                              "remark-item",
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  arg.event.extendedProps.record
                                                    .taskStatus == "驳回" &&
                                                  arg.event.extendedProps.record
                                                    .rejectTask &&
                                                  arg.event.extendedProps.record
                                                    .rejectTask.taskName ==
                                                    "甲方反馈"
                                                    ? _c("div", {
                                                        staticClass:
                                                          "reject-icon",
                                                      })
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          arg.event.title &&
                                          arg.event.extendedProps.record.type ==
                                            "customEvent" &&
                                          _vm.isView
                                            ? _c(
                                                "div",
                                                {
                                                  style:
                                                    "display: flex; justify-content: space-between;width:100%;" +
                                                    `border:${arg.event.extendedProps.record.border}`,
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "93%",
                                                      },
                                                      on: {
                                                        dblclick: function (
                                                          $event
                                                        ) {
                                                          return _vm.openEditEvent(
                                                            arg.event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      arg.event.title &&
                                                      _vm.isSelect
                                                        ? _c("a-checkbox", {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              },
                                                              change: (e) => {
                                                                _vm.selectChange(
                                                                  e,
                                                                  arg.event
                                                                    .extendedProps
                                                                    .record
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : arg.event.title &&
                                                          _vm.isDropSelect &&
                                                          arg.event
                                                            .extendedProps
                                                            .record
                                                            .taskStatus !=
                                                            "已完成"
                                                        ? _c("a-checkbox", {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              },
                                                              change: (e) => {
                                                                _vm.dropSelectChange(
                                                                  e,
                                                                  arg.event
                                                                    .extendedProps
                                                                    .record
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c("input", {
                                                        staticStyle: {
                                                          position: "relative",
                                                          top: "-1px",
                                                          left: "3px",
                                                        },
                                                        attrs: {
                                                          type: "radio",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            arg.event
                                                              .extendedProps
                                                              .checked == "YES"
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          click: (e) => {
                                                            _vm.radioClick(
                                                              e,
                                                              arg.event
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          style:
                                                            arg.event
                                                              .extendedProps
                                                              .checked == "YES"
                                                              ? "text-decoration:line-through;position: relative;top: 1px;left: 8px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;width: 90%;display: inline-block;"
                                                              : "position: relative;top: 1px;left: 8px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;width: 90%;display: inline-block;",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              arg.event.title
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  arg.event.extendedProps.record
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "4px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeEvent(
                                                                arg.event
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("a-icon", {
                                                            staticClass:
                                                              "delete-icon",
                                                            attrs: {
                                                              type: "delete",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : arg.event.title == "dropDiv"
                                    ? _c("div", { staticClass: "drop-list" })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1664896966
                        ),
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "day-show",
                      style: "height:" + _vm.dayHeight,
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: { "padding-right": "2vw" },
                              attrs: { span: 12 },
                            },
                            [
                              _c("div", { staticClass: "day-show-num" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "16px" } },
                                    [_vm._v("今日任务")]
                                  ),
                                ]),
                                _c("div", [
                                  _c("span", [_vm._v("已完成：")]),
                                  _c("span", [_vm._v(_vm._s(_vm.overNum))]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "15px" } },
                                    [_vm._v("待完成：")]
                                  ),
                                  _c("span", [_vm._v(_vm._s(_vm.waitNum))]),
                                ]),
                              ]),
                              _vm._l(_vm.dayList, function (item) {
                                return [
                                  item.title
                                    ? _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticStyle: {
                                            width: "100%",
                                            "margin-bottom": "5px",
                                            position: "relative",
                                          },
                                        },
                                        [
                                          item.title &&
                                          item.record.type != "customEvent"
                                            ? _c(
                                                "div",
                                                {
                                                  style:
                                                    "display: flex; justify-content: space-between;width:100%;" +
                                                    `background-color:${item.record.backGroundColor};color:${item.record.textColor};border:${item.record.border}`,
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "day-view-event",
                                                      staticStyle: {
                                                        width: "100%",
                                                        "padding-left": "5px",
                                                        display: "flex",
                                                      },
                                                      attrs: {
                                                        id:
                                                          item.record
                                                            .chapterId +
                                                          "-" +
                                                          item.record.id +
                                                          "-" +
                                                          item.record
                                                            .productionId,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.handleEventDayClick(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      item.title && _vm.isSelect
                                                        ? _c("a-checkbox", {
                                                            ref:
                                                              item.record.id +
                                                              "-check",
                                                            refInFor: true,
                                                            attrs: {
                                                              id:
                                                                item.record.id +
                                                                "-check",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              },
                                                              change: (e) => {
                                                                _vm.selectDayChange(
                                                                  e,
                                                                  item.record
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "day-view-event",
                                                          staticStyle: {
                                                            "margin-left":
                                                              "5px",
                                                            display:
                                                              "inline-block",
                                                          },
                                                          attrs: {
                                                            id:
                                                              item.record
                                                                .chapterId +
                                                              "-" +
                                                              item.record.id +
                                                              "-" +
                                                              item.record
                                                                .productionId +
                                                              "-order",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.record
                                                                  .productSequenceNo
                                                                  ? item.record
                                                                      .productSequenceNo +
                                                                      "-"
                                                                  : ""
                                                              ) +
                                                              _vm._s(
                                                                item.title.split(
                                                                  "-"
                                                                )[0]
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "day-view-event",
                                                          staticStyle: {
                                                            "min-width":
                                                              "100px",
                                                            display:
                                                              "inline-block",
                                                          },
                                                          attrs: {
                                                            id:
                                                              item.record
                                                                .chapterId +
                                                              "-" +
                                                              item.record.id +
                                                              "-" +
                                                              item.record
                                                                .productionId +
                                                              "-content",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                "-" +
                                                                  item.title.split(
                                                                    "-"
                                                                  )[1] +
                                                                  "-" +
                                                                  item.title.split(
                                                                    "-"
                                                                  )[2] +
                                                                  "-" +
                                                                  item.title.split(
                                                                    "-"
                                                                  )[3] +
                                                                  (item.title.split(
                                                                    "-"
                                                                  )[4]
                                                                    ? "-" +
                                                                      item.title.split(
                                                                        "-"
                                                                      )[4]
                                                                    : "")
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "remark-div day-view-event",
                                                      attrs: {
                                                        id:
                                                          item.record
                                                            .chapterId +
                                                          "-" +
                                                          item.record.id +
                                                          "-" +
                                                          item.record
                                                            .productionId +
                                                          "-remarkDiv",
                                                      },
                                                    },
                                                    [
                                                      item.record &&
                                                      "customEvent" !=
                                                        item.record.type
                                                        ? _c("actions-tasks", {
                                                            staticClass:
                                                              "day-view-event",
                                                            attrs: {
                                                              propsParams:
                                                                item.record,
                                                              isMyTask:
                                                                _vm.isMyTask,
                                                              id:
                                                                item.record
                                                                  .chapterId +
                                                                "-" +
                                                                item.record.id +
                                                                "-" +
                                                                item.record
                                                                  .productionId +
                                                                "-remark",
                                                              isCalendar: true,
                                                              loadData:
                                                                _vm.reDayloadData,
                                                            },
                                                            on: {
                                                              openTaskModel:
                                                                _vm.openTaskModel,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      item.record.hasNote
                                                        ? _c("div", {
                                                            staticClass:
                                                              "remark-item",
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          item.record.taskStatus == "驳回" &&
                                          item.record.rejectTask &&
                                          item.record.rejectTask.taskName ==
                                            "甲方反馈"
                                            ? _c("div", {
                                                staticClass: "reject-icon",
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                          _c(
                            "a-col",
                            {
                              staticStyle: { "padding-left": "2vw" },
                              attrs: { span: 12 },
                            },
                            [
                              _c("div", { staticClass: "day-show-num" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "16px" } },
                                    [_vm._v("今日待办")]
                                  ),
                                ]),
                              ]),
                              _vm._l(_vm.eventList, function (item) {
                                return [
                                  item.title
                                    ? _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticStyle: {
                                            width: "100%",
                                            "margin-bottom": "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              style:
                                                "display: flex; justify-content: space-between;width:100%;background-color:#f8f8f8",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    "padding-left": "5px",
                                                  },
                                                },
                                                [
                                                  _c("input", {
                                                    staticStyle: {
                                                      position: "relative",
                                                      top: "2px",
                                                      left: "3px",
                                                    },
                                                    attrs: { type: "radio" },
                                                    domProps: {
                                                      value:
                                                        item.checked == "YES"
                                                          ? true
                                                          : false,
                                                    },
                                                    on: {
                                                      click: (e) => {
                                                        _vm.radioDayClick(
                                                          e,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        item.checked == "YES"
                                                          ? "text-decoration:line-through;position: relative;top: 0;left: 8px;"
                                                          : "position: relative;top: 0;left: 8px;",
                                                    },
                                                    [_vm._v(_vm._s(item.title))]
                                                  ),
                                                ]
                                              ),
                                              item.record
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "4px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeEvent(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "delete-icon",
                                                        attrs: {
                                                          type: "delete",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-modal",
                    {
                      attrs: {
                        title: "添加事件",
                        visible: _vm.addEventDlgShow,
                      },
                      on: {
                        ok: _vm.handleAddEventOk,
                        cancel: _vm.handleAddEventCancel,
                      },
                    },
                    [
                      _c(
                        "p",
                        [
                          _c("a-textarea", {
                            attrs: { autoSize: "" },
                            model: {
                              value: _vm.addEventText,
                              callback: function ($$v) {
                                _vm.addEventText = $$v
                              },
                              expression: "addEventText",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "a-modal",
                    {
                      attrs: {
                        title: "编辑事件",
                        visible: _vm.editEventDlgShow,
                      },
                      on: {
                        ok: _vm.handleEditEventOk,
                        cancel: _vm.handleAddEventCancel,
                      },
                    },
                    [
                      _c(
                        "p",
                        [
                          _c("a-textarea", {
                            attrs: { autoSize: "" },
                            model: {
                              value: _vm.editEvent.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.editEvent, "text", $$v)
                              },
                              expression: "editEvent.text",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "a-modal",
                    {
                      attrs: {
                        width: "900px",
                        visible: _vm.needScheduleTask,
                        destoryOnClose: "",
                        title: "自动排期",
                      },
                      on: {
                        ok: _vm.handleEditdateOk,
                        cancel: _vm.handleAddEventCancel,
                      },
                    },
                    [
                      _c("span", [_vm._v("自动排期：")]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            height: "40px",
                            "align-items": "center",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              on: { change: _vm.zdpqChange },
                              model: {
                                value: _vm.taskRadioValue,
                                callback: function ($$v) {
                                  _vm.taskRadioValue = $$v
                                },
                                expression: "taskRadioValue",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: "1" } }, [
                                _vm._v("是"),
                              ]),
                              _c("a-radio", { attrs: { value: "2" } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                          _vm.taskRadioValue == "1"
                            ? _c("a-checkbox-group", {
                                staticStyle: {
                                  display: "flex",
                                  flex: "1",
                                  "justify-content": "space-around",
                                  "margin-left": "15px",
                                },
                                attrs: { options: _vm.plainOptions },
                                model: {
                                  value: _vm.taskCheckedList,
                                  callback: function ($$v) {
                                    _vm.taskCheckedList = $$v
                                  },
                                  expression: "taskCheckedList",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.taskRadioValue == "2"
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "20px" } },
                            [
                              _c("chapter-table", {
                                ref: "chapterTable",
                                attrs: { scheduleTasks: _vm.scheduleTasks },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "a-menu",
            { attrs: { slot: "overlay" }, slot: "overlay" },
            [
              _vm.taskDetail.isLocal
                ? _c(
                    "a-menu-item",
                    {
                      key: "11",
                      on: {
                        click: function ($event) {
                          return _vm.goToTerm(_vm.taskDetail, 1)
                        },
                      },
                    },
                    [_c("span", [_vm._v("查看术语表")])]
                  )
                : _vm._e(),
              _vm.taskDetail.isLocal
                ? _c(
                    "a-menu-item",
                    {
                      key: "10",
                      on: {
                        click: function ($event) {
                          return _vm.goToTerm(_vm.taskDetail, 3)
                        },
                      },
                    },
                    [_c("span", [_vm._v("查看关系图")])]
                  )
                : _vm._e(),
              _vm.taskDetail.applyTranslation &&
              ((_vm.taskDetail.taskStatus != "等待" &&
                _vm.taskDetail.taskStatus != "驳回") ||
                (_vm.taskDetail.taskStatus == "驳回" &&
                  _vm.taskDetail.rejectTaskId))
                ? _c(
                    "a-menu-item",
                    {
                      key: "1",
                      on: {
                        click: function ($event) {
                          return _vm.goToTranslate(_vm.taskDetail)
                        },
                      },
                    },
                    [_c("span", [_vm._v("跳转翻译系统")])]
                  )
                : _vm._e(),
              _vm.taskDetail.applyReview &&
              ((_vm.taskDetail.taskStatus != "等待" &&
                _vm.taskDetail.taskStatus != "驳回") ||
                (_vm.taskDetail.taskStatus == "驳回" &&
                  _vm.taskDetail.rejectTaskId))
                ? _c(
                    "a-menu-item",
                    {
                      key: "2",
                      on: {
                        click: function ($event) {
                          return _vm.goToFatchStraw(_vm.taskDetail)
                        },
                      },
                    },
                    [_c("span", [_vm._v("跳转查稿系统")])]
                  )
                : _vm._e(),
              _vm.taskDetail.stageName == "给稿"
                ? _c(
                    "a-menu-item",
                    {
                      key: "3",
                      on: {
                        click: function ($event) {
                          return _vm.goToUpload(_vm.taskDetail)
                        },
                      },
                    },
                    [_c("span", [_vm._v("上传原稿")])]
                  )
                : _vm._e(),
              _vm.taskDetail.applyReview &&
              (_vm.taskDetail.stageName == "制作" ||
                _vm.taskDetail.stageName == "制作修改" ||
                _vm.taskDetail.stageName == "监制" ||
                _vm.taskDetail.stageName == "监制确认" ||
                _vm.taskDetail.stageName.indexOf("建模") > -1 ||
                _vm.taskDetail.stageName.indexOf("精草") > -1 ||
                _vm.taskDetail.stageName.indexOf("勾线") > -1 ||
                _vm.taskDetail.stageName.indexOf("上色") > -1 ||
                _vm.taskDetail.stageName.indexOf("后期") > -1)
                ? _c(
                    "a-menu-item",
                    {
                      key: "4",
                      on: {
                        click: function ($event) {
                          return _vm.gotoSumbmit(_vm.taskDetail)
                        },
                      },
                    },
                    [_c("span", [_vm._v("上传完成稿")])]
                  )
                : _vm._e(),
              _vm.taskDetail.systemTypes &&
              _vm.taskDetail.systemTypes.some(
                (item) => item == "IMAGE_TRANSLATION_SYSTEM"
              ) &&
              _vm.taskDetail.stageName == "制作"
                ? _c(
                    "a-menu-item",
                    {
                      key: "5",
                      on: {
                        click: function ($event) {
                          return _vm.downloadMake(_vm.taskDetail)
                        },
                      },
                    },
                    [_c("span", [_vm._v("下载制作稿")])]
                  )
                : _vm._e(),
              _vm.taskDetail.applyReview &&
              (_vm.taskDetail.stageName == "监制确认" ||
                _vm.taskDetail.stageName == "勾线终审" ||
                _vm.taskDetail.stageName == "上色终审" ||
                _vm.taskDetail.stageName == "监制" ||
                _vm.taskDetail.stageName == "交稿")
                ? _c(
                    "a-menu-item",
                    {
                      key: "6",
                      on: {
                        click: function ($event) {
                          return _vm.downloadOver(_vm.taskDetail, false)
                        },
                      },
                    },
                    [_c("span", [_vm._v("下载终稿")])]
                  )
                : _vm._e(),
              _vm.taskDetail.applyReview &&
              (_vm.taskDetail.stageName == "监制确认" ||
                _vm.taskDetail.stageName == "监制" ||
                _vm.taskDetail.stageName == "交稿")
                ? _c(
                    "a-menu-item",
                    {
                      key: "9",
                      on: {
                        click: function ($event) {
                          return _vm.downloadOver(_vm.taskDetail, true)
                        },
                      },
                    },
                    [_vm._v("下载切片")]
                  )
                : _vm._e(),
              _vm.taskDetail.applyTranslation &&
              _vm.taskDetail.stageName == "交稿"
                ? _c(
                    "a-menu-item",
                    {
                      key: "7",
                      on: {
                        click: function ($event) {
                          return _vm.downloadJpg()
                        },
                      },
                    },
                    [_c("span", [_vm._v("下载翻译JPG")])]
                  )
                : _vm._e(),
              _vm.taskDetail.applyTranslation &&
              _vm.taskDetail.stageName == "交稿"
                ? _c(
                    "a-menu-item",
                    {
                      key: "8",
                      on: {
                        click: function ($event) {
                          return _vm.downloadPdf()
                        },
                      },
                    },
                    [_c("span", [_vm._v("下载翻译PDF")])]
                  )
                : _vm._e(),
              _c("a-menu-item", { key: "5", on: { click: _vm.openDrawer } }, [
                _vm._v("\n        备注\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "备注",
            placement: "right",
            width: 700,
            footer: null,
            wrapClassName: "drawer-layout",
            visible: _vm.drawerVisible,
          },
          on: { cancel: _vm.onClose },
        },
        [
          _c("remark", {
            ref: "remark",
            attrs: {
              height: "500px",
              isMyTask: true,
              taskDetail: _vm.taskDetail,
              params: _vm.taskDetail,
            },
          }),
        ],
        1
      ),
      _c("tasks-model", {
        ref: "tasksModel",
        on: { closeTasksModel: _vm.closeTasksModel },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.confirmVisible,
            wrapClassName: "confirm-modal",
            bodyStyle: { padding: "32px", paddingBottom: "24px" },
            width: "416px",
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.confirmVisible = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                display: "flex",
                "align-items": "center",
                "font-weight": "500",
                color: "rgba(0, 0, 0, 0.85)",
              },
            },
            [
              _c("a-icon", {
                staticStyle: {
                  "font-size": "22px",
                  "margin-right": "15px",
                  color: "orange",
                },
                attrs: { type: "question-circle" },
              }),
              _vm._v("是否加载已锁定状态的文件?\n    "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                height: "62px",
                display: "flex",
                "align-items": "end",
                "justify-content": "end",
              },
            },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleConfimCancel(_vm.confirmTask)
                    },
                  },
                },
                [_vm._v("\n        不加载\n      ")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfimOk(_vm.confirmTask)
                    },
                  },
                },
                [_vm._v("\n        加载\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }