<template>
  <drawer-layout
    :is-preview="false"
    :isMaskClosable="isMaskClosable"
    :hide-edit-btn="hideEditBtn"
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    :permission="permission"
    :btnLoading="btnLoading"
    :confirm-btn-disable="confirmBtnDisable"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
    @onConfirm="onConfirm"
  >
    <div
      class="scroll-view"
      slot=""
      @scroll="onScroll"
      :class="{ 'allow-scroll': isPreview /* , 'no-scroll': isFrameHover */ }"
    >
      <g-title>基本信息</g-title>
      <info-item :label-width="labelWidth" label="名称">{{ curRow.guideName }}</info-item>
      <info-item :label-width="labelWidth" label="序号">{{ curRow.sequenceNo }}</info-item>
      <info-item :label-width="labelWidth" label="分类" v-if="!propsFilters.typeId">
        {{ typeNameString(curRow.typeId) }}
      </info-item>
      <info-item :label-width="labelWidth" label="属性">
        {{ classNameString(curRow.classId) }}
      </info-item>
      <info-item v-if="curRow.classId == 13" :label-width="labelWidth" label="词汇">
        <a-tag v-for="one in curRow.vocabularyList" :key="one.guideId">
          {{ one.guideName }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="适用部门">
        <a-tag v-for="one in curRow.departments" :key="one.departId">
          {{ one.departName }}
        </a-tag>
      </info-item>
      <info-item v-if="propsFilters.typeId == 4" :label-width="labelWidth" label="适用平台">
        <a-tag v-for="one in curRow.platforms" :key="one.platformId">
          {{ one.platformName }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="适用职位">
        <div v-if="propsFilters.typeId != 1">
          <a-tag v-for="one in curRow.positions" :key="one.positionId">
            {{ one.positionName }}
          </a-tag>
        </div>
        <div v-else>
          <a-tag v-for="one in curRow.positions" :key="one.positionId"
            >{{ one.positionName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
          >
        </div>
      </info-item>
      <info-item
        v-if="curRow.classId == 3 || curRow.classId == 10 || curRow.classId == 11 || curRow.classId == 12"
        :label-width="labelWidth"
        label="适用工具"
      >
        {{ inspectSimpleFilters(curRow) }}
      </info-item>

      <g-title v-if="curRow.classId != 5 && curRow.classId != 13 && curRow.classId != 9">正文</g-title>
      <!-- <iframe src="https://www.kdocs.cn/l/cusAibFk85d1" width="100%" height="100%" frameborder="0"></iframe> -->
      <div v-if="curRow.classId === 1">
        <!-- 文本 -->
        <info-item :label-width="labelWidth" label="文字方向">{{
          curRow.textLayout === 1 ? '水平' : curRow.textLayout === 2 ? '垂直' : ''
        }}</info-item>
        <info-item :label-width="labelWidth" label="对齐方式">
          <span v-if="curRow.styleItem.textAlign === 'left'"> 左对齐 </span>
          <span v-else-if="curRow.styleItem.textAlign === 'center'"> 居中对齐 </span>
          <span v-else-if="curRow.styleItem.textAlign === 'right'"> 右对齐 </span>
        </info-item>
        <!--         <info-item :label-width="labelWidth" label="是否加粗">
          {{ curRow.styleItem.isBold ? '是' : '否' }}
        </info-item> -->
        <info-item :label-width="labelWidth" label="行距">{{
          (curRow.styleItem.lineHeight * 100).toFixed(0) + '%'
        }}</info-item>
        <info-item :label-width="labelWidth" label="字高">{{
          (curRow.styleItem.scaleY * 100).toFixed(0) + '%'
        }}</info-item>
        <info-item :label-width="labelWidth" label="字宽">{{
          (curRow.styleItem.scaleX * 100).toFixed(0) + '%'
        }}</info-item>
        <info-item :label-width="labelWidth" label="字号">{{ (curRow.styleItem.fontSize - 1) / 4 }}</info-item>
        <!--         <info-item :label-width="labelWidth" label="颜色">
          <a-input v-model="curRow.styleItem.fontColor" type="color" class="fontColor" disabled />
        </info-item> -->
        <!-- <info-item :label-width="labelWidth" label="抗锯齿">{{ getAntiAliasMethod(curRow.styleItem) }}</info-item> -->

        <!-- <a-row> -->
        <!-- <a-col :span="12">
            <info-item :label-width="labelWidth" label="描边颜色">
              <a-input v-model="curRow.styleItem.outlineColor" type="color" class="outlineColor" disabled />
            </info-item>
          </a-col> -->
        <!-- <a-col :span="12">
            <info-item :label-width="labelWidth" label="描边宽度">{{ curRow.styleItem.outlineWidth }}</info-item>
          </a-col> -->
        <!-- </a-row> -->
      </div>
      <div v-if="curRow.classId === 15">
        <info-item :label-width="labelWidth" label="对齐方式">
          <span v-if="curRow.phoneticStyle && curRow.phoneticStyle.textAlign === 'left'"> 左对齐 </span>
          <span v-else-if="curRow.phoneticStyle && curRow.phoneticStyle.textAlign === 'center'"> 居中对齐 </span>
          <span v-else-if="curRow.phoneticStyle && curRow.phoneticStyle.textAlign === 'right'"> 右对齐 </span>
        </info-item>
        <info-item :label-width="labelWidth" label="行距">{{
          (curRow.phoneticStyle ? curRow.phoneticStyle.lineHeight * 100 : 0).toFixed(0) + '%'
        }}</info-item>
        <info-item :label-width="labelWidth" label="字高">{{
          (curRow.phoneticStyle ? curRow.phoneticStyle.scaleY * 100 : 0).toFixed(0) + '%'
        }}</info-item>
        <info-item :label-width="labelWidth" label="字间距"
          >-{{ curRow.phoneticStyle ? curRow.phoneticStyle.scaleX : 0 }}</info-item
        >
        <info-item :label-width="labelWidth" label="字号">{{
          curRow.phoneticStyle ? getDisplayFontSize(curRow.phoneticStyle) : 0
        }}</info-item>
        <!--         <info-item :label-width="labelWidth" label="颜色">
          <a-input v-model="curRow.phoneticStyle.fontColor" type="color" class="fontColor" disabled />
        </info-item> -->
        <!-- <info-item :label-width="labelWidth" label="抗锯齿">{{ getAntiAliasMethod(curRow.styleItem) }}</info-item> -->

        <!-- <a-row> -->
        <!-- <a-col :span="12">
            <info-item :label-width="labelWidth" label="描边颜色">
              <a-input v-model="curRow.styleItem.outlineColor" type="color" class="outlineColor" disabled />
            </info-item>
          </a-col> -->
        <!-- <a-col :span="12">
            <info-item :label-width="labelWidth" label="描边宽度">{{ curRow.styleItem.outlineWidth }}</info-item>
          </a-col> -->
        <!-- </a-row> -->
      </div>
      <div v-else-if="curRow.classId === 2">
        <div v-html="curRow.guideData"></div>
      </div>
      <div
        v-if="
          curRow.classId === 3 ||
            curRow.classId === 10 ||
            curRow.classId === 16 ||
            curRow.classId === 17 ||
            curRow.classId === 18 ||
            curRow.classId == 11
        "
      >
        <!-- 屏蔽词列表 -->
        <div v-for="item in curRow.blacklist" :key="item.classId" class="black_word">
          <h4 v-if="curRow.classId === 3">
            {{ item.className }}
          </h4>

          <span v-for="(word, idx) in item.words" :key="idx">
            <a-tooltip v-if="calcWord(word).length > 20" :title="calcWord(word)">
              <a-tag>
                {{ `${calcWord(word).slice(0, 20)}...` }}
              </a-tag>
            </a-tooltip>
            <a-tag v-else>
              {{ calcWord(word) }}
            </a-tag>
          </span>
        </div>
      </div>
      <div v-if="curRow.classId === 5">
        <!-- 尺寸设置 -->
        <g-title>原稿</g-title>
        <info-item :label-width="labelWidth" label="格式">{{ curRow.size && curRow.size.psdFormat }}</info-item>
        <info-item :label-width="labelWidth" label="分辨率(DPI)">{{
          curRow.size && curRow.size.psdResolution
        }}</info-item>
        <info-item :label-width="labelWidth" label="宽度(PX)">{{ curRow.size && curRow.size.psdWidth }}</info-item>
        <info-item :label-width="labelWidth" label="最小高度(PX)">{{
          curRow.size && curRow.size.psdMinHeight
        }}</info-item>
        <info-item :label-width="labelWidth" label="最大高度(PX)">{{
          curRow.size && curRow.size.psdMaxHeight
        }}</info-item>
        <g-title>完成稿</g-title>
        <info-item :label-width="labelWidth" label="格式">{{ curRow.size && curRow.size.jpgFormat }}</info-item>
        <info-item :label-width="labelWidth" label="分辨率(DPI)">{{
          curRow.size && curRow.size.jpgResolution
        }}</info-item>
        <info-item :label-width="labelWidth" label="宽度(PX)">{{ curRow.size && curRow.size.jpgWidth }}</info-item>
        <info-item :label-width="labelWidth" label="最小高度(PX)">{{
          curRow.size && curRow.size.jpgMinHeight
        }}</info-item>
        <info-item :label-width="labelWidth" label="最大高度(PX)">{{
          curRow.size && curRow.size.jpgMaxHeight
        }}</info-item>
        <g-title>切片</g-title>
        <info-item :label-width="labelWidth" label="单张最大(M)">{{
          curRow.size && curRow.size.sectionSingleSize
        }}</info-item>
        <info-item :label-width="labelWidth" label="总共最大(M)">{{
          curRow.size && curRow.size.sectionTotalSize
        }}</info-item>
        <g-title>tmb</g-title>
        <info-item :label-width="labelWidth" label="格式">{{ curRow.size && curRow.size.tmbFormat }}</info-item>
        <info-item :label-width="labelWidth" label="宽度(PX)">{{ curRow.size && curRow.size.tmbWidth }}</info-item>
        <info-item :label-width="labelWidth" label="高度(PX)">{{ curRow.size && curRow.size.tmbHeight }}</info-item>
      </div>
      <div v-if="curRow.classId === 6">
        <!-- 封面设置 -->
        <info-item :label-width="labelWidth" label="章节封面的宽度(PX)">{{
          curRow.coverImage.thumbnailImageWidth
        }}</info-item>
        <info-item :label-width="labelWidth" label="章节封面的高度(PX)">{{
          curRow.coverImage.thumbnailImageHeight
        }}</info-item>
        <info-item :label-width="labelWidth" label="章节封面的格式">{{
          curRow.coverImage.thumbnailImageFormat
        }}</info-item>
        <info-item :label-width="labelWidth" label="章节封面文件大小(MB)">{{
          curRow.coverImage.thumbnailImageMaxSize
        }}</info-item>
      </div>
      <div v-if="curRow.classId === 7">
        <div v-for="item in curRow.blacklist" :key="item.classId" class="black_word">
          <h4>
            {{ item.className }}
          </h4>

          <a-row :gutter="16" v-for="(word, index) in item.words" :key="index">
            <a-col :span="6" style="white-space: pre-wrap" class="py-3">{{ word.value || '' }}</a-col>
            <a-col :span="6" style="white-space: pre-wrap" class="py-3">{{ word.target || '' }}</a-col>
            <a-col :span="12" style="white-space: pre-wrap" class="py-3">{{ word.remark || '' }}</a-col>
          </a-row>
        </div>
      </div>
      <div v-if="curRow.classId === 12">
        <div v-for="item in curRow.blacklist" :key="item.classId" class="black_word">
          <div v-for="(word, index) in item.words" :key="index">
            <div style="display: flex">
              <span
                style="white-space: pre-line; margin-right: 15px; text-align: left"
                v-for="(value, index) in word.values"
                :key="index"
              >
                {{ value || '' }}
              </span>
            </div>
            <div style="white-space: pre-line; margin-right: 15px; text-align: left; position: relative; top: -20px">
              {{ word.remark }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="curRow.classId === 8">
        <!-- PDF文件 -->
        <div v-if="curRow.guideData">
          <pdf v-for="i in pdfPagesCount" :key="i" :src="pdfDocument" :page="i"></pdf>
          <!-- <iframe frameborder="0" width="100%" height="600px" scrolling="auto" :src="curRow.guideData" /> -->
          <!-- <a v-if="curRow.guideData" :href="curRow.guideData" target="_blank">预览文件</a> -->
        </div>
      </div>
      <!--       <template v-if="curRow.classId === 9">
        <iframe
          @mouseenter="moveInIframe"
          @mouseout="moveOutIframe"
          :src="curRow.guideData"
          width="100%"
          height="800px"
          frameborder="0"
        />
      </template>
      <div v-else>
        <a-row :gutter="16" v-for="(item, index) in curRow.fonts" :key="index">
          <a-col :span="3" class="py-3">{{ item.sequenceNo }}</a-col>
          <a-col :span="5" class="py-3">
            {{ item.usage }}
          </a-col>
          <a-col :span="6" class="py-3">
            {{ item.fontFamily.join(',') }}
          </a-col>
          <a-col :span="6" class="py-3">
            {{ item.description }}
          </a-col>
        </a-row>
      </div> -->
      <div v-if="curRow.classId === 4">
        <!-- 字体等 -->
        <a-row>
          <!--           <a-col :span="3">
            <div class="py-3">序号</div>
          </a-col> -->
          <a-col :span="7">
            <div class="py-3">用途</div>
          </a-col>
          <a-col :span="10">
            <div class="py-3">字体</div>
          </a-col>
          <a-col :span="4">
            <div class="py-3">加粗</div>
          </a-col>
          <a-col :span="3">
            <div class="py-3">颜色</div>
          </a-col>
          <!--           <a-col :span="7">
            <div class="py-3">说明</div>
          </a-col> -->
        </a-row>
        <a-row :gutter="16" v-for="(item, index) in curRow.fonts" :key="index">
          <!-- <a-col :span="3" class="py-3">{{ item.sequenceNo }}</a-col> -->
          <a-col :span="7" class="py-3" style="padding-right:0">
            {{ item.usage }}
          </a-col>
          <a-col :span="10" class="py-3" style="padding-right:0">
            {{ item.fontFamily.join(',') }}
          </a-col>
          <a-col :span="4" class="py-3" style="padding-right:0">
            {{ item.isBold ? '是' : '否' }}
          </a-col>
          <a-col :span="3" class="py-3" style="padding:0">
            <a-input v-model="item.fontColor" type="color" class="fontColor" disabled />
          </a-col>
          <!--           <a-col :span="7" class="py-3">
            {{ item.description }}
          </a-col> -->
        </a-row>
      </div>
    </div>

    <div class="scroll-view" :class="{ 'allow-scroll': isPreview, 'no-scroll': isFrameHover }" slot="form">
      <g-title>基本信息</g-title>
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="名称" prop="guideName">
          <a-input v-model="formData.guideName" placeholder="请输入名称" v-if="formData.id" />
          <a-auto-complete v-model="formData.guideName" placeholder="请输入名称" v-else @change="guideNameChanged">
            <template slot="dataSource">
              <a-select-option v-for="item in guideTemplates" :key="item.id + ''">{{ item.guideName }}</a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="formData.sequenceNo" placeholder="请输入序号" class="w-full" />
        </a-form-model-item>

        <a-form-model-item label="选择分类" v-if="!propsFilters.typeId">
          <a-select v-model="formData.typeId" placeholder="请选择分类" @change="typeIdChanged">
            <a-select-option v-for="item in configFormData.typeIds" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="选择属性">
          <a-select
            v-if="propsFilters.typeId == 4"
            v-model="formData.classId"
            placeholder="请选择类型"
            @change="classIdChanged"
          >
            <a-select-option
              v-for="item in configFormData.classIds.filter(i => {
                return (
                  i.value != 1 &&
                  i.value != 2 &&
                  i.value != 4 &&
                  i.value != 5 &&
                  i.value != 6 &&
                  i.value != 8 &&
                  i.value != 9 &&
                  i.value != 15
                )
              })"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-select
            v-else-if="propsFilters.typeId == 1"
            v-model="formData.classId"
            placeholder="请选择类型"
            @change="classIdChanged"
          >
            <a-select-option
              v-for="item in configFormData.classIds.filter(i => {
                return i.value == 1 || i.value == 5 || i.value == 6 || i.value == 4 || i.value == 13 || i.value == 15
              })"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-select v-else v-model="formData.classId" placeholder="请选择类型" @change="classIdChanged">
            <a-select-option v-for="item in configFormData.classIds" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item v-if="formData.classId == 13" label="选择词汇" prop="selectedDepart">
          <a-select mode="multiple" @change="vocabularyChange" v-model="selectedVocabulary" placeholder="请选择词汇">
            <a-select-option v-for="item in vocabularyList" :key="item.guideId">
              {{ item.guideName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择部门" prop="selectedDepart">
          <a-tree-select
            v-model="selectedDeparts"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            treeDefaultExpandAll
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
        <a-form-model-item v-if="propsFilters.typeId == 4" label="选择平台">
          <a-select
            style="width: 100%"
            placeholder="请选择平台"
            option-filter-prop="label"
            mode="multiple"
            v-model="selectPlatformIds"
          >
            <a-select-option
              v-for="i in allPlatformList.filter(
                item => !selectedDeparts.length || selectedDeparts.some(d => d == item.departmentId)
              )"
              :key="i.id"
              :label="i.platformName"
            >
              {{ i.platformName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择职位" prop="selectedPositions">
          <a-tree-select
            v-if="propsFilters.typeId != 1"
            v-model="selectedPositions"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreePositionList"
            search-placeholder="选择职位"
            placeholder="请选择适用职位"
            @change="changePositionList"
          />
          <a-tree-select
            v-else
            v-model="selectedPositionsNew"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreePositionListNew"
            search-placeholder="选择职位"
            placeholder="请选择适用职位"
            @change="changePositionListNew"
          />
        </a-form-model-item>
        <!--         <a-form-model-item
          label="是否同步"
          prop="isSync"
        >
          <a-checkbox
            v-model="formData.isSync"
            placeholder="请输入序号"
            class="w-full"
          />
        </a-form-model-item> -->
        <a-form-model-item
          v-if="formData.classId == 3 || formData.classId == 10 || formData.classId == 11 || formData.classId == 12"
          label="适用工具"
          prop="isSync"
        >
          <a-select placeholder="请选择工具" v-model="inspectSimpleValue" @change="inspectSimpleChange">
            <a-select-option v-for="item in inspectSimpleList" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <g-title v-if="formData.classId != 5 && formData.classId != 13">正文</g-title>
        <div v-if="formData.classId === 1">
          <a-form-model-item label="文字方向">
            <a-select v-model="formData.textLayout" placeholder="请选择文字方向">
              <a-select-option v-for="item in configFormData.textLayout" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="对齐方式">
            <a-select v-model="formData.styleItem.textAlign" placeholder="请选择对齐方式">
              <a-select-option v-for="item in configFormData.textAlign" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--           <a-form-model-item label="是否加粗">
            <a-radio-group v-model="formData.styleItem.isBold" :default-value="0">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item> -->

          <a-form-model-item label="行距">
            <a-select v-model="formData.styleItem.lineHeight" placeholder="请选择行距">
              <a-select-option v-for="item in configFormData.lineHeight" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!-- <a-form-model-item label="锯齿选项">
          <a-select v-model="formData.styleItem.letterSpacing" placeholder="请选择锯齿选项">
            <a-select-option v-for="item in configFormData.letterSpacing" :key="item.id">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->

          <a-form-model-item label="字高">
            <a-select v-model="formData.styleItem.scaleY" placeholder="请选择字高">
              <a-select-option v-for="item in configFormData.scaleY" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="字宽">
            <a-select v-model="formData.styleItem.scaleX" placeholder="请选择字宽">
              <a-select-option v-for="item in configFormData.scaleX" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="字号">
            <a-select
              :filter-option="filterOption"
              show-search
              v-model="formData.styleItem.fontSize"
              @search="fontChange"
              placeholder="请选择字号"
            >
              <a-select-option v-for="item in configFormData.fontSize" :value="item.value" :key="item.value">
                {{ item.label + '' }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!--           <a-form-model-item label="颜色">
            <a-input v-model="formData.styleItem.fontColor" type="color" class="fontColor" />
          </a-form-model-item> -->

          <!-- <a-form-model-item label="抗锯齿">
            <a-select v-model="formData.styleItem.antiAliasMethod" placeholder="请选择">
              <a-select-option v-for="item in configFormData.antiAliasMethod" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
          <!-- <a-row> -->
          <!-- <a-col :span="12"> -->
          <!-- <a-form-model-item label="描边颜色" class="form_min_width">
                <a-input
                  v-model="formData.styleItem.outlineColor"
                  type="color"
                  class="outlineColor"
                  @change="handleOutLineColor"
                />
              </a-form-model-item>
            </a-col> -->
          <!-- <a-col :span="12">
              <a-form-model-item label="描边宽度" class="form_min_width">
                <a-input-number id="inputNumber" v-model="formData.styleItem.outlineWidth" class="inputNumber" />
              </a-form-model-item>
            </a-col>
          </a-row> -->
          <!-- <a-row> -->
          <!-- <a-col :span="12">
              <div class="default_outline_color">
                <span
                  v-for="item in configFormData.defaultOutlineColor"
                  :key="item.color"
                  :style="{ background: item.color }"
                  @click="() => handleSetOutlineColor(item.color)"
                  :class="item.color === formData.styleItem.outlineColor ? 'active_outlineColor' : ''"
                ></span>
              </div>
            </a-col> -->
          <!-- <a-col :span="12">
              <div class="default_outline_width">
                <span
                  v-for="item in configFormData.defaultOutlineWidth"
                  :key="item.width"
                  @click="() => handleSetOutlineWidth(item.width)"
                  :class="item.width === formData.styleItem.outlineWidth ? 'active_outlineWidth' : ''"
                >
                  {{ item.width }}
                </span>
              </div>
            </a-col> -->
          <!-- </a-row> -->
        </div>
        <div v-if="formData.classId === 15">
          <a-form-model-item label="对齐方式">
            <a-select v-model="formData.phoneticStyle.textAlign" placeholder="请选择对齐方式">
              <a-select-option v-for="item in configFormData.textAlign" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="行距">
            <a-select v-model="formData.phoneticStyle.lineHeight" placeholder="请选择行距">
              <a-select-option v-for="item in configFormData.lineHeight" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!-- <a-form-model-item label="锯齿选项">
          <a-select v-model="formData.styleItem.letterSpacing" placeholder="请选择锯齿选项">
            <a-select-option v-for="item in configFormData.letterSpacing" :key="item.id">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->

          <a-form-model-item label="字高">
            <a-select v-model="formData.phoneticStyle.scaleY" placeholder="请选择字高">
              <a-select-option v-for="item in configFormData.scaleY" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="字间距">
            <a-select v-model="formData.phoneticStyle.scaleX" placeholder="请选择字间距">
              <a-select-option :value="100">
                -100
              </a-select-option>
              <a-select-option :value="0">
                0
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="字号">
            <a-select v-model="formData.phoneticStyle.fontSize" placeholder="请选择字号">
              <a-select-option v-for="item in configFormData.fontSize" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!--           <a-form-model-item label="颜色">
            <a-input v-model="formData.phoneticStyle.fontColor" type="color" class="fontColor" />
          </a-form-model-item> -->
        </div>

        <div v-else-if="formData.classId === 2">
          <custorm-editor v-model="formData.guideData"> </custorm-editor>
        </div>
        <div
          v-else-if="
            formData.classId === 3 ||
              formData.classId === 10 ||
              formData.classId === 16 ||
              formData.classId === 17 ||
              formData.classId === 18 ||
              formData.classId === 11 ||
              formData.classId === 12
          "
        >
          <a-form-model-item
            :label="'新增' + configFormData.classIds.find(item => item.value == formData.classId).label"
            v-if="formData.classId === 3"
          >
            <a-select placeholder="请选择" v-model="formData.typeBackWord" @change="handleAddBlackWord">
              <a-select-option v-for="item in typeBackWordList" :key="item.value">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!-- 新增类型 -->
          <div v-for="(item, idx) in formData.blacklist" :key="item.classId" class="black_word">
            <div class="black_header" v-if="formData.classId === 3">
              <h4>
                {{ item.className }}
              </h4>
              <a-popconfirm
                title="确定？"
                ok-text="是"
                cancel-text="否"
                @confirm="() => handleDeleteBlackWordType(item)"
              >
                <div>x</div>
              </a-popconfirm>
            </div>
            <!-- <div v-for="word in item.words" :key="word" :style="{ display: 'inline' }">
              <a-tooltip v-if="word.length > 20" :title="word">
                <a-tag :key="word" closable @close="() => handleClose(removedTag)" color="#2db7f5">
                  {{ `${word.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag v-else closable @close="() => handleClose(removedTag)" color="#2db7f5">
                {{ word }}
              </a-tag>
            </div>
            <a-input v-if="item.inputVisible" :ref="`input_${item.classId}`" type="text" size="small"
              :style="{ width: '78px' }" :value="item.inputValue" @change="handleInputChange"
              @blur="() => handleInputConfirm(item)" @keyup.enter="() => handleInputConfirm(item)" />
            <a-tag v-else style="background: #fff; borderstyle: dashed" @click="() => showInput(item)">
              <a-icon type="plus" /> 新增
            </a-tag> -->
            <tag-drag-select
              :key="idx"
              :scope-id="idx"
              :classId="formData.classId"
              @change="dataList => wordsChange(dataList, idx)"
              :prop-data-list="item.words"
            ></tag-drag-select>
          </div>
        </div>
        <div v-else-if="formData.classId === 5">
          <g-title>原稿</g-title>
          <a-form-model-item label="格式">
            <a-select v-model="formData.size.psdFormats" mode="multiple">
              <a-select-option :value="'PSD'">PSD</a-select-option>
              <a-select-option :value="'JPG'">JPG</a-select-option>
              <a-select-option :value="'PNG'">PNG</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="分辨率(DPI)">
            <a-input-number v-model="formData.size.psdResolution" />
          </a-form-model-item>
          <a-form-model-item label="宽度(PX)">
            <a-input-number v-model="formData.size.psdWidth" />
          </a-form-model-item>
          <a-form-model-item label="最小高度(PX)">
            <a-input-number v-model="formData.size.psdMinHeight" />
          </a-form-model-item>
          <a-form-model-item label="最大高度(PX)">
            <a-input-number v-model="formData.size.psdMaxHeight" />
          </a-form-model-item>
          <g-title>完成稿</g-title>
          <a-form-model-item label="格式">
            <a-select v-model="formData.size.jpgFormat">
              <a-select-option :value="'JPG'">JPG</a-select-option>
              <!-- <a-select-option :value="'JPG切片'">JPG切片</a-select-option> -->
              <a-select-option :value="'PNG'">PNG</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="分辨率(DPI)">
            <a-input-number v-model="formData.size.jpgResolution" />
          </a-form-model-item>
          <a-form-model-item label="宽度(PX)">
            <a-input-number v-model="formData.size.jpgWidth" />
          </a-form-model-item>
          <a-form-model-item label="最小高度(PX)">
            <a-input-number v-model="formData.size.jpgMinHeight" />
          </a-form-model-item>
          <a-form-model-item label="最大高度(PX)">
            <a-input-number v-model="formData.size.jpgMaxHeight" />
          </a-form-model-item>
          <g-title>切片</g-title>
          <a-form-model-item label="单张最大(M)">
            <a-input-number v-model="formData.size.sectionSingleSize" />
          </a-form-model-item>
          <a-form-model-item label="总共最大(M)">
            <a-input-number
              :min="formData.size && formData.size.sectionSingleSize ? formData.size.sectionSingleSize : 0"
              v-model="formData.size.sectionTotalSize"
            />
          </a-form-model-item>
          <g-title>tmb</g-title>
          <a-form-model-item label="格式">
            <a-select v-model="formData.size.tmbFormats" mode="multiple">
              <a-select-option :value="'JPG'">JPG</a-select-option>
              <a-select-option :value="'PNG'">PNG</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="宽度(PX)">
            <a-input-number v-model="formData.size.tmbWidth" />
          </a-form-model-item>
          <a-form-model-item label="高度(PX)">
            <a-input-number v-model="formData.size.tmbHeight" />
          </a-form-model-item>
        </div>
        <div v-else-if="formData.classId == 6">
          <a-form-model-item label="章节封面的宽度(PX)">
            <a-input-number v-model="formData.coverImage.thumbnailImageWidth" />
          </a-form-model-item>
          <a-form-model-item label="章节封面的高度(PX)">
            <a-input-number v-model="formData.coverImage.thumbnailImageHeight" />
          </a-form-model-item>
          <a-form-model-item label="章节封面的格式">
            <a-select v-model="formData.coverImage.thumbnailImageFormat">
              <a-select-option :value="'PNG'">PNG</a-select-option>
              <a-select-option :value="'JPG'">JPG</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="章节封面文件大小(MB)">
            <a-input-number v-model="formData.coverImage.thumbnailImageMaxSize" />
          </a-form-model-item>
        </div>
        <div v-else-if="formData.classId == 7">
          <a-form-model-item label="添加类型">
            <a-select
              placeholder="请选择专用词汇类型"
              v-model="formData.typeGlossaryList"
              @change="handleAddGlossaryType"
            >
              <a-select-option v-for="item in typeGlossaryList" :key="item.value">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div v-for="(item, idx) in formData.blacklist" :key="item.classId" class="black_word">
            <div class="black_header">
              <h4>
                {{ item.className }}
              </h4>
              <a-popconfirm
                title="确定？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="() => handleDeleteGlossaryType(item)"
              >
                <div>x</div>
              </a-popconfirm>
            </div>
            <tag-drag-select
              :key="idx"
              :scope-id="idx"
              :classId="formData.classId"
              @change="dataList => wordsChange(dataList, idx)"
              :prop-data-list="item.words"
            ></tag-drag-select>
          </div>
        </div>
        <div v-else-if="formData.classId == 8">
          <a-upload
            :headers="headers"
            accept=".pdf"
            name="file"
            :show-upload-list="false"
            action="/jeecg-boot/sys/common/upload"
            @change="handleChangeUpload"
          >
            <a-button> <a-icon type="upload" /> 上传文件 </a-button>
          </a-upload>
          <div style="margin-top: 16px" v-if="formData.guideData">
            <pdf v-for="i in pdfPagesCount" :key="i" :src="formData.guideData" :page="i"></pdf>
            <!-- <iframe frameborder="0" width="100%" height="600px" scrolling="auto" :src="formData.guideData" /> -->
            <!-- <a v-if="formData.guideData" :href="formData.guideData" target="_blank">预览文件</a> -->
          </div>
        </div>
        <template v-else-if="formData.classId === 9">
          <a-form-model-item label="设置外链">
            <a-input v-model="formData.guideData" placeholder="请输入外链地址" />
          </a-form-model-item>
          <!--           <iframe
            @mouseenter="moveInIframe"
            @mouseout="moveOutIframe"
            :src="formData.guideData"
            width="100%"
            height="800px"
            frameborder="0"
          /> -->
        </template>
        <div v-else-if="formData.classId == 4">
          <a-row :gutter="16">
            <!--             <a-col :span="3">
              <div class="py-3">序号</div>
            </a-col> -->
            <a-col :span="6">
              <div class="py-3">用途</div>
            </a-col>
            <a-col :span="8">
              <div class="py-3">字体</div>
            </a-col>
            <a-col :span="3">
              <div class="py-3">加粗</div>
            </a-col>
            <!--             <a-col :span="5">
              <div class="py-3">说明</div>
            </a-col> -->
            <a-col :span="3">
              <div class="py-3">颜色</div>
            </a-col>
            <a-col :span="4">
              <div class="py-3">操作</div>
            </a-col>
          </a-row>
          <a-row :gutter="16" v-for="(item, index) in formData.fonts" :key="index" class="mb-6">
            <!--             <a-col :span="3">
              <a-input-number v-model="item.sequenceNo" placeholder="请输入序号" class="w-full" />
            </a-col> -->
            <a-col :span="6">
              <a-select v-model="item.usage" placeholder="选择用途" dropdownClassName="font-select-guide-pro">
                <a-select-option v-for="itm in textStyleTypeList" :key="itm.value">{{ itm.label }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="8">
              <a-select
                dropdownClassName="font-select-guide-pro"
                v-model="item.fontFamily"
                :filter-option="filterOption"
                placeholder="选择字体"
                mode="multiple"
                :value="item.fontFamily"
              >
                <a-select-option v-for="itm in filterDepart(configFormData.defaultFontFamily)" :key="itm.displayName">{{
                  itm.displayName
                }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="3">
              <a-switch
                v-if="isShow"
                @change="e => switchChange(e, item)"
                :defaultChecked="item.isBold ? true : false"
              />
            </a-col>
            <!--             <a-col :span="5">
              <a-textarea auto-size v-model="item.description" placeholder="请输入描述" />
            </a-col> -->
            <a-col :span="3">
              <a-input v-model="item.fontColor" type="color" class="fontColor" />
            </a-col>
            <a-col :span="4">
              <a-button shape="circle" icon="minus" class="mr-3" v-if="index > 0" @click="removeField(index)" />
              <a-button shape="circle" icon="plus" @click="addField" />
            </a-col>
          </a-row>
        </div>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { configFormData } from './config'
import CustormEditor from '@/components/jeecg/JEditor'
import { getAction } from '@/api/manage'
import pdf from 'vue-pdf'
import TagDragSelect from '@/components/TagDragSelect'
import { decodeWord, encodeWord } from '@/utils/util.js'

const defaultSelectedDeparts = []
const defaultSelectedPositions = []
const NOT_DICTIORARY_ITEM = {
  classId: '10',
  className: '',
  inputValue: '',
  inputVisible: false,
  words: []
}
const defaultFormData = {
  textLayout: 1,
  styleItem: {
    textAlign: 'left',
    lineHeight: '1.2',
    scaleY: 1,
    scaleX: 1,
    fontSize: 8,
    fontColor: '#000000',
    antiAliasMethod: 'antiAliasSharp'
    // outlineColor: '#ffffff',
    // outlineWidth: 4,
  },
  phoneticStyle: {
    textAlign: 'left',
    lineHeight: '1.2',
    scaleY: 1,
    scaleX: 1,
    fontSize: 4,
    fontColor: '#000000'
  },
  typeId: 1,
  classId: 1,
  isSync: false
}
export default {
  mixins: [DrawerMixins],
  components: {
    CustormEditor,
    pdf,
    TagDragSelect
  },
  props: {
    propsFilters: {
      type: Object,
      default: () => ({})
    },
    propsUrl: {
      type: Object,
      default: {}
    },
    productionId: {
      type: [String, Number],
      default: ''
    },
    platformId: {
      type: [String, Number],
      default: ''
    },
    departmentId: {
      type: String,
      default: ''
    },
    positionId: {
      type: String,
      default: ''
    },
    permission: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'curRow',
    event: 'changeCurRow'
  },
  data() {
    return {
      isShow:true,
      inspectSimpleList: [
        { value: '0', label: '无' },
        { value: 'inspectSimpleBlack', label: '质检大师简黑名单生效' },
        { value: 'inspectSimpleFilter', label: '质检大师简白名单生效' },
        { value: 'inspectComplexBlack', label: '质检大师繁屏蔽词生效' },
        { value: 'inspectComplexFilter', label: '质检大师繁过滤词生效' },
        { value: 'inspectComplexNotDict', label: '质检大师繁非字库生效' },
        { value: 'inspectJapaneseBlack', label: '质检大师日黑名单生效' },
        { value: 'inspectJapaneseFilter', label: '质检大师日白名单生效' }
      ],
      list: [
        'inspectJapaneseBlack',
        'inspectJapaneseFilter',
        'inspectSimpleBlack',
        'inspectSimpleFilter',
        'inspectComplexBlack',
        'inspectComplexFilter',
        'inspectComplexNotDict'
      ],
      selectPlatformIds: [],
      inspectSimpleValue: '0',
      isFrameHover: false,
      confirmBtnDisable: true,
      isAddBlackWord: true,
      selectedDeparts: [],
      selectedVocabulary: [],
      allPlatformList: [],
      vocabularyList: [],
      selectedPositions: [],
      selectedPositionsNew: [],
      rules: {
        guideName: [{ required: true, message: '请输入名称', trigger: 'blur' }]
        // displayName: [{ required: true, message: '请输入字体中文名称', trigger: 'blur' }]
      },
      uploadLoading: false,
      pdfPagesCount: 0,
      pdfDocument: null,
      configFormData,
      formData: {
        ...defaultFormData,
        guideData: '',
        blacklist: [],
        glossary: [], //专有词汇
        fonts: [Object.assign({}, configFormData.defaultFields)],
        size: Object.assign({}, configFormData.defaultSize),
        coverImage: Object.assign({}, configFormData.defaultCoverImage)
      },
      url: {
        ...this.propsUrl,
        fontsList: '/fonts/list'
      },

      labelWidth: '160',
      layout: {
        labelCol: {
          style: 'width: 160px',
          span: 7
        },
        wrapperCol: {
          span: 16
        }
      },
      guideTemplates: [],
      headers: {},
      isBlack: false
    }
  },
  methods: {
    switchChange(e, item) {
      if (e) {
        item.isBold = 1
      } else {
        item.isBold = 0
      }
    },
    fontChange(e) {
      if (e > 0 == false) return
      if (e.indexOf('.') > -1 && e.split('.')[1].length > 1) return
      if (this.configFormData.fontSize.every(item => item.label != e)) {
        this.configFormData.fontSize.push({ value: e * 4 + 1, label: e })
      }
    },
    filterDepart(fonts) {
      fonts = fonts.filter(f =>
        this.formData.departments?.some(i => f.departments?.some(d => i.departId == d.departId))
      )
      return fonts
    },
    getAllPlatform() {
      getAction('/platform/list?pageSize=1000').then(res => {
        if (res.success) {
          this.allPlatformList = res.data.records
        }
      })
    },
    changePositionListNew(keys, t, { triggerNode }) {
      console.log('changePositionList', { keys, t, triggerNode })
      this.formData.positions = keys.map(item => {
        return {
          positionId: item.split('-')[0],
          type: item.split('-')[1]
        }
      })
    },
    vocabularyChange(values) {
      let arr = this.vocabularyList.filter(i => values.some(item => i.guideId == item))
      this.formData.vocabularyList = arr
    },
    inspectSimpleFilters(curRow) {
      let text = '无'
      this.inspectSimpleList.forEach(item => {
        if (curRow[item.value]) {
          text = item.label
        }
      })
      return text
    },
    inspectSimpleChange(e) {
      this.list.forEach(key => {
        if (key == e) {
          this.formData[key] = true
        } else {
          this.formData[key] = false
        }
      })
    },
    wordsChange(dataList, idx) {
      /* if(this.formData.classId==3){
        this.formData.blacklist[idx].words = dataList
        console.log({ 'this.formData.blacklist[idx].words': this.formData.blacklist[idx].words })
      }else{
        this.formData.glossary[idx].words = dataList
        console.log({ 'this.formData.glossary[idx].words': this.formData.glossary[idx].words })
      } */
      this.formData.blacklist[idx].words = dataList
      console.log({ 'this.formData.blacklist[idx].words': this.formData.blacklist[idx].words })
    },
    moveInIframe(e) {
      /* if (this.isFrameHover) return
      console.log('进入', e)
      this.isFrameHover = true */
      e.stopPropagation()
    },
    moveOutIframe(e) {
      if (!this.isFrameHover) return
      console.log('出去', e)
      this.isFrameHover = false
      e.stopPropagation()
    },
    onScroll(e) {
      if (this.isFrameHover) {
        console.log('停止父组件滚动')
        e.stopPropagation()
        e.preventDefault()
        return false
      }
      // Y轴滚动距离 偏移高度 总高度
      let { scrollTop, clientHeight, scrollHeight } = e.target
      // console.log('onScroll...', { e, scrollTop, clientHeight, scrollHeight })
      /* console.log(`scrollTop + clientHeight ${scrollTop + clientHeight} >= scrollHeight-1 ${scrollHeight - 1}`) */
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log('滚到底部啦！！！')
        this.confirmBtnDisable = false
      }
    },
    initForm() {
      this.formData = {
        ...defaultFormData,
        ...this.propsFilters,
        blacklist: [],
        fonts: [Object.assign({}, configFormData.defaultFields)],
        size: Object.assign({}, configFormData.defaultSize),
        coverImage: Object.assign({}, configFormData.defaultCoverImage)
      }
      this.selectedVocabulary = []
      this.selectedDeparts = [...defaultSelectedDeparts]
      this.selectedPositions = [...defaultSelectedPositions]
      this.selectedPositionsNew = []
      this.selectPlatformIds = []
    },

    show() {
      console.log('显示...', { 'this.curRow': this.curRow })
      this.inspectSimpleValue = '0'
      this.confirmBtnDisable = true
      this.getPosition()
      this.getDepartList()
      this.getAllPlatform()
      this.getVocabularyList()
      this.getDictItems()
      this.getTextStyleType()
      if (this.productionId) {
        this.formData.productionId = this.productionId
      } else if (this.platformId) {
        this.formData.platformId = this.platformId
      }
      this.getGuideTemplates()
      if (
        this.configFormData.fontSize.every(
          item => this.curRow.styleItem && item.value != this.curRow.styleItem.fontSize
        )
      ) {
        this.configFormData.fontSize.push({
          value: this.curRow.styleItem.fontSize,
          label: (this.curRow.styleItem.fontSize - 1) / 4
        })
      }
      if (this.isEdit) {
        this.formData = this.curRow
        // console.log(this.formData, this.curRow,"this.formData = this.curRow")
        const { departments, platforms } = this.formData
        const { positions } = this.formData
        const { vocabularyList } = this.formData
        this.selectedVocabulary = vocabularyList.map(item => item.guideId)
        console.log(this.selectedVocabulary, 123)
        this.selectedDeparts = departments.map(item => item.departId)
        console.log({ positions: positions })
        this.selectedPositions = positions.map(item => item.positionId)
        if (this.propsFilters.typeId == 1) {
          this.selectedPositionsNew = positions?.map(item => item.positionId + '-' + item.type)
        }
        if (platforms) {
          this.selectPlatformIds = platforms.map(item => item.platformId)
        }
        console.log({ 'show this.selectedPositions': this.selectedPositions })
      }
      if (this.curRow.classId == 8 && this.curRow.guideData && this.curRow.guideData.startsWith('http')) {
        this.loadPdfPages(this.curRow.guideData)
      }
      this.formData = { ...this.formData, ...this.propsFilters }
      if (this.formData.classId == 1) {
        if (this.setTitle == '新建') {
          this.formData.textLayout = defaultFormData.textLayout
          this.formData.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
      }
      console.log({ 'this.formData': this.formData })
      setTimeout(() => this.$nextTick(this.calcConfirmBtnDisable), 500)
      // this.calcConfirmBtnDisable()
    },

    calcConfirmBtnDisable() {
      let allowScroll = document.querySelector('.allow-scroll')
      console.log({ allowScroll })
      if (!allowScroll) {
        return
      }
      console.log(
        `allowScroll.scrollHeight ${allowScroll.scrollHeight} <= allowScroll.clientHeight ${allowScroll.clientHeight}`
      )
      if (allowScroll.scrollHeight <= allowScroll.clientHeight) {
        console.log('高度低于窗口可视区域高度')
        this.confirmBtnDisable = false
      }
    },
    onConfirm() {
      console.log('guide-modal onConfirm')
      this.$emit('onConfirm', this.curRow.id)
    },

    getGuideTemplates() {
      // TODO 这块等下再看
      if (this.guideTemplates.length == 0 && this.platformId && this.departmentId) {
        getAction('/guide/get_guide_by_depart', { id: this.departmentId }).then(res => {
          if (res.success) {
            this.guideTemplates = Object.assign([], res.data)
          }
        })
      } else if (this.guideTemplates.length == 0 && this.productionId && this.platformId) {
        getAction('/platform/get_guide_by_platform', { id: this.platformId }).then(res => {
          if (res.success) {
            this.guideTemplates = Object.assign([], res.data)
          }
        })
      }
    },

    guideNameChanged(value) {
      if (!this.formData.id) {
        let matched = this.guideTemplates.filter(one => one.guideName == value)
        if (matched && matched.length > 0) {
          let one = matched[0]
          this.formData.sequenceNo = one.sequenceNo
          this.formData.description = one.description
          this.formData.classId = one.classId
          if (this.platformId && this.departmentId) {
            this.formData.departGuideId = one.guideId
          } else if (this.productionId && this.platformId) {
            this.formData.platformGuideId = one.id
          }
          switch (this.formData.classId) {
            case 1: //文本样式
              this.formData.textLayout = one.textLayout
              this.formData.styleItem = Object.assign({}, one.styleItem)
              break
            case 2: //富文本
            case 8: //PDF
              this.formData.guideData = one.guideData
              break
            case 3: //屏蔽词
              this.formData.blacklist = Object.assign([], one.blacklist)
              break
            case 4: //字体
              this.formData.fonts = Object.assign([], one.fonts)
              break
            case 5: //尺寸
              this.formData.size = Object.assign({}, one.size)
              break
            case 6: //封面
              this.formData.coverImage = Object.assign({}, one.coverImage)
              break
            case 7: //专有词汇
              this.formData.glossary = Object.assign([], one.glossary)
              break
          }
          this.$forceUpdate()
        }
      }
    },

    classIdChanged(value) {
      if (value == 1) {
        if (!this.curRow.styleItem) {
          this.curRow.textLayout = defaultFormData.textLayout
          this.curRow.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
        if (!this.formData.styleItem) {
          this.formData.textLayout = defaultFormData.textLayout
          this.formData.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
      }
      if (value == 15) {
        if (!this.curRow.phoneticStyle) {
          this.curRow.textLayout = defaultFormData.textLayout
          this.curRow.phoneticStyle = Object.assign({}, defaultFormData.phoneticStyle)
        }
        if (!this.formData.phoneticStyle) {
          this.formData.textLayout = defaultFormData.textLayout
          this.formData.phoneticStyle = Object.assign({}, defaultFormData.phoneticStyle)
        }
      }
      if (value == 4) {
        if (!this.formData.fonts || !this.formData.fonts.length) {
          this.formData.fonts = []
          this.formData.fonts.push(
            Object.assign({}, configFormData.defaultFields, {
              fontFamily: [this.configFormData.defaultFontFamily[0].displayName]
            })
          )
        }
      }
      if ((value == 10 || value == 16 || value == 17 || value == 18) && !this.formData.blacklist?.length) {
        this.formData.blacklist = [NOT_DICTIORARY_ITEM]
      }
      if (value == 12 && !this.formData.blacklist?.length) {
        this.formData.blacklist = []
        this.formData.blacklist.push({
          classId: value,
          className: '混淆词',
          words: [],
          inputVisible: false,
          inputValue: ''
        })
        this.formData.typeBackWord = 31
      }
      if (value == 11 && !this.formData.blacklist?.length) {
        this.formData.blacklist = []
        this.formData.blacklist.push({
          classId: value,
          className: '敏感词',
          words: [],
          inputVisible: false,
          inputValue: ''
        })
        this.formData.typeBackWord = 32
      }
      if (value == 7 && !this.formData.blacklist?.length) {
        this.formData.blacklist = []
      }
      if (value == 5) {
        this.formData.size = {}
      }
      if (value == 6) {
        this.formData.coverImage = {}
      }
    },

    typeIdChanged(value) {
      if (value == 1) {
        if (!this.curRow.styleItem) {
          this.curRow.textLayout = defaultFormData.textLayout
          this.curRow.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
        if (!this.formData.styleItem) {
          this.formData.textLayout = defaultFormData.textLayout
          this.formData.styleItem = Object.assign({}, defaultFormData.styleItem)
        }
      }
    },

    startEdit() {
      console.log('startEdit', { 'this.isEdit': this.isEdit })
      if (this.isEdit) {
        this.formData = this.curRow
        this.list.forEach(key => {
          if (this.formData[key]) {
            this.inspectSimpleValue = key
          }
        })
        const { departments, platforms } = this.formData
        if (departments) {
          this.selectedDeparts = departments.map(item => item.departId)
        }
        const { positions } = this.formData
        console.log({ positions: positions })
        if (positions) {
          this.selectedPositions = positions.map(item => item.positionId)
          if (this.propsFilters.typeId == 1) {
            this.selectedPositionsNew = positions?.map(item => item.positionId + '-' + item.type)
          }
          console.log({ 'show this.selectedPositions': this.selectedPositions })
        }
        if (platforms) {
          this.selectPlatformIds = platforms.map(item => item.platformId)
        }

        const { vocabularyList } = this.formData
        if (vocabularyList) {
          this.selectedVocabulary = vocabularyList.map(item => item.guideId)
          console.log(this.selectedVocabulary, 123)
        }
      }
      if ((this.curRow.classId == 10 || this.formData.classId == 10) && !this.formData.blacklist?.length) {
        this.$set(this.formData, 'blacklist', [NOT_DICTIORARY_ITEM])
        this.$emit('changeCurRow', { ...this.curRow, blacklist: [NOT_DICTIORARY_ITEM] })
        console.log({ 'this.curRow': this.curRow, 'this.formData': this.formData })
      }
    },

    setFormData() {
      if (this.formData.classId == 3 || this.formData.classId == 10 || this.formData.classId == 7) {
        this.isBlack = true
      } else {
        this.isBlack = false
      }
      if (this.propsFilters.typeId == 4) {
        this.formData.platforms = this.selectPlatformIds.map(item => {
          return { id: item, platformName: this.allPlatformList.find(p => p.id == item).platformName, platformId: item }
        })
      }
    },
    handleOutLineColor(e) {
      if (e.target.value === '#000000') {
        this.formData.styleItem.outlineWidth = 3
      } else if (e.target.value === '#ffffff') {
        this.formData.styleItem.outlineWidth = 4
      }
    },
    handleAddGlossaryType(val) {
      // 判断是否在新增列表里边
      let hasAddNew = false
      /* if (!this.formData.glossary) {
        this.formData.glossary = []
      }
      this.formData.glossary.forEach(item => {
        if (item.classId == val) {
          hasAddNew = true
        }
      }) */
      if (!this.formData.blacklist) {
        this.formData.glossary = []
      }
      this.formData.blacklist.forEach(item => {
        if (item.classId == val) {
          hasAddNew = true
        }
      })
      if (hasAddNew) {
        this.$message.error('当前类型已经新增过了')
        return
      }

      let curItem = this.typeGlossaryList.filter(item => item.value === val)[0] || {}

      /* this.formData.glossary.push({
        classId: curItem.value,
        className: curItem.title,
        words: [],
        inputVisible: false,
        inputValue: ''
      }) */
      this.formData.blacklist.push({
        classId: curItem.value,
        className: curItem.title,
        words: [],
        inputVisible: false,
        inputValue: ''
      })
    },
    handleDeleteGlossaryType(curItem) {
      /* this.formData.glossary = this.formData.glossary.filter(item => item.classId !== curItem.classId) */
      this.formData.blacklist = this.formData.blacklist.filter(item => item.classId !== curItem.classId)
    },
    addGlossary(classId) {
      let one = this.formData.glossary.filter(item => item.classId == classId)
      if (one && one.length == 1) {
        one[0].words.push({ originalWords: '', translateWords: '' })
      }
    },
    removeGlossary(classId, index) {
      let one = this.formData.glossary.filter(item => item.classId == classId)
      if (one && one.length == 1) {
        one[0].words.splice(index, 1)
      }
    },
    handleAddBlackWord(val) {
      // 判断是否在新增列表里边
      let hasAddNew = false
      this.formData.blacklist.forEach(item => {
        if (item.classId === val) {
          hasAddNew = true
        }
      })

      if (hasAddNew) {
        this.$message.error('当前类型已经新增过了')
        return
      }

      let curItem = this.typeBackWordList.filter(item => item.value === val)[0] || {}

      this.formData.blacklist.push({
        classId: curItem.value,
        className: curItem.title,
        words: [],
        inputVisible: false,
        inputValue: ''
      })
    },
    handleDeleteBlackWordType(curItem) {
      this.formData.blacklist = this.formData.blacklist.filter(item => item.classId !== curItem.classId)
    },
    handleClose(removedTag) {
      this.formData.blacklist = this.formData.blacklist.map(each => {
        if (each.words.indexOf(removedTag) >= 0) {
          return {
            ...each,
            words: each.words.filter(tag => tag !== removedTag)
          }
        } else {
          return each
        }
      })
    },

    showInput(item) {
      this.formData.blacklist = this.formData.blacklist.map(each => {
        if (item.classId === each.classId) {
          return {
            ...each,
            inputVisible: true
          }
        } else {
          return each
        }
      })

      this.$nextTick(function() {
        let refName = `input_${item.classId}`
        this.$refs[refName][0].focus()
      })
    },

    handleInputChange(e) {
      this.inputValue = e.target.value
    },

    handleInputConfirm(curItem) {
      const inputValue = this.inputValue
      this.formData.blacklist = this.formData.blacklist.map(item => {
        if (item.classId === curItem.classId) {
          let words = item.words
          if (inputValue && words.indexOf(inputValue) === -1) {
            words = [...words, inputValue]
          } else {
            this.$message.error('当前屏蔽词已经新增过了')
          }

          return {
            ...item,
            words,
            inputVisible: false,
            inputValue: ''
          }
        } else {
          return {
            ...item
          }
        }
      })
    },
    handleSetOutlineColor(color) {
      this.formData.styleItem.outlineColor = color
    },
    handleSetOutlineWidth(width) {
      this.formData.styleItem.outlineWidth = width
    },
    addField() {
      this.formData.fonts.push(
        Object.assign({}, configFormData.defaultFields, {
          fontFamily: [this.configFormData.defaultFontFamily[0].displayName]
        })
      )
    },
    removeField(index) {
      if (this.formData.fonts.length <= 1) return
      this.formData.fonts.splice(index, 1)
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
      })
    },
    classNameString(classId) {
      for (let i = 0; i < configFormData.classIds.length; i++) {
        if (classId == configFormData.classIds[i].value) {
          return configFormData.classIds[i].label
        }
      }
    },
    typeNameString(typeId) {
      for (let i = 0; i < configFormData.typeIds.length; i++) {
        if (typeId == configFormData.typeIds[i].value) {
          return configFormData.typeIds[i].label
        }
      }
    },

    getDisplayFontSize(styleItem) {
      for (let idx = 0; idx < configFormData.fontSize.length; idx++) {
        if (configFormData.fontSize[idx].value == styleItem.fontSize) {
          return configFormData.fontSize[idx].label
        }
      }
      styleItem.fontSize = 33
      return 8
    },

    getAntiAliasMethod(styleItem) {
      for (let idx = 0; idx < configFormData.antiAliasMethod.length; idx++) {
        if (configFormData.antiAliasMethod[idx].value == styleItem.antiAliasMethod) {
          return configFormData.antiAliasMethod[idx].label
        }
      }
      return '锐利'
    },

    handleChangeUpload(info) {
      this.uploadLoading = true
      if (info.file.status !== 'uploading') {
        this.uploadLoading = false
      }
      if (info.file.status === 'done') {
        this.uploadLoading = false
        if (info.file.response.success) {
          this.loadPdfPages(info.file.response.message, () => {
            this.formData.guideData = info.file.response.message
          })
        }
      } else if (info.file.status === 'error') {
        this.uploadLoading = false
        this.$message.error(`${info.file.name} 上传失败`)
      }
    },

    loadPdfPages(url, callback) {
      var loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then(pdf => {
        this.pdfDocument = loadingTask
        this.pdfPagesCount = pdf.numPages
        if (callback) {
          callback()
        }
      })
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    calcWord(word) {
      // console.log('calcWord', { word });
      if (!word) return ''
      if (typeof word == 'string') {
        return decodeWord(word)
      } else if (typeof word == 'object') {
        const { value, target } = word
        if (target) {
          return decodeWord(value + ' → ' + target)
        } else {
          return decodeWord(String(value))
        }
      }
      // console.log({ word });
      // return word?.value ? (word.value + (word.target ? ' → ' + word.target : '')) : word
    }
  },
  watch: {
    formData() {
      if (this.propsFilters.typeId == 4 && this.formData.classId == 1) {
        this.formData.classId = 3
      }
    }
  },
  computed: {
    getTreePositionListNew() {
      console.log({ 'this.positionList': this.positionList })
      let treePositionList = this.positionList
        .filter(i => i)
        .map(({ id: key, id: value, name: title }) => ({ title, key, value }))
      console.log({ treePositionList })
      let arr1 = []
      treePositionList.forEach(item => {
        arr1.push({ title: item.title + '-查看', key: item.key + '-VIEW', value: item.value + '-VIEW' })
        arr1.push({ title: item.title + '-编辑', key: item.key + '-EDIT', value: item.value + '-EDIT' })
      })
      treePositionList = arr1
      return treePositionList
    }
  },
  created() {
    const params = {
      pageNo: 1,
      pageSize: 100000
    }
    getAction(this.url.fontsList, params).then(res => {
      if (res.success) {
        this.configFormData.defaultFontFamily = []
        res.data.records.filter(one => {
          let found = false
          for (let idx = 0; idx < this.configFormData.defaultFontFamily.length; idx++) {
            if (this.configFormData.defaultFontFamily[idx].displayName == one.displayName) {
              found = true
              break
            }
          }
          if (!found) {
            this.configFormData.defaultFontFamily.push(one)
          }
        })
        // this.configFormData.defaultFontFamily = res.data.records

        // this.formData.fonts[0].fontFamily = [res.data.records[0].displayName]
        this.formData.fonts[0] = Object.assign(this.formData.fonts[0] || {}, {
          fontFamily: [this.configFormData.defaultFontFamily[0]?.displayName]
        })
      }
    })
    const token = Vue.ls.get(ACCESS_TOKEN)
    this.headers = { 'X-Access-Token': token }
  }
}
</script>
<style scoped lang="less">
/deep/.ant-tag {
  margin-bottom: 5px;
}

/deep/ .ant-drawer-body {
  padding: unset !important;
}

/deep/ .eidtor-btn {
  margin: 24px 48px 10px 0;
}
</style>
<style lang="less">
.scroll-view {
  height: 80vh;
  overflow-y: auto;
  padding: 24px 44px 0 48px;
}

// 千万不要改！
.allow-scroll {
  height: 80vh;
  overflow-y: auto;
  padding: 24px 44px 0 48px;
}

.no-scroll {
  overflow-y: hidden !important;
  padding-right: 48px !important;
}

// .no-scroll {
//   overflow-y: hidden;
// }

.fontColor,
.outlineColor {
  width: 32px;
  padding: 0;
  margin: 0;
}

.inputNumber {
  width: 70px;
}

.black_word {
  margin-bottom: 15px;
}

.black_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :last-child {
    font-size: 20px;
    cursor: pointer;
  }
}

.default_outline_color,
.default_outline_width {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;

  span {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border: 1px solid #44afe4;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .active_outlineColor,
  .active_outlineWidth {
    -webkit-box-shadow: 0 0 5px rgba(0, 113, 241, 1);
  }
}

.form_min_width > div {
  display: inline-block;
  width: 100px;
}
</style>
